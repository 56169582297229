import { MENU_NAMES, TYPE_ACCOUNT } from "$utils/constants";
import {
  isAccessSystemEnabled,
  isCardIssuingSystemEnabled,
  isPluginEnabled,
} from "$utils/plugins";
import { ROUTE, isRenderMenu, isRole } from "./utils";

export const MenuList = (role) => ({
  adminMenus: [
    {
      id: "dashBoard",
      isAvailable: true,
    },
    {
      id: "monitoring",
      isAvailable: true,
    },
    {
      id: "deviceManagement",
      isAvailable: true,
    },
    {
      id: "systemManagement",
      isAvailable: true,
    },
    {
      id: "advancedManagement",
      isAvailable: true,
    },
  ],
  normalMenus: [
    {
      id: "dashBoard",
      isAvailable:
        isRole(ROUTE.dashBoard) && isRenderMenu(MENU_NAMES.DashBoard),
    },
    {
      id: "monitoring",
      isAvailable:
        isRenderMenu(MENU_NAMES.Monitoring) && isAccessSystemEnabled(),
    },
    {
      id: "visitApplicationManagement",
      isAvailable:
        isPluginEnabled("visitManagement") &&
        isAccessSystemEnabled() &&
        isRenderMenu(MENU_NAMES.VisitApplicationManagement),
    },
    {
      id: "user",
      isAvailable: isRenderMenu(MENU_NAMES.User),
    },
    {
      id: "eventManagement",
      isAvailable:
        isAccessSystemEnabled() &&
        role !== TYPE_ACCOUNT.employee &&
        isPluginEnabled("eventManagement"),
    },
    {
      id: "accessControl",
      isAvailable:
        isPluginEnabled("accessControl") &&
        isAccessSystemEnabled() &&
        isRenderMenu(MENU_NAMES.AccessControl),
    },
    {
      id: "timeAttendance",
      isAvailable:
        role !== TYPE_ACCOUNT.employee &&
        isPluginEnabled("timeAttendance") &&
        isAccessSystemEnabled() &&
        isRenderMenu(MENU_NAMES.TimeAttendance),
    },
    {
      id: "cardIssuing",
      isAvailable:
        isPluginEnabled("cardIssuing") &&
        (isAccessSystemEnabled() || isCardIssuingSystemEnabled()) &&
        isRenderMenu(MENU_NAMES.CardIssuing),
    },
    {
      id: "deviceSetting",
      isAvailable:
        role !== TYPE_ACCOUNT.employee &&
        isAccessSystemEnabled() &&
        isRenderMenu(MENU_NAMES.Device),
    },
    {
      id: "report",
      isAvailable:
        role !== TYPE_ACCOUNT.employee && isRenderMenu(MENU_NAMES.Report),
    },
    {
      id: "bookManagement",
      isAvailable: isPluginEnabled("libraryManagement"),
    },
    {
      id: "systemManagement",
      isAvailable: isRenderMenu(MENU_NAMES.SystemManagement),
    },
    {
      id: "notifications",
      isAvailable: isRole(ROUTE.notifications),
    },
  ],
});
export const AdminSubMenuList = {
  dashBoard: [
    {
      id: "dashBoard",
      isAvailable: true,
      route: ROUTE.dashBoard,
    },
  ],
  monitoring: [
    {
      id: "deviceMonitoring",
      isAvailable: true,
      route: ROUTE.deviceMonitoring,
    },
  ],
  deviceManagement: [
    {
      id: "deviceSetting",
      isAvailable: true,
      route: ROUTE.deviceSetting,
    },
    {
      id: "unregisteredDevices",
      isAvailable: true,
      route: ROUTE.unregisteredDevices,
    },
  ],
  systemManagement: [
    {
      id: "companyManagement",
      isAvailable: true,
      route: ROUTE.company,
    },
    {
      id: "systemAccountManagement",
      isAvailable: true,
      route: ROUTE.systemAccountManagement,
    },
  ],
  advancedManagement: [
    {
      id: "deviceUpdate",
      isAvailable: true,
      route: ROUTE.deviceUpdate,
    },
    {
      id: "firmwareVersion",
      isAvailable: true,
      route: ROUTE.firmwareVersion,
    },
    {
      id: "aboutVersion",
      isAvailable: true,
      route: ROUTE.aboutVersion,
    },
  ],
};
export const SubMenuList = (role) => ({
  dashBoard: [
    {
      id: "dashBoard",
      isAvailable: true,
      route: ROUTE.dashBoard,
    },
  ],
  monitoring: [
    {
      id: "monitoring",
      isAvailable: isRole(ROUTE.monitoring) && isAccessSystemEnabled(),
      route: ROUTE.monitoring,
    },
    {
      id: "deviceMonitoring",
      isAvailable: isRole(ROUTE.deviceMonitoring) && isAccessSystemEnabled(),
      route: ROUTE.deviceMonitoring,
    },
    {
      id: "carMonitoring",
      isAvailable: isRole(ROUTE.carMonitorings),
      route: ROUTE.carMonitorings,
    },
  ],
  visitApplicationManagement: [
    {
      id: "visitManagement",
      isAvailable: isRole(ROUTE.visit),
      route: ROUTE.visit,
    },
    {
      id: "visitReport",
      isAvailable: isRole(ROUTE.visitReport),
      route: ROUTE.visitReport,
    },
    {
      id: "visitSetting",
      isAvailable: isRole(ROUTE.visitSetting),
      route: ROUTE.visitSetting,
    },
  ],
  user: [
    {
      id: "department",
      isAvailable: isRole(ROUTE.department),
      route: ROUTE.department,
    },
    {
      id: "userManagement",
      isAvailable: isRole(ROUTE.user),
      route: ROUTE.user,
    },
    {
      id: "userSetting",
      isAvailable: isRole(ROUTE.userSetting),
      route: ROUTE.userSetting,
    },
  ],
  eventManagement: [
    {
      id: "meetingManagement",
      isAvailable: role !== TYPE_ACCOUNT.employee,
      route: ROUTE.meetings,
    },
  ],
  accessControl: [
    {
      id: "accessGroup",
      isAvailable: isRole(ROUTE.accessGroup),
      route: ROUTE.accessGroup,
    },
    {
      id: "accessibleDoor",
      isAvailable: isAccessSystemEnabled() && isRole(ROUTE.accessibleDoor),
      route: ROUTE.accessibleDoor,
    },
    {
      id: "timeZone",
      isAvailable: isRole(ROUTE.timezone),
      route: ROUTE.timezone,
    },
    {
      id: "holiday",
      isAvailable: isRole(ROUTE.holiday),
      route: ROUTE.holiday,
    },
  ],
  timeAttendance: [
    {
      id: "timeAttendanceReport",
      isAvailable: isRole(ROUTE.timeAttendanceReport),
      route: ROUTE.timeAttendanceReport,
    },
    {
      id: "workingTimeType",
      isAvailable: isRole(ROUTE.workingTimeType),
      route: ROUTE.workingTimeType,
    },
    {
      id: "leaveRequest",
      isAvailable: isRole(ROUTE.leaveRequest),
      route: ROUTE.leaveRequest,
    },
    {
      id: "leaveManagement",
      isAvailable: isRole(ROUTE.leaveManagement),
      route: ROUTE.leaveManagement,
    },
    {
      id: "timeAttendanceSetting",
      isAvailable: isRole(ROUTE.timeAttendanceSetting),
      route: ROUTE.timeAttendanceSetting,
    },
  ],
  cardIssuing: [
    {
      id: "issuingVisitCard",
      isAvailable: isRole(ROUTE.issuingVisitCard),
      route: ROUTE.issuingVisitCard,
    },
    {
      id: "issuingAccessCard",
      isAvailable: isRole(ROUTE.issuingAccessCard),
      route: ROUTE.issuingAccessCard,
    },
    {
      id: "issuingTempCard",
      isAvailable: isRole(ROUTE.issuingTempCard),
      route: ROUTE.issuingTempCard,
    },
    {
      id: "issuingReport",
      isAvailable: isRole(ROUTE.issuingReport),
      route: ROUTE.issuingReport,
    },
    {
      id: "issuingSetting",
      isAvailable: isRole(ROUTE.issuingSetting),
      route: ROUTE.issuingSetting,
    },
    {
      id: "issuingCardLayouts",
      isAvailable: isRole(ROUTE.issuingCardLayouts),
      route: ROUTE.issuingCardLayouts,
    },
  ],
  deviceSetting: [
    {
      id: "messageSetting",
      isAvailable:
        isPluginEnabled("screenMessage") && isRole(ROUTE.messageSetting),
      route: ROUTE.messageSetting,
    },
    {
      id: "building",
      isAvailable: isRole(ROUTE.building),
      route: ROUTE.building,
    },
    {
      id: "menuDoor",
      isAvailable: isRole(ROUTE.deviceSetting),
      route: ROUTE.deviceSetting,
    },
    {
      id: "elevators",
      isAvailable: isPluginEnabled("elevator"),
      route: ROUTE.elevators,
    },
  ],
  report: [
    {
      id: "eventLog",
      isAvailable: isRole("event"),
      route: ROUTE.event,
    },
    {
      id: "systemLog",
      isAvailable: isRole("systemLog"),
      route: ROUTE.systemLog,
    },
  ],
  bookManagement: [
    {
      id: "bookManagement",
      isAvailable: isRole("bookManagement"),
      route: ROUTE.bookManagement,
    },
    {
      id: "borrowingManagement",
      isAvailable:
        isRole("borrowingManagement") && role === TYPE_ACCOUNT.primaryManager,
      route: ROUTE.borrowingManagement,
    },
    {
      id: "bookArea",
      isAvailable: isRole("bookArea"),
      route: ROUTE.bookArea,
    },
    {
      id: "bookReport",
      isAvailable: isRole("bookReport"),
      route: ROUTE.bookReport,
    },
  ],
  systemManagement: [
    {
      id: "accountManagement",
      isAvailable: isRole("accountManagement"),
      route: ROUTE.accountManagement,
    },
    {
      id: "roleSetting",
      isAvailable:
        isRole("roleSetting") && role === TYPE_ACCOUNT.primaryManager,
      route: ROUTE.roleSetting,
    },
    {
      id: "eventRecovery",
      isAvailable: isRole("eventRecovery"),
      route: ROUTE.eventRecovery,
    },
    {
      id: "setting",
      isAvailable: isRole("setting"),
      route: ROUTE.setting,
    },
    {
      id: "emailTemplate",
      isAvailable: role === TYPE_ACCOUNT.primaryManager,
      route: ROUTE.emailTemplate,
    },
  ],
  notifications: [
    {
      id: "notifications",
      isAvailable: isRole("notifications"),
      route: ROUTE.notifications,
    },
  ],
});
