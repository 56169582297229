import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { GLOBAL_COLOR, PAGE_TITLE } from "$utils/constants";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBoxComponent from "$components/CheckBoxComponent";
import { ButtonComponent } from "$components/ButtonComponent";
import { LoginStepOne, LoginStepTwo } from "../../api";
import { FindPassword } from "./FindPassword";
import { SelectCompanies } from "./SelectCompanies";
import messages from "routes/Login/messages";
import { CommonAvatar } from "routes/Login/commonStyle";
import { useSetRecoilState } from "recoil";
import { errorState } from "$utils/atom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEnvelope,
  faLock,
  faSearch,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ChangePassword } from "./ChangePassword";
import { localstoreUtilites } from "$utils/persistenceData";
import { saveLoginDataToLocal } from "$utils/libs/loginLibs";
import { useForm } from "react-hook-form";
import { ErrorMsg } from "$components/Common/ErrorMsg";
import { InputComponent } from "$components/InputComponent";

const LoginBox = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      username: localstoreUtilites.getUsernameFromLocalStorage() || "",
    },
  });
  const intl = useIntl();
  const [rememberMe, setRememberMe] = useState(
    localstoreUtilites.getIsRememberIdToLocalStorage()
  );
  const { username, password } = watch();
  const [enableRemoveOldSession, setEnableRemoveOldSession] = useState(false);
  const [temporaryToken, setTemporaryToken] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [onLoginStepOne, { data }] = LoginStepOne();
  const [onLoginStepTwo] = LoginStepTwo(rememberMe, username);
  const handleLoginStepOne = (submitData) => {
    onLoginStepOne({
      username: submitData.username,
      password: submitData.password,
      enableRemoveOldSession,
    });
    setEnableRemoveOldSession(false);
  };
  const newLogin = (companyId) => {
    if (companyId && temporaryToken) {
      onLoginStepTwo({ companyId, temporaryToken, username });
    } else {
      setError("username", {
        type: "manual",
        message: intl.formatMessage({
          id: messages.mesInvalidLogin.id,
        }),
      });
    }
  };
  const setErrorState = useSetRecoilState(errorState);
  useEffect(() => {
    if (data) {
      if (data.temporaryToken) {
        setTemporaryToken(data.temporaryToken);
        setCompanies(data.companies);
        setValue("password", "");
      } else {
        if (!data.authToken) {
          setErrorState(data.message);
          return;
        }
        saveLoginDataToLocal(data, username, rememberMe);
        setValue("password", "");
      }
    }
  }, [data]);
  const [openChange, setOpenChange] = useState(false);
  const [openFind, setOpenfind] = useState(false);
  return (
    <LoginLayout>
      <LoginContainer>
        {temporaryToken ? (
          <SelectCompanies
            companies={companies}
            newLogin={newLogin}
            setTemporaryToken={setTemporaryToken}
            setCompanies={setCompanies}
          />
        ) : (
          <EachContents>
            <CommonAvatar>
              <FontAwesomeIcon icon={faSignInAlt} />
            </CommonAvatar>
            <Title>{PAGE_TITLE}</Title>
            <ErrorMsg
              text={
                errors?.username ||
                (errors?.password && (
                  <FormattedMessage {...messages.mesInvalidLogin} />
                ))
              }
            />
            <Formbox onSubmit={handleSubmit(handleLoginStepOne)}>
              <InputComponent
                required
                register={register("username", {
                  required: true,
                })}
                value={username}
                height="45px"
                border={null}
                label={false}
                reset={() => setValue("username", "")}
                icon={<FontAwesomeIcon size="lg" icon={faEnvelope} />}
                placeholder={
                  window.env.LOGIN_USERNAME ? (
                    window.env.LOGIN_USERNAME
                  ) : (
                    <FormattedMessage {...messages.username} />
                  )
                }
              />
              <InputComponent
                required
                register={register("password", {
                  required: true,
                })}
                value={password}
                height="45px"
                border={null}
                label={false}
                reset={() => setValue("password", "")}
                icon={<FontAwesomeIcon size="lg" icon={faLock} />}
                placeholder={<FormattedMessage {...messages.password} />}
                type="password"
              />
              <CheckBoxComponent
                id={"loginCheckBox"}
                bgcolor={GLOBAL_COLOR.lightGrayColor}
                value={rememberMe}
                setValue={(value) => {
                  localstoreUtilites.saveIsRememberIdToLocalStorage(value);
                  setRememberMe(value);
                }}
                label={<FormattedMessage {...messages.rememberMe} />}
              />
              <ButtonComponent
                id="LoginBtn"
                text={<FormattedMessage {...messages.signIn} />}
                onClick={handleSubmit(handleLoginStepOne)}
              />
            </Formbox>
            <BtnBox>
              <ButtonComponent
                id="btn-reset-pass"
                onClick={() => {
                  setOpenChange(true);
                }}
                text={<FormattedMessage {...messages.resetPasswd} />}
                contained={false}
                icon={<FontAwesomeIcon icon={faEdit} />}
                textUnderline={true}
              />
              {openChange && (
                <ChangePassword
                  messages={messages}
                  intl={intl}
                  setOpen={setOpenChange}
                />
              )}
              {!window.env.CONTACT_INFO && (
                <>
                  <ButtonComponent
                    id="btn-forgot-pass"
                    onClick={() => {
                      setOpenfind(true);
                    }}
                    text={<FormattedMessage {...messages.forgotPassword} />}
                    contained={false}
                    icon={<FontAwesomeIcon icon={faSearch} />}
                    textUnderline={true}
                  />
                  {openFind && <FindPassword setOpen={setOpenfind} />}
                </>
              )}
              {window.env.CONTACT_INFO && (
                <Contact>{window.env.CONTACT_INFO}</Contact>
              )}
            </BtnBox>
          </EachContents>
        )}
      </LoginContainer>
    </LoginLayout>
  );
};
export default LoginBox;

const LoginLayout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;
const LoginContainer = styled.div`
  width: 500px;
  height: 600px;
  margin-top: 140px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const EachContents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;
const Title = styled.span`
  font-size: 25px;
  color: ${({ theme }) => theme.grayColor};
`;
const Formbox = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;
const BtnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;
const Contact = styled.div`
  white-space: pre-line;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  padding: 10px;
`;
