const { motion } = require("framer-motion");
const { styled } = require("styled-components");

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
export const BoxOfCheckBox = styled(motion.div)`
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StyledCheckBoxBackground = styled(motion.label)`
  position: absolute;
  left: 0;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${(props) => props.bgcolor};
  cursor: pointer;
`;
export const StyledCheckBox = styled.label`
  accent-color: ${(props) => props.theme.grayColor};
  cursor: pointer;
  width: 17px;
  height: 17px;
  z-index: 1;
  outline: ${(props) =>
    props.disabled
      ? "1px solid rgba(0, 0, 0, 0.1)"
      : "2px solid rgba(0, 0, 0, 0.5)"};
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${(props) =>
    props.disabled ? "lightgray" : props.theme.darkGrayColor};
`;
export const CheckBoxIcon = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.disabled ? "lightgray" : props.theme.grayColor};
  color: ${(props) => props.theme.lightGrayColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledLabel = styled.label`
  font-size: 12px;
  color: ${({ theme }) => theme.grayColor};
  cursor: pointer;
`;
