import { useParams } from "react-router-dom";
import { GetShortenLink } from "./api";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { localstoreUtilites } from "$utils/persistenceData";

export const withSubMonitoring = (Component) => {
  return () => {
    const { id } = useParams();
    const { data: token } = GetShortenLink(id);
    const [jsonData, setJsonData] = useState(false);
    const [ready, setReady] = useState(false);
    useEffect(() => {
      if (token) {
        setJsonData(jwt_decode(token));
      }
    }, [token]);
    useEffect(() => {
      if (jsonData) {
        localstoreUtilites.saveUsernameClientToLocalStorage(
          jsonData.CompanyCode
        );
        localstoreUtilites.saveCompanyCodeClientToLocalStorage(
          jsonData.CompanyCode
        );
        if (jsonData.QueueService) {
          localstoreUtilites.saveConfigWSClientToLocalStorage(
            JSON.parse(jsonData.QueueService)
          );
        }
        if (jsonData.Language) {
          localstoreUtilites.saveLanguageToLocalStorage(jsonData.Language);
        }
        if (jsonData.Timezone) {
          localstoreUtilites.saveAccountTzToLocalStorage(jsonData.Timezone);
        }
        setReady(true);
      }
    }, [jsonData]);
    return (
      ready && (
        <Component
          token={token}
          jsonData={jsonData}
          enableDisplayListVisitor={
            jsonData?.EnableDisplayListVisitor == "True"
          }
          enableDisplayAbnormalEvent={
            jsonData?.EnableDisplayAbnormalEvent == "True"
          }
          enableDisplayCanteenEvent={
            jsonData?.EnableDisplayCanteenEvent == "True"
          }
        />
      )
    );
  };
};
