import { useFetch } from "$utils/hooks/useFetch";
import { useMutation } from "$utils/hooks/useMutation";
import {
  convertDateUtcToUserTime,
  convertStringDefaultToDateTime,
} from "$utils/libs/timeLibs";

export function GetUserInit() {
  const res = useFetch(`/users/init`, `/users/init`);
  return res;
}
function addOneYear(date) {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + 10);
  return dateCopy;
}
export function GetUserData(id) {
  const res = useFetch(`/users/${id || 0}`, `users/${id || 0}`);
  return {
    ...res,
    data: res?.data
      ? {
          ...res.data,
          effectiveDate: res.data.effectiveDate
            ? convertDateUtcToUserTime(
                convertStringDefaultToDateTime(res.data.effectiveDate)
              )
            : new Date(),
          expiredDate: res.data.expiredDate
            ? convertDateUtcToUserTime(
                convertStringDefaultToDateTime(res.data.expiredDate)
              )
            : addOneYear(new Date()),
          birthDay: res.data.birthDay
            ? convertDateUtcToUserTime(
                convertStringDefaultToDateTime(res.data.birthDay)
              )
            : new Date(),
        }
      : undefined,
  };
}

export function GetInitRegisterUser(companyCode) {
  const res = useFetch(
    `/register-user?companyCode=${companyCode}`,
    `/register-user?companyCode=${companyCode}`
  );
  return res;
}

export function PostRegisterUser(companyCode) {
  const res = useMutation(`/register-user?companyCode=${companyCode}`);
  return res;
}
