import { motion } from "framer-motion";
import { styled } from "styled-components";

export const PaginationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: ${(props) => props.theme.darkGrayColor};
`;
export const PaginantionSpan = styled.span`
  font-size: 13px;
  padding: 5px;
`;
export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 17px;
`;
