import { errorState } from "$utils/atom";
import { useDidMountEffect } from "$utils/hooks/useDidMountEffect";
import { appMessages } from "messages";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSetRecoilState } from "recoil";

const TODAY = new Date();
const regExp = /^\d{4}.\d{2}.\d{2}$/;

export const useInputDateActions = ({
  id,
  value,
  setValue,
  year,
  setYear,
  month,
  setMonth,
  day,
  setDay,
  isOverlay,
  isFocus,
  setIsFocus,
  setPosition,
  monthRef,
  dayRef,
}) => {
  const setErrorMsg = useSetRecoilState(errorState);
  useEffect(() => {
    if (!isOverlay) {
      setIsFocus(false);
      setPosition(null);
    }
    if (isOverlay && isOverlay.toString() === id) {
      setIsFocus(true);
    } else {
      setIsFocus(false);
      setPosition(null);
    }
  }, [isOverlay]);
  useEffect(() => {
    if (!value) {
      setYear("");
      setMonth("");
      setDay("");
    }
  }, [value]);
  useEffect(() => {
    if (!isFocus) {
      const test = regExp.test(`${year}.${month}.${day}`);
      // 입력하다 말았으면
      if ((year || month || day) && (!year || !month || !day) && !test) {
        setErrorMsg(<FormattedMessage {...appMessages.invalidDateFormat} />);
      }
    }
  }, [isFocus]);
  const onChangeYear = () => {
    if (!year) {
      return false;
    }
    if (year.length !== 0 && year.length < 4) {
      setYear(String(TODAY.getFullYear()));
      return false;
    }
    return true;
  };
  useDidMountEffect(() => {
    if (year.length === 4 && monthRef.current) {
      monthRef.current.focus();
    }
  }, [year]);
  const onChangeMonth = () => {
    if (!month) {
      return false;
    }
    if (month.length !== 0 && month.length < 2) {
      setMonth(String(TODAY.getMonth() + 1).padStart(2, "0"));
      return false;
    } else if (Number(month) > 12) {
      setMonth("12");
      return false;
    } else if (Number(month) < 1) {
      setMonth("01");
      return false;
    }
    return true;
  };
  useDidMountEffect(() => {
    if (month.length === 2 && dayRef.current) {
      dayRef.current.focus();
    }
  }, [month]);
  const onChangeDay = () => {
    if (!day) {
      return false;
    }
    if (day.length !== 0 && day.length < 2) {
      setDay(String(TODAY.getDate()).padStart(2, "0"));
      return false;
    } else if (Number(day) > 31) {
      setDay("31");
      return false;
    } else if (Number(day) < 1) {
      setDay("01");
      return false;
    }
    return true;
  };
  useDidMountEffect(() => {
    if (year.length === 4 && month.length === 2 && day.length === 2) {
      if (onChangeYear() && onChangeMonth() && onChangeDay()) {
        const date = `${year}.${month}.${day}`;
        setValue(date);
      }
    }
  }, [year, month, day]);
  return [onChangeYear, onChangeMonth, onChangeDay];
};
