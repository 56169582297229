import React, { useEffect, useRef, useState } from "react";
import { ContextComponent } from "$components/ContextComponent.js";
import TableHeader from "./Components/TableHeader";
import { TableBox } from "./style";
import { TablePagination } from "./Components/TablePagination";
import {
  ON_RECOVER,
  ON_REINSTALL,
  ON_RESTORE,
  ON_TRANSMIT,
  ON_UPDATE,
} from "./constants";
import { localstoreUtilites } from "$utils/persistenceData";
import { reformatHeaders } from "$utils/libs/tableLibs";
import { lazy } from "react";
import { Suspense } from "react";
import { GridLoader } from "react-spinners";
import { GLOBAL_COLOR } from "$utils/constants";
const TableBody = lazy(() => import("./Components/TableBody"));

export const TableComponent = ({
  data = [],
  children,
  // 데이터의 총 갯수.
  totalCount,
  // modal table ** 모달창의 테이블 등의 두 번째 테이블에서는 반드시 true를 사용해야함. **
  isSecondTable = false,
  isThirdTable = false,
  //id
  tableId,
  headers,
  // 테이블 우클릭 메뉴컴포넌트
  contextMenu,
  // header의 설정(visible, 위치)을 변경하고 창을 닫았을 때 발생할 이벤트 (onPatchHeaderColumns)
  // onPatchHeaderWhenClosed를 사용하는 경우엔 서버에서 header 설정를 관리하고, 아니면 localStorage로 관리한다.
  onPatchHeaderWhenClosed,
  // 체크박스 사용 여부
  checkBox = true,
  checkOnlyOne,
  viewOnly,
  onClick,
  // hasIndex 삭제. 인덱스를 사용하면 실시간 데이터를 사용할 때 성능에 악영향을 줄 것이라 판단.
  // dobleClick event를 props로 넘기면 더블 클릭 시, 아래의 onEditRow 이벤트 대신 발생함. (기존 onAddCardUser도 이걸로 사용하기.)
  onDoubleClick,
  onEditRow,
  // === isEditShowModal, 값을 주지 않을 경우 navigate됌.
  isEditWithModal,
  // data.map으로 row를 렌더링할 때 사용할 수 있는 uniqueKey. 값이 없다면 id 사용
  rowUniqueKey = "id",
  // copyToClipboard 삭제. message Log page에서만 사용
  // === notViewAction, 버튼 cell 렌더링 여부
  viewAction = false,
  isAssignTable = false,
  // ===isNotPaging, pagination 사용 여부
  noPagination = false,
  // === orderHeader, 데이터 정렬 리스트
  sortingItem = headers.map((item) => ({
    id: item.id,
    name: item.label,
    value: item.id,
  })),
  // for assign component
  keepSaveSelectedEvenChangeMeta = false,
  noSort = false,
  noPageSize = false,
  checkRowNeedNewData = () => {
    return true;
  },
  styles = {},
  // --- 이하 actions
  onChangeAlarmStatus,
  hideEditBtn = false,
  onPrintCard,
  onIssueCard,
  onViewDetail,
  onDeleteRow,
  onReconnect,
  onOpenDoor,
  onResetDevice,
  onSendCurrentTime,
  onCopySettingDevice,
  onChangeFilePath,
  onAssignCardId,
  onCompare,
  onShowDeviceHistory,
  actionButtons,
  onChangeStatus,
  // progress btn action, 아이콘 구분을 위해 지정된 이름으로 함수를 받음.
  progressAction = {
    [ON_RESTORE]: undefined,
    [ON_REINSTALL]: undefined,
    [ON_TRANSMIT]: undefined,
    [ON_UPDATE]: undefined,
    [ON_RECOVER]: undefined,
  },
  onStopProgress,
}) => {
  const [tableHeader, setTableHeader] = useState([]);
  const savedHeaders =
    localstoreUtilites.getTableHeaderFromLocalStorage(tableId);
  useEffect(() => {
    function loadHeaderSettingFromLocalStoage() {
      if (
        savedHeaders &&
        tableId &&
        headers.length > 0 &&
        !onPatchHeaderWhenClosed
      ) {
        const parsedHeaders = savedHeaders.map((header) => {
          const headerData = headers.find((item) => item.id === header);
          return {
            ...headerData,
            isVisible: true,
          };
        });
        const notVisibleHeader = headers
          .filter((item) => !savedHeaders.includes(item.id))
          .map((item) => ({ ...item, isVisible: false }));
        setTableHeader([...parsedHeaders, ...notVisibleHeader]);
      } else {
        setTableHeader(
          headers.map((header) => ({
            ...header,
            isVisible: header.isVisible === undefined ? true : header.isVisible,
          }))
        );
      }
    }
    loadHeaderSettingFromLocalStoage();
  }, [headers]);
  // body의 스크롤 이동이 header에도 적용되도록함
  const headerRef = useRef();
  const tempCountForLoading = useRef();
  useEffect(() => {
    if (totalCount) {
      tempCountForLoading.current = totalCount;
    }
  }, [totalCount]);
  // 첫 번째 행의 버튼 tdata width
  const renderTableBody = (
    <Suspense
      fallback={
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridLoader size={11} color={GLOBAL_COLOR.grayColor} />
        </div>
      }
    >
      <TableBody
        data={data}
        tableId={tableId}
        isSecondTable={isSecondTable}
        isThirdTable={isThirdTable}
        isAssignTable={isAssignTable}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        isEditWithModal={isEditWithModal}
        viewOnly={viewOnly}
        onEditRow={onEditRow}
        headerRef={headerRef}
        checkOnlyOne={checkOnlyOne}
        checkBox={checkBox}
        rowUniqueKey={rowUniqueKey}
        tableHeader={tableHeader}
        viewAction={viewAction}
        checkRowNeedNewData={checkRowNeedNewData}
        onChangeAlarmStatus={onChangeAlarmStatus}
        hideEditBtn={hideEditBtn}
        onPrintCard={onPrintCard}
        onIssueCard={onIssueCard}
        onViewDetail={onViewDetail}
        onDeleteRow={onDeleteRow}
        onOpenDoor={onOpenDoor}
        onReconnect={onReconnect}
        onResetDevice={onResetDevice}
        onSendCurrentTime={onSendCurrentTime}
        onCopySettingDevice={onCopySettingDevice}
        onChangeFilePath={onChangeFilePath}
        onAssignCardId={onAssignCardId}
        onCompare={onCompare}
        onShowDeviceHistory={onShowDeviceHistory}
        actionButtons={actionButtons}
        onChangeStatus={onChangeStatus}
        progressAction={progressAction}
        onStopProgress={onStopProgress}
        contextMenu={contextMenu}
        savedHeaders={savedHeaders}
      />
    </Suspense>
  );
  return (
    <TableBox
      secondtable={isSecondTable || isThirdTable ? "true" : undefined}
      style={{ ...styles }}
      isSecondTable={isSecondTable}
      isThirdTable={isThirdTable}
    >
      {children && children}
      {/* Table Header */}
      <TableHeader
        data={data}
        ref={headerRef}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        onPatchHeaderWhenClosed={onPatchHeaderWhenClosed}
        tableId={tableId}
        checkBox={checkBox}
        checkOnlyOne={checkOnlyOne}
        viewOnly={viewOnly}
        isSecondTable={isSecondTable}
        isThirdTable={isThirdTable}
        isAssignTable={isAssignTable}
        keepSaveSelectedEvenChangeMeta={keepSaveSelectedEvenChangeMeta}
        viewAction={viewAction}
        rowUniqueKey={rowUniqueKey}
      />
      {/* Table Body */}
      {contextMenu ? (
        <ContextComponent tableId={`${tableId}_Body`} contextMenu={contextMenu}>
          {renderTableBody}
        </ContextComponent>
      ) : (
        <>{renderTableBody}</>
      )}
      <TablePagination
        tableId={tableId}
        noPagination={noPagination}
        sortingItem={sortingItem}
        total={
          totalCount === 0 ? 0 : totalCount || tempCountForLoading.current || 0
        }
        isSecondTable={isSecondTable}
        isThirdTable={isThirdTable}
        isAssignTable={isAssignTable}
        checkBox={checkBox}
        keepSaveSelectedEvenChangeMeta={keepSaveSelectedEvenChangeMeta}
        noSort={noSort}
        noPageSize={noPageSize}
      />
    </TableBox>
  );
};

const TableWrapper = (props) => {
  const { headers, tableId } = props;
  const savedHeaderSizes =
    localstoreUtilites.getTableHeaderSizesFromLocalStorage(tableId);
  const headerData =
    headers?.length > 0 && headers[0]?.headerName && headers[0]?.headerVariable
      ? reformatHeaders(headers)
      : headers;
  return (
    <TableComponent
      {...props}
      headers={headerData.map((header) => ({
        ...header,
        width: savedHeaderSizes
          ? savedHeaderSizes[header.id] || 160
          : header?.width || 160,
      }))}
    />
  );
};

export default TableWrapper;
