import React, { createRef, useState } from "react";
import {
  InputComponentBox,
  InputComponentOverlay,
  StyledInput,
} from "../style";
import { Label } from "../Components/Label";
import { Placehoder } from "../Components/Placehoder";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import { menuOverlayState } from "$utils/atom";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconBox } from "../styleForDateInput";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ButtonComponent } from "$components/ButtonComponent";
import { useInputDateActions } from "./action";

export const InputDateComponent = ({
  id,
  value,
  setValue,
  icon,
  placeholder,
  width,
  height,
  border = "1px solid rgba(0,0,0,0.1)",
  disabled = false,
  label = false,
  required = false,
  noIcon = false,
}) => {
  const [position, setPosition] = useState(null);
  const [isOverlay, setIsOverlay] = useRecoilState(menuOverlayState);
  const [isFocus, setIsFocus] = useState(false);
  const [year, setYear] = useState(
    value ? String(new Date(value).getFullYear()) : ""
  );
  const [month, setMonth] = useState(
    value ? String(new Date(value).getMonth() + 1).padStart(2, "0") : ""
  );
  const [day, setDay] = useState(
    value ? String(new Date(value).getDate()).padStart(2, "0") : ""
  );
  const yearRef = createRef();
  const monthRef = createRef();
  const dayRef = createRef();
  const [onChangeYear, onChangeMonth, onChangeDay] = useInputDateActions({
    id,
    value,
    setValue,
    year,
    setYear,
    month,
    setMonth,
    day,
    setDay,
    isOverlay,
    isFocus,
    setIsFocus,
    setPosition,
    monthRef,
    dayRef,
  });
  return (
    <InputComponentOverlay width={width} height={height}>
      {label && (
        <Label
          required={required}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
      <InputComponentBox
        isfocus={isFocus ? isFocus.toString() : null}
        border={border}
        width={width}
        height={height}
        onClick={() => {
          if (!isFocus) {
            if (!year) {
              yearRef.current.focus();
            } else if (!month) {
              monthRef.current.focus();
            } else {
              dayRef.current.focus();
            }
          }
        }}
      >
        <Flexbox>
          <StyledInput
            onFocus={(event) => {
              event.target.selectionStart = 4;
              setIsFocus(true);
            }}
            onBlur={() => {
              setIsFocus(false);
              onChangeYear();
            }}
            maxLength={4}
            value={year}
            id={`${id}_year`}
            onChange={(event) => {
              if (isNaN(Number(event.target.value))) {
                yearRef.current.defaultValue = year;
              } else {
                setYear(String(event.target.value));
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "ArrowRight") {
                if (event.target.selectionStart === 4) {
                  event.preventDefault();
                  monthRef.current.focus();
                }
              } else if (
                event.key !== "Backspace" &&
                event.key !== "ArrowRight" &&
                event.key !== "ArrowLeft" &&
                event.key !== "Tab"
              ) {
                if (event.target.selectionStart === 4) {
                  monthRef.current.focus();
                }
              }
            }}
            placeholder="YYYY"
            disabled={disabled ? disabled.toString() : false}
            ref={yearRef}
            style={{
              width: "34px",
              padding: 0,
              height: "100%",
              margin: 0,
            }}
          />
          <span style={{ marginBottom: "3px" }}>.</span>
          <StyledInput
            onFocus={() => {
              setIsFocus(true);
              if (!year) {
                yearRef.current.focus();
              }
            }}
            onBlur={() => {
              setIsFocus(false);
              onChangeMonth();
            }}
            maxLength={2}
            value={month}
            id={`${id}_month`}
            onChange={(event) => {
              if (isNaN(Number(event.target.value))) {
                monthRef.current.defaultValue = month;
              } else {
                setMonth(String(event.target.value));
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "ArrowRight") {
                if (event.target.selectionStart === 2) {
                  event.preventDefault();
                  dayRef.current.focus();
                }
              } else if (event.key === "ArrowLeft") {
                if (event.target.selectionStart === 0) {
                  event.preventDefault();
                  yearRef.current.focus();
                }
              } else if (event.key === "Backspace") {
                if (event.target.selectionStart === 0) {
                  event.preventDefault();
                  yearRef.current.focus();
                }
              } else if (event.key !== "Tab") {
                if (event.target.selectionStart === 2) {
                  dayRef.current.focus();
                }
              }
            }}
            disabled={disabled ? disabled.toString() : false}
            ref={monthRef}
            placeholder="MM"
            style={{
              width: "25px",
              padding: 0,
              height: "100%",
              margin: 0,
              paddingLeft: "5px",
            }}
          />
          <span style={{ marginBottom: "3px" }}>.</span>
          <StyledInput
            onFocus={() => {
              setIsFocus(true);
              if (!year) {
                yearRef.current.focus();
              } else if (!month) {
                monthRef.current.focus();
              }
            }}
            onBlur={() => {
              setIsFocus(false);
              onChangeDay();
            }}
            maxLength={2}
            value={day}
            id={`${id}_day`}
            onChange={(event) => {
              if (isNaN(Number(event.target.value))) {
                dayRef.current.defaultValue = day;
              } else {
                setDay(String(event.target.value));
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "ArrowLeft") {
                if (event.target.selectionStart === 0) {
                  event.preventDefault();
                  monthRef.current.focus();
                }
              } else if (event.key === "Backspace") {
                if (event.target.selectionStart === 0) {
                  event.preventDefault();
                  monthRef.current.focus();
                }
              }
            }}
            disabled={disabled ? disabled.toString() : false}
            ref={dayRef}
            placeholder="DD"
            style={{
              width: "35px",
              padding: 0,
              height: "100%",
              margin: 0,
              paddingLeft: "5px",
            }}
          />
        </Flexbox>
        {!label && (
          <Placehoder
            isFocus={isFocus}
            value={value}
            disabled={disabled}
            icon={icon}
            placeholder={placeholder}
            required={required}
          />
        )}
        {!noIcon && !disabled && (
          <IconBox>
            <ButtonComponent
              icon={<FontAwesomeIcon icon={faCalendar} />}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                if (!isFocus) {
                  setIsOverlay(id);
                }
                setPosition({
                  x: event.target.getBoundingClientRect().left,
                  y: event.target.getBoundingClientRect().top,
                });
              }}
              contained={false}
              circle
              style={{
                marginBottom: "2px",
                width: "27px",
                height: "27px",
              }}
            />
          </IconBox>
        )}
      </InputComponentBox>
      {!noIcon && position && isOverlay && (
        <CalendarBox onClick={(event) => event.stopPropagation()}>
          <Calendar
            onChange={(data) => {
              const newYear = String(data.getFullYear());
              const newMonth = String(data.getMonth() + 1).padStart(2, "0");
              const newDate = String(data.getDate()).padStart(2, "0");
              setYear(newYear);
              setMonth(newMonth);
              setDay(newDate);
              setValue(`${newYear}.${newMonth}.${newDate}`);
              setIsFocus(false);
              setPosition(null);
              setIsOverlay(false);
            }}
            value={value}
          />
        </CalendarBox>
      )}
    </InputComponentOverlay>
  );
};

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 100%;
  font-size: 13px;
  padding-left: 5px;
`;
const CalendarBox = styled.div`
  position: fixed;
  z-index: 20;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.theme.lightGrayColor};
  border-radius: 5px;
  padding: 20px 40px;
`;
