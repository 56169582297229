import React from "react";
import { StyledLabel } from "../style";

export const Label = ({ required, disabled, placeholder, bgcolor }) => {
  return (
    <StyledLabel
      style={{ left: "10px" }}
      required={required}
      disabled={disabled ? disabled.toString() : null}
      bgcolor={bgcolor}
    >
      {placeholder}
      {required && "*"}
    </StyledLabel>
  );
};
