import React, { useEffect, useRef } from "react";
import { menuOverlayState, mouseLocaleState } from "$utils/atom";
import { useRecoilValue } from "recoil";
import { ContextMenuBox } from "./style";

export const ContextContentsBox = ({ contextMenu, closeEvent, tableId }) => {
  const menuRef = useRef();
  const menuOverlay = useRecoilValue(menuOverlayState);
  const mouseLocale = useRecoilValue(mouseLocaleState);
  useEffect(() => {
    if (closeEvent) {
      if (menuOverlay && menuOverlay === tableId) {
        menuRef.current = menuOverlay;
      } else if (menuRef.current === tableId && menuOverlay !== tableId) {
        menuRef.current = false;
        closeEvent();
      }
    }
  }, [menuOverlay]);
  return (
    <>
      {menuOverlay && mouseLocale && tableId === menuOverlay && (
        <ContextMenuBox
          onClick={(event) => event.stopPropagation()}
          mouselocale={mouseLocale}
        >
          {contextMenu}
        </ContextMenuBox>
      )}
    </>
  );
};
