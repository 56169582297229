import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { GetSubDisplayInfoV1 } from "../api";
import { GLOBAL_COLOR, MQTT_TYPE } from "$utils/constants";
import WebSocketMqtt from "$utils/mqtt";
import { useSetRecoilState } from "recoil";
import { errorState } from "$utils/atom";
import { localstoreUtilites } from "$utils/persistenceData";
import { FormattedMessage } from "react-intl";
import { deviceMonitoringMessages } from "routes/DeviceMonitoirng/messages";
import { LIST_CULTURE, headerBoxes } from "../utils";
import { format } from "date-fns";
import { Time } from "../Time";
import { redCode } from "routes/Monitoring/utils";
import { SubMonitoringbox } from "../styles";
import { withSubMonitoring } from "../withSubMonitoring";
const avatarDefault = "/images/avatar-male-default.jpg";

export const SubMonitoringOne = withSubMonitoring(({ token, jsonData }) => {
  const { data } = GetSubDisplayInfoV1(token);
  const [personInfos, setPersonInfos] = useState();
  const countDeviceShowing = Number(jsonData?.CountDeviceShowing) || 2;
  const setError = useSetRecoilState(errorState);
  useEffect(() => {
    if (data) {
      setPersonInfos(data.personInfos);
    }
  }, [data]);
  const handleReceiveMessageMqtt = (messages) => {
    const messageQueue = JSON.parse(messages.payloadString);
    if (
      JSON.parse(jsonData.ListDeviceAddress).includes(
        messageQueue?.data?.personInfos[0].deviceAddress
      )
    ) {
      var newPersonInfos = [];
      if (jsonData.IsUseDesign === "True") {
        for (let i = 0; i < personInfos.length; i++) {
          if (
            personInfos[i].deviceAddress ==
            messageQueue.data.personInfos[0].deviceAddress
          ) {
            newPersonInfos.push(messageQueue.data.personInfos[0]);
          } else {
            newPersonInfos.push(personInfos[i]);
          }
        }
      } else {
        newPersonInfos = personInfos;
        newPersonInfos = [messageQueue.data.personInfos[0], ...personInfos];
        if (countDeviceShowing < newPersonInfos.length) {
          newPersonInfos.splice(-1);
        }
      }
      setPersonInfos(newPersonInfos);
    }
  };
  useEffect(() => {
    if (jsonData && personInfos) {
      window.DeMaster_Mqtt_Client = new WebSocketMqtt()
        .initClient()
        .addReciveMessageHandler(handleReceiveMessageMqtt)
        .onReciveMessage()
        .setTopics([
          MQTT_TYPE.SUB_DISPLAY_DEVICE_INFO.topic.concat(
            `/${jsonData.CompanyCode}`
          ),
        ])
        .connectClient(
          (context, topicsIsSubscribed) => {
            context.subscribeTopics(topicsIsSubscribed);
          },
          (message) => {
            setError(`connect failed: ${message}`);
          }
        );
    }
    return () => {
      if (window.DeMaster_Mqtt_Client) {
        window.DeMaster_Mqtt_Client.unSubscribe([
          MQTT_TYPE.SUB_DISPLAY_DEVICE_INFO.topic.concat(
            `/${localstoreUtilites.getCompanyCodeClientFromLocalStorage()}`
          ),
        ])
          .removeReciveMessageHandler()
          .removeConnectLostHandler();
      }
    };
  }, [personInfos]);
  const culture = LIST_CULTURE.find(
    (item) => item.language === jsonData.Language
  );
  const count =
    countDeviceShowing < 7 ? (countDeviceShowing % 3 === 0 ? 3 : 2) : 5;
  const size = count === 5 ? 17 : count === 3 ? 23 : 30;
  return (
    <SubMonitoringbox>
      <TimeBox>
        <Time />
      </TimeBox>
      <EventBox size={size} count={count}>
        {countDeviceShowing &&
          personInfos &&
          [...Array(countDeviceShowing).keys()].map((index) => (
            <EachEventBox key={index}>
              <EachEvent count={count}>
                <Box count={count}>
                  <Img
                    count={count}
                    src={personInfos[index]?.avatar || avatarDefault}
                  />
                  <FlexBox
                    style={{
                      flexDirection: "column",
                    }}
                  >
                    {personInfos[index]?.isVisit ? (
                      <InfoBox>
                        {personInfos[index]?.plateNumber ? (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.plateNumber}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.plateNumber}
                          </FlexBox>
                        ) : (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.visitorTypeInfo}
                              />
                              :
                            </SubTitle>
                            {
                              personInfos[index]?.visitorTypes[
                                culture.cultureCode
                              ]
                            }
                          </FlexBox>
                        )}
                        {personInfos[index]?.visitorRank && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.visitorRankInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.visitorRank}
                          </FlexBox>
                        )}
                        {personInfos[index]?.visitorName && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.visitorNameInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.visitorName}
                          </FlexBox>
                        )}
                        {personInfos[index]?.visitorReason && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.visitorReasonInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.visitorReason}
                          </FlexBox>
                        )}
                      </InfoBox>
                    ) : (
                      <InfoBox>
                        {personInfos[index]?.plateNumber && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.plateNumber}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.plateNumber}
                          </FlexBox>
                        )}
                        {personInfos[index]?.grade && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.gradeInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.grade}
                          </FlexBox>
                        )}
                        {personInfos[index]?.name && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.nameInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.name}
                          </FlexBox>
                        )}
                        {personInfos[index]?.position && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.positionInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.position}
                          </FlexBox>
                        )}
                        {((!personInfos[index]?.plateNumber &&
                          personInfos[index]?.department) ||
                          (!personInfos[index]?.name &&
                            personInfos[index]?.department)) && (
                          <FlexBox>
                            <SubTitle size={size}>
                              <FormattedMessage
                                {...deviceMonitoringMessages.departmentInfo}
                              />
                              :
                            </SubTitle>
                            {personInfos[index]?.department}
                          </FlexBox>
                        )}
                      </InfoBox>
                    )}
                    <InfoBox>
                      <FlexBox>
                        <SubTitle size={size}>
                          <FormattedMessage
                            {...deviceMonitoringMessages.eventTypeDescriptionInfo}
                          />
                        </SubTitle>
                        <span
                          style={
                            redCode.includes(personInfos[index]?.eventType)
                              ? { color: GLOBAL_COLOR.redColor }
                              : { color: GLOBAL_COLOR.greenColor }
                          }
                        >
                          {personInfos[index]?.eventTypeDescriptions
                            ? personInfos[index]?.eventTypeDescriptions[
                                culture.cultureCode
                              ].eventName
                            : ""}
                        </span>
                      </FlexBox>
                      <FlexBox>
                        <SubTitle size={size}>
                          <FormattedMessage
                            {...deviceMonitoringMessages.accessTimeInfo}
                          />
                        </SubTitle>
                        {personInfos[index]?.eventTime &&
                        personInfos[index]?.eventTime !== "0001-01-01T00:00:00Z"
                          ? format(
                              new Date(personInfos[index]?.eventTime),
                              culture.dateTimeFormat
                            )
                          : ""}
                      </FlexBox>
                      <FlexBox>
                        <SubTitle size={size}>
                          <FormattedMessage
                            {...deviceMonitoringMessages.deviceNameInfo}
                          />
                        </SubTitle>
                        {personInfos[index]?.deviceName}
                      </FlexBox>
                    </InfoBox>
                  </FlexBox>
                </Box>
              </EachEvent>
              <Title>
                {jsonData.IsUseDesign === "True"
                  ? personInfos[index]?.deviceName || index
                  : headerBoxes[index] || (
                      <FormattedMessage
                        {...deviceMonitoringMessages.headerEvent}
                      />
                    )}
              </Title>
            </EachEventBox>
          ))}
      </EventBox>
    </SubMonitoringbox>
  );
});
const TimeBox = styled.div`
  font-size: 22px;
  display: flex;
  justify-content: center;
  padding: 10px;
  color: ${(props) => props.theme.grayColor};
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
`;

const EventBox = styled.div`
  width: 100%;
  height: calc(100% - 42px);
  display: grid;
  background-color: rgba(0, 0, 0, 0.2);
  grid-template-columns: ${(props) => `repeat(${props.count}, 1fr)`};
  font-size: ${(props) => `${props.size}px`};
  gap: 4px;
  font-weight: bold;
  padding-top: 4px;
`;
const EachEventBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const EachEvent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.lightGrayColor};
  padding: 20px 0;
  padding-left: 20px;
  display: flex;
  flex-direction: ${(props) =>
    props.count % 3 !== 0 && props.count % 2 === 0 ? "row" : "column"};
`;
const Box = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.count === 5 ? "column" : "row")};
  align-items: center;
  height: 100%;
  gap: ${(props) => (props.count === 5 ? "20px" : "40px")};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
const SubTitle = styled.div`
  color: gray;
  font-size: ${(props) =>
    `${
      props.size < 20
        ? props.size - 3
        : props.size < 30
        ? props.size - 5
        : props.size - 8
    }px`};
  font-weight: normal;
`;
const FlexBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Img = styled.div`
  background-image: url(${(props) => props.src});
  width: ${(props) => `${100 / props.count - 100 / (props.count * 1.7)}vw`};
  height: ${(props) => `${100 / props.count - 100 / (props.count * 1.7)}vw`};
  max-width: 35vh;
  max-height: 35vh;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  position: relative;
  border: 3px solid ${(props) => props.theme.orangeColor};
  flex-shrink: 0;
`;
const Title = styled.div`
  font-size: 16px;
  display: flex;
  padding: 10px;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.grayColor};
  color: ${(props) => props.theme.lightGrayColor};
`;
