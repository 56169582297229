import { defineMessages } from "react-intl";

const scope = "demasterpro.containers.UserPage";
export const userMessages = defineMessages({
  linkCopied: {
    id: "demasterpro.containers.App.linkCopied",
    defaultMessage: `링크가 복사되었습니다.`,
  },
  cardIdAlreadyExist: {
    id: `${scope}.cardIdAlreadyExist`,
    defaultMessage: `이미 존재하는 카드 ID입니다. 다른 카드 ID를 사용해주세요.`,
  },
  useEndDate: {
    id: `${scope}.useEndDate`,
    defaultMessage: `금일까지`,
  },
  titleTabAccessGroup: {
    id: `${scope}.titleTabAccessGroup`,
    defaultMessage: `출입그룹`,
  },
  titleTabDetail: {
    id: `${scope}.titleTabDetail`,
    defaultMessage: `세부사항`,
  },
  titleTabUserInfo: {
    id: `${scope}.titleTabUserInfomation`,
    defaultMessage: `사용자 정보`,
  },
  titleUserToolBarExport: {
    id: `${scope}.titleUserToolBarExport`,
    defaultMessage: `내보내기`,
  },
  titleUserToolBarDelete: {
    id: `${scope}.titleUserToolBarDelete`,
    defaultMessage: `사용자 삭제`,
  },
  titleUserToolBarWorkingTime: {
    id: `${scope}.titleUserToolBarWorkingTime`,
    defaultMessage: `Change Working Time`,
  },
  placeholderSearchBox: {
    id: `${scope}.placeholderSearchBox`,
    defaultMessage: `찾기...`,
  },
  headerTimezone: {
    id: `${scope}.headerTimezone`,
    defaultMessage: `타임존`,
  },
  headerBuilding: {
    id: `${scope}.headerBuilding`,
    defaultMessage: `장소`,
  },
  headerDoorName: {
    id: `${scope}.headerDoorName`,
    defaultMessage: `출입문 이름`,
  },
  headerDeviceAddress: {
    id: `${scope}.headerDeviceAddress`,
    defaultMessage: `RID`,
  },
  headerExpriedDate: {
    id: `${scope}.headerExpriedDate`,
    defaultMessage: `유효기간`,
  },
  headerEmployeeNo: {
    id: `${scope}.headerEmployeeNo`,
    defaultMessage: `사번`,
  },
  headerDepartment: {
    id: `${scope}.headerDepartment`,
    defaultMessage: `소속`,
  },
  invalidUser: {
    id: `${scope}.invalidUser`,
    defaultMessage: `모든 사용자 보기`,
  },
  titleTable: {
    id: `${scope}.titleTable`,
    defaultMessage: `사용자 관리`,
  },
  btnSave: {
    id: `${scope}.btnSave`,
    defaultMessage: `저장`,
  },
  btnCancel: {
    id: `${scope}.btnCancel`,
    defaultMessage: `취소`,
  },
  name1: {
    id: `${scope}.name1`,
    defaultMessage: `이름`,
  },
  cardId: {
    id: `${scope}.cardId`,
    defaultMessage: `카드 ID`,
  },
  issueCount: {
    id: `${scope}.issueCount`,
    defaultMessage: `발급차수`,
  },
  masterCard: {
    id: `${scope}.masterCard`,
    defaultMessage: `마스터카드`,
  },
  departmentCard: {
    id: `${scope}.departmentCard`,
    defaultMessage: `부서카드`,
  },
  cardStatus: {
    id: `${scope}.cardStatus`,
    defaultMessage: `카드상태`,
  },
  employeeNumber: {
    id: `${scope}.employeeNumber`,
    defaultMessage: `사번`,
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: `주소`,
  },
  nationality: {
    id: `${scope}.nationality`,
    defaultMessage: `국적`,
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: `도시`,
  },
  officePhone: {
    id: `${scope}.officePhone`,
    defaultMessage: `전화번호1`,
  },
  homePhone: {
    id: `${scope}.homePhone`,
    defaultMessage: `전화번호2`,
  },
  postCode: {
    id: `${scope}.postCode`,
    defaultMessage: `우편번호`,
  },
  responsibility: {
    id: `${scope}.responsibility`,
    defaultMessage: `시스템 권한`,
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: `메모`,
  },
  titleExport: {
    id: `${scope}.titleExport`,
    defaultMessage: `내보내기`,
  },
  titleFormatFile: {
    id: `${scope}.titleFormatFile`,
    defaultMessage: `파일 형식`,
  },
  export: {
    id: `${scope}.btnExport`,
    defaultMessage: `내보내기`,
  },
  accessGroup: {
    id: `${scope}.accessGroup`,
    defaultMessage: `출입 그룹`,
  },
  doorList: {
    id: `${scope}.doorList`,
    defaultMessage: `출입그룹 문 리스트`,
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: `취소`,
  },
  expiredDate: {
    id: `${scope}.expiredDate`,
    defaultMessage: `만료일`,
  },
  effectiveDate: {
    id: `${scope}.effectiveDate`,
    defaultMessage: `발급일`,
  },
  genderMale: {
    id: `${scope}.genderMale`,
    defaultMessage: `남성`,
  },
  genderFemale: {
    id: `${scope}.genderFemale`,
    defaultMessage: `여성`,
  },
  confirmDeleteUser: {
    id: `${scope}.confirmDeleteUser`,
    defaultMessage: `Do you want to delete the user?`,
  },
  confirmInvalidUser: {
    id: `${scope}.confirmInvalidUser`,
    defaultMessage: `You will not see this user in list if you set card status to be "invalid". But you can see this in the list when you check the check box "InvalidUser". Do you want this?`,
  },
  headerUserCode: {
    id: `${scope}.headerUserCode`,
    defaultMessage: `사용자 번호`,
  },
  userCode: {
    id: `${scope}.userCode`,
    defaultMessage: `사용자 번호`,
  },
  birthDay: {
    id: `${scope}.birthDay`,
    defaultMessage: `생년월일`,
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: `소속`,
  },
  workType: {
    id: `${scope}.workType`,
    defaultMessage: `근무 형태`,
  },
  workingTimeType: {
    id: `${scope}.workingTimeType`,
    defaultMessage: `Working Time Type`,
  },
  position: {
    id: `${scope}.position`,
    defaultMessage: `직책 및 직급`,
  },
  userStatus: {
    id: `${scope}.userStatus`,
    defaultMessage: `상태`,
  },
  enableUser: {
    id: `${scope}.enableUser`,
    defaultMessage: `사용`,
  },
  cardType: {
    id: `${scope}.cardType`,
    defaultMessage: `카드 구분`,
  },
  cardID: {
    id: `${scope}.cardID`,
    defaultMessage: `카드 ID`,
  },
  reissueCnt: {
    id: `${scope}.reissueCnt`,
    defaultMessage: `발급차수`,
  },
  cardListTypo: {
    id: `${scope}.cardListTypo`,
    defaultMessage: `카드목록`,
  },
  userPasswd: {
    id: `${scope}.userPasswd`,
    defaultMessage: `비밀번호`,
  },
  confirmuserPasswd: {
    id: `${scope}.confirmuserPasswd`,
    defaultMessage: `비밀번호 확인`,
  },
  whyRegist: {
    id: `${scope}.whyRegist`,
    defaultMessage: `신청 사유`,
  },
  userCardList: {
    id: `${scope}.userCardList`,
    defaultMessage: `User Card List`,
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: `Description`,
  },
  editCardModalTitle: {
    id: `${scope}.editCardModalTitle`,
    defaultMessage: `Edit Card`,
  },
  btnAdd: {
    id: `${scope}.btnAdd`,
    defaultMessage: `추가`,
  },
  headerCardId: {
    id: `${scope}.headerCardId`,
    defaultMessage: `카드 ID`,
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: `이메일`,
  },
  titleUserToolBarImport: {
    id: `${scope}.titleUserToolBarImport`,
    defaultMessage: `Import`,
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: `Import`,
  },
  deleteCards: {
    id: `${scope}.deleteCards`,
    defaultMessage: `Do you want to delete selected card(s)?`,
  },
  invalidEmail: {
    id: `${scope}.invalidEmail`,
    defaultMessage: "Email can not be blank.",
  },
  invalidUserName: {
    id: `${scope}.invalidUserName`,
    defaultMessage: "Username can not be blank.",
  },
  invalidNationalIdNumber: {
    id: `${scope}.invalidNationalIdNumber`,
    defaultMessage: "National Id Number can not be blank.",
  },
  invalidEmailFormat: {
    id: `${scope}.invalidEmailFormat`,
    defaultMessage: "Invalid email address.",
  },
  avatar: {
    id: `${scope}.avatar`,
    defaultMessage: `이미지 첨부`,
  },
  valid: {
    id: `${scope}.valid`,
    defaultMessage: `Valid`,
  },
  invalid: {
    id: `${scope}.invalid`,
    defaultMessage: `Invalid`,
  },
  validFilter: {
    id: `${scope}.validFilter`,
    defaultMessage: `Valid User`,
  },
  titleTakeAPicture: {
    id: `${scope}.titleTakeAPicture`,
    defaultMessage: `Take A Picture`,
  },
  camera: {
    id: `demasterpro.containers.VisitPage.titleTakeAPicture`,
    defaultMessage: `Camera`,
  },
  user: {
    id: `demasterpro.components.Common.user`,
    defaultMessage: `User`,
  },
  userType: {
    id: `${scope}.userType`,
    defaultMessage: `User Type`,
  },
  approveModalTitle: {
    id: `${scope}.approveModalTitle`,
    defaultMessage: `User Approve`,
  },
  btnTakePhoto: {
    id: `${scope}.btnTakePhoto`,
    defaultMessage: "Take Photo",
  },
  cameraDeviceList: {
    id: `${scope}.cameraDeviceList`,
    defaultMessage: "Camera Device List",
  },
  btnEdit: {
    id: `demasterpro.components.Datatables.btnEdit`,
    defaultMessage: `Edit`,
  },
  vehicle: {
    id: `${scope}.vehicle`,
    defaultMessage: `Vehicle`,
  },
  model: {
    id: `${scope}.model`,
    defaultMessage: `Model`,
  },
  plateNumber: {
    id: `${scope}.plateNumber`,
    defaultMessage: `Plate Number`,
  },
  color: {
    id: `${scope}.color`,
    defaultMessage: `Color`,
  },
  confirmDelete: {
    id: `${scope}.confirmDelete`,
    defaultMessage: `Do you want to delete ?`,
  },
  approveUser: {
    id: `${scope}.approveUser`,
    defaultMessage: `Approval User`,
  },
  reason: {
    id: `demasterpro.containers.VisitPage.reason`,
    defaultMessage: "사유",
  },
  btnApproval: {
    id: `demasterpro.containers.VisitPage.btnApproval`,
    defaultMessage: `결재`,
  },
  btnDeny: {
    id: `demasterpro.containers.VisitPage.btnDeny`,
    defaultMessage: `거절`,
  },
  firstApproverAccounts: {
    id: `demasterpro.containers.VisitSetting.firstApproverAccounts`,
    defaultMessage: `First Approver Accounts`,
  },
  secondApproverAccounts: {
    id: `demasterpro.containers.VisitSetting.secondsApproverAccounts`,
    defaultMessage: "Second Approver Accounts",
  },
  btnClear: {
    id: `demasterpro.containers.ReportPage.btnClear`,
    defaultMessage: `Clear`,
  },
  btnSearch: {
    id: `demasterpro.containers.ReportPage.btnSearch`,
    defaultMessage: `Search`,
  },
  imageCardIdFont: {
    id: `${scope}.imageCardIdFont`,
    defaultMessage: `Image Card Id Front`,
  },
  imageCardIdBack: {
    id: `${scope}.imageCardIdBack`,
    defaultMessage: `Image Card Id Back`,
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: `Name`,
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: `Gender`,
  },
  nationalIdNumber: {
    id: `${scope}.nationalIdNumber`,
    defaultMessage: `National Id Number`,
  },
  chooseAvatar: {
    id: `${scope}.chooseAvatar`,
    defaultMessage: `Choose avatar`,
  },
  titleRegisterUser: {
    id: `${scope}.titleRegisterUser`,
    defaultMessage: `Register New User`,
  },
  descriptionRegisterUser: {
    id: `${scope}.descriptionRegisterUser`,
    defaultMessage: `Please fill form to register new account`,
  },

  confirmRegisterUser: {
    id: `${scope}.confirmRegisterUser`,
    defaultMessage: `Do you want to complete your application?`,
  },
  btnAddFingerPrint: {
    id: `${scope}.btnAddFingerPrint`,
    defaultMessage: `+ Add Finger Print`,
  },
  labelScan: {
    id: `${scope}.labelScan`,
    defaultMessage: `Scan`,
  },
  labelDelete: {
    id: `${scope}.labelDelete`,
    defaultMessage: `Delete`,
  },
  selectFingerPrint: {
    id: `${scope}.selectFingerPrint`,
    defaultMessage: `Select Device Scan Fingerprint`,
  },
  stop: {
    id: `${scope}.stop`,
    defaultMessage: `Stop`,
  },
  verify: {
    id: `${scope}.verify`,
    defaultMessage: `Verify`,
  },
  pressScan: {
    id: `${scope}.pressScan`,
    defaultMessage: `Press scan to add finger print`,
  },
  scanning: {
    id: `${scope}.scanning`,
    defaultMessage: `Please put your finger on the device`,
  },
  pressVerify: {
    id: `${scope}.pressVerify`,
    defaultMessage: `Press verify to verify finger print`,
  },
  completeScan: {
    id: `${scope}.completeScan`,
    defaultMessage: `Complete scan finger print!`,
  },
});
