import React from "react";
import { styled } from "styled-components";
import { useSetRecoilState } from "recoil";
import { menuOverlayState, mouseLocaleState } from "$utils/atom";

export const Overlay = ({ onClick, open, children, zIndex, style = {} }) => {
  const setIsOverlay = useSetRecoilState(menuOverlayState);
  const setMouseLocale = useSetRecoilState(mouseLocaleState);
  return (
    <Modal open={open} zindex={zIndex}>
      {/* overlay의 자식으로 box를 둘 경우 box안에서 mousedown 후 overlay 부분에서 mouseup 했을 때도 overlay가 사라짐. 그러므로 box와 overlay를 분리*/}
      <OverlayBox onClick={onClick} />
      <Box
        style={style}
        onClick={() => {
          setIsOverlay(false);
          setMouseLocale(null);
        }}
        tabIndex="-1"
      >
        {children}
      </Box>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: ${(props) => Number(props.zindex) || 1300};
`;
const OverlayBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
`;
const Box = styled.div`
  position: absolute;
  margin: 0 auto;
  z-index: 1;
`;
