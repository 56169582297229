import React, { lazy } from "react";
import { Login } from "./Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CommonRouter } from "./CommonRouter";
const ErrorPage = lazy(() => import("./error-page"));

const PublicRouter = ({ isAuthed, auth, permission }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Login isAuthed={isAuthed} auth={auth} permission={permission} />
          }
        />
        <Route path="/*" element={<ErrorPage />} />
        {CommonRouter()}
      </Routes>
    </BrowserRouter>
  );
};
export default PublicRouter;
