import React, { useEffect, useState } from "react";
import {
  EachContentBoxV3,
  EachEventDataBoxV3,
  EachEventImgV3,
  EachEventTextBoxV3,
  EachEventTextV3,
  NoContentBox,
  SubMonitoringbox,
} from "../styles";
import { styled } from "styled-components";
import { Time } from "../Time";
import { GetSubDisplayEventInfo } from "../api";
import { NORMAL_TYPES, convertDataDisplayMonitoring } from "../utils";
import { HandleEventDataMqtt } from "../handleEventDataMqtt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { deviceMonitoringMessages } from "routes/DeviceMonitoirng/messages";
import { GLOBAL_COLOR } from "$utils/constants";
import { cardIcon } from "routes/Monitoring/utils";
import { format } from "date-fns";
import { withSubMonitoring } from "../withSubMonitoring";

export const SubMonitoringThree = withSubMonitoring(({ token, jsonData }) => {
  const { data } = GetSubDisplayEventInfo(token);
  const [eventData, setEventData] = useState([]);
  const [eventUsers, setEventUsers] = useState(0);
  const [eventVisitors, setEventVisitors] = useState(0);
  const [eventAbnormal, setEventAbnormal] = useState(0);
  const [eventCanteen, setEventCanteen] = useState(0);
  useEffect(() => {
    if (data) {
      setEventUsers(data?.eventUsers);
      setEventVisitors(data?.eventVisitors);
      setEventAbnormal(data?.eventAbnormal);
      setEventCanteen(data?.eventCanteen);
      setEventData(
        convertDataDisplayMonitoring(data.eventLogs?.data, data.eventTypes)
      );
    }
  }, [data]);
  return (
    <SubMonitoringbox>
      <TimeBox>
        <Time />
      </TimeBox>
      {data && (
        <>
          {eventData && (
            <HandleEventDataMqtt
              jsonData={jsonData}
              eventData={eventData}
              setEventData={setEventData}
              setVisitData={() => {}}
              typeDetails={data.eventTypes}
              typeStatusDetails={data.visitorStatusTypes}
              setEventAbnormal={setEventAbnormal}
              setEventCanteen={setEventCanteen}
              setEventVisitors={setEventVisitors}
              setEventUsers={setEventUsers}
            />
          )}
          <Box>
            {eventData?.length > 0 ? (
              <Content>
                {eventData.map((item) => (
                  <EachContent key={item.id}>
                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                        fontSize: "30px",
                        color: GLOBAL_COLOR.grayColor,
                      }}
                    >
                      {cardIcon[item.cardTypeId]}
                    </div>
                    {item.doorName}
                    <EachContentBoxV3>
                      <EachEventImgV3
                        src={item.avatar || "/images/avatar-male-default.jpg"}
                      />
                      <EachEventDataBoxV3>
                        <EachEventTextBoxV3>
                          <FlexBox>
                            <EachEventTextV3>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerName}
                              />
                            </EachEventTextV3>
                            <span>{item.userName}</span>
                          </FlexBox>
                        </EachEventTextBoxV3>
                        <EachEventTextBoxV3>
                          <FlexBox>
                            <EachEventTextV3>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerDepartment}
                              />
                            </EachEventTextV3>
                            <span>{item.department}</span>
                          </FlexBox>
                        </EachEventTextBoxV3>
                        <EachEventTextBoxV3>
                          <FlexBox>
                            <EachEventTextV3>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerEventDetail}
                              />
                            </EachEventTextV3>
                            <span
                              style={{
                                color:
                                  NORMAL_TYPES.includes(item.eventType) ||
                                  NORMAL_TYPES.includes(item.eventDetailCode)
                                    ? GLOBAL_COLOR.greenColor
                                    : GLOBAL_COLOR.redColor,
                              }}
                            >
                              {item.eventDetail}
                            </span>
                          </FlexBox>
                        </EachEventTextBoxV3>
                        <EachEventTextBoxV3>
                          <FlexBox>
                            <EachEventTextV3>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerAccessTime}
                              />
                            </EachEventTextV3>
                            <span>
                              {format(
                                new Date(parseInt(item.unixTime, 10) * 1000),
                                "HH:mm:ss"
                              )}
                            </span>
                          </FlexBox>
                        </EachEventTextBoxV3>
                      </EachEventDataBoxV3>
                    </EachContentBoxV3>
                  </EachContent>
                ))}
              </Content>
            ) : (
              <NoContentBox>
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span>No Content</span>
              </NoContentBox>
            )}
          </Box>
        </>
      )}
    </SubMonitoringbox>
  );
});

const TimeBox = styled.div`
  font-size: 22px;
  display: flex;
  justify-content: center;
  padding: 10px;
  color: ${(props) => props.theme.grayColor};
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
`;
const Box = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 5px 0;
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 50px;
`;
const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
`;
const EachContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.lightGrayColor};
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
`;
const FlexBox = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
