import React from "react";
import { menuMessages } from "../messages";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import { AdminSubMenuList, SubMenuList } from "../constants";
import { GLOBAL_COLOR } from "$utils/constants";

const USER = "user";
const VISIT = "visitApplicationManagement";

export const MenuListComponent = ({
  menu,
  intl,
  accountType,
  isAdmin,
  isFirst,
  isLast,
  handleListItemClick,
  mainMenu,
  selectedIndex,
  countReview,
  isHover,
  setIsHover,
}) => {
  const message = menuMessages[menu.id];
  const subMenus = isAdmin
    ? AdminSubMenuList[menu.id]
    : SubMenuList(accountType)[menu.id];
  return (
    <MenuBox>
      <MenuTitle
        onMouseEnter={!isHover ? () => setIsHover(true) : undefined}
        selected={mainMenu === menu.id}
      >
        {menu.id === USER && countReview?.user ? (
          <Count>{countReview.user}</Count>
        ) : null}
        {menu.id === VISIT && countReview?.visit ? (
          <Count>{countReview.visit}</Count>
        ) : null}
        {message &&
          intl.formatMessage({
            id: message.id,
          })}
      </MenuTitle>
      <Background />
      {isHover && (
        <SubMenuWrapper
          initial={{ y: -350, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -350, opacity: 0 }}
          isAdmin={isAdmin}
          isFirst={isFirst}
          isLast={isLast}
          transition={{
            ease: "easeInOut",
            duration: 0.2,
          }}
        >
          {subMenus.map((submenu) => {
            if (submenu.isAvailable) {
              const message = menuMessages[submenu.id];
              return (
                <SubMenu
                  whileHover={{
                    backgroundColor: GLOBAL_COLOR.lightGreenColor,
                  }}
                  selected={selectedIndex === submenu.route}
                  transition={{
                    duration: 0.1,
                  }}
                  onClick={() => handleListItemClick(submenu.route, menu.id)}
                >
                  {message &&
                    intl.formatMessage({
                      id: message.id,
                    })}
                </SubMenu>
              );
            }
          })}
        </SubMenuWrapper>
      )}
    </MenuBox>
  );
};

const MenuBox = styled(motion.div)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
`;
const MenuTitle = styled.div`
  position: relative;
  padding: 1px 15px;
  z-index: 2;
  cursor: default;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  color: ${(props) =>
    props.selected ? props.theme.greenColor : props.theme.darkGrayColor};
`;
const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding-right: 70px;
  z-index: 1;
  background-color: ${(props) => props.theme.lightGrayColor};
`;
const SubMenuWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: calc(100% + 10px);
  top: 59px;
  min-height: ${(props) => (props.isAdmin ? "220px" : "355px")};
  background-color: ${(props) => props.theme.lightGrayColor};
  padding: 5px;
  padding-right: 10px;
  padding-bottom: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: ${(props) =>
    props.isFirst ? "2px solid rgba(0, 0, 0, 0.2)" : null};
  border-right: ${(props) =>
    props.isLast ? "2px solid rgba(0, 0, 0, 0.2)" : null};
`;
const SubMenu = styled(motion.div)`
  cursor: pointer;
  font-size: 11px;
  background-color: ${(props) => props.theme.lightGrayColor};
  padding: 15px 10px;
  width: 100%;
  word-break: keep-all;
  word-wrap: break-word;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  color: ${(props) =>
    props.selected ? props.theme.greenColor : props.theme.darkGrayColor};
`;
const Count = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  color: white;
  background-color: ${(props) => props.theme.orangeColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -10px;
  right: 1px;
  z-index: -1;
  font-size: 10px;
  font-weight: normal;
`;
