import React from "react";
import { menuOverlayState, mouseLocaleState } from "$utils/atom";
import { useSetRecoilState } from "recoil";
import { styled } from "styled-components";
import { ContextContentsBox } from "./ContextContentsBox";

export const ContextComponent = ({
  children,
  contextMenu,
  closeEvent,
  noFullHeight,
  tableId,
  styles = {},
}) => {
  const setMenuOverlay = useSetRecoilState(menuOverlayState);
  const setMouseLocale = useSetRecoilState(mouseLocaleState);
  const onOpenContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setMenuOverlay(tableId);
      setMouseLocale({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  return (
    <>
      {contextMenu ? (
        <ContextBox
          onContextMenu={onOpenContextMenu}
          height={noFullHeight ? "fit-content" : "100%"}
          className="ContextBox"
          style={{ ...styles }}
        >
          {children}
          <ContextContentsBox
            contextMenu={contextMenu}
            closeEvent={closeEvent}
            tableId={tableId}
          />
        </ContextBox>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const ContextBox = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  overflow: hidden;
`;
