import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { GetLastLogin, GetLogo, UpdateCountReview } from "../api";
import { WebOverlayHeader } from "./WebOverlayHeader";

const withWebOverlay = (Component) => {
  return (props) => {
    const { data: logo, isLoading } = GetLogo();
    const [logoData, setLogoData] = useState();
    useEffect(() => {
      if (!isLoading) {
        setLogoData(
          <img
            height="35px"
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.navigate("/")}
            alt="logo"
            src={logo?.logo || "/images/dmpIcon.png"}
          />
        );
      }
    }, [isLoading]);
    return logoData && <Component logo={logoData} {...props} />;
  };
};

export const WebOverlay = withWebOverlay(
  ({ accountType, navigate, children, logo }) => {
    const { data } = GetLastLogin();
    const { data: countReview } = UpdateCountReview();
    const onGetLastLogin = async () => {
      localStorage.setItem("lastLogin", JSON.stringify(data));
    };
    useEffect(() => {
      if (data) {
        onGetLastLogin();
      }
    }, [data]);
    const [loadFlag, setLoadFlag] = useState(false);
    return (
      <WebOverlayContainer>
        <WebOverlayHeader
          accountType={accountType}
          logo={logo}
          navigate={navigate}
          setLoadFlag={setLoadFlag}
          countReview={countReview}
        />
        <WebContentsBox>
          <WebContents
            key={String(loadFlag)}
            id="scrolledContents"
            className="noScrollbar"
          >
            {children}
          </WebContents>
        </WebContentsBox>
      </WebOverlayContainer>
    );
  }
);
const WebOverlayContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${(props) => props.theme.lightGrayColor};
`;
const WebContentsBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px 30px;
  padding-top: 15px;
`;
const WebContents = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
`;
