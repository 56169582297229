import React from "react";
import { StyledBtn } from "../style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export const CancelBtn = ({ isFocus, value, reset }) => {
  return (
    <StyledBtn
      onClick={reset}
      whileHover={{ scale: 1.1 }}
      initial={{
        opacity: 0,
        y: 50,
      }}
      animate={{
        opacity: isFocus || value ? 1 : 0,
        y: isFocus || value ? 0 : 50,
      }}
    >
      <FontAwesomeIcon icon={faTimesCircle} />
    </StyledBtn>
  );
};
