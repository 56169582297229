import React, { memo, useEffect, useState } from "react";
import { ButtonComponent } from "$components/ButtonComponent";
import { localstoreUtilites } from "$utils/persistenceData";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil";
import { menuOverlayState } from "$utils/atom";
import {
  faInfoCircle,
  faPowerOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TYPE_ACCOUNT } from "$utils/constants";
import { NotificationComponent } from "./NotificationComponent";
import { LogOut } from "$utils/libs/loginLibs";
import { privateRouteMessages } from "$components/PrivateRoute/PrivateRouteWrapper/messages";
import { GetNotifications } from "$components/PrivateRoute/PrivateRouteWrapper/api";

const WebProfileMenu = ({ accountType, navigate }) => {
  const username = localstoreUtilites.getUsernameFromLocalStorage();
  const [open, setOpen] = useState(false);
  const [isOverlay, setIsOverlay] = useRecoilState(menuOverlayState);
  const { data, refetch } = GetNotifications();
  useEffect(() => {
    if (!isOverlay) {
      setOpen(false);
    }
  }, [isOverlay]);
  const isPrimaryManager = accountType === TYPE_ACCOUNT.primaryManager;
  return (
    <>
      {data && !open && data?.meta?.totalUnRead > 0 && (
        <NotificationCount>{data.meta.totalUnRead}</NotificationCount>
      )}
      <StyledMenuBoxContainer>
        <ButtonComponent
          icon={<FontAwesomeIcon size="1x" icon={faUser} />}
          onClick={(event) => {
            event.stopPropagation();
            setIsOverlay((prev) => !prev);
            setOpen((prev) => !prev);
          }}
          text={username}
          fontSize="11px"
          height="40px"
          shadow={open ? open.toString() : false}
          style={{
            padding: "0 15px",
            zIndex: 2,
          }}
          borderRadius="20px"
        />
        <StyledOpenedMenuBox animate={{ height: open ? "fit-content" : "0px" }}>
          <StyledMenuBox
            initial={{ opacity: 0, y: -150 }}
            animate={{ opacity: open ? 1 : 0, y: open ? 0 : -150 }}
            transition={{
              ease: "linear",
              duration: 0.1,
            }}
            isprimary={isPrimaryManager ? isPrimaryManager.toString() : null}
          >
            <ButtonComponent
              icon={<FontAwesomeIcon size="lg" icon={faInfoCircle} />}
              onClick={() => navigate("/profile")}
              text={<FormattedMessage {...privateRouteMessages.goToProfile} />}
              fontSize="10px"
              height="40px"
              shadow={false}
              justify="end"
              style={{ paddingRight: "15px" }}
            />
            {isPrimaryManager && data && open && (
              <NotificationComponent data={data} refetch={refetch} />
            )}
            <ButtonComponent
              icon={<FontAwesomeIcon icon={faPowerOff} />}
              onClick={() => LogOut()}
              text={<FormattedMessage {...privateRouteMessages.logout} />}
              fontSize="10px"
              height="40px"
              shadow={false}
              justify="end"
              style={{ paddingRight: "15px" }}
            />
          </StyledMenuBox>
        </StyledOpenedMenuBox>
      </StyledMenuBoxContainer>
    </>
  );
};
export default memo(WebProfileMenu);
const StyledMenuBoxContainer = styled.div`
  width: 150px;
  font-size: 11px;
`;
const StyledOpenedMenuBox = styled(motion.div)`
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 20px;
  width: 100%;
`;
const StyledMenuBox = styled(motion.div)`
  background-color: ${(props) => props.theme.grayColor};
  height: ${(props) => (props.isprimary ? "155px" : "115px")};
  padding-top: 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)";
`;
const NotificationCount = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.orangeColor};
  border-radius: 50%;
  z-index: 1;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -9px;
  top: -9px;
  color: ${(props) => props.theme.lightGrayColor};
`;
