import React from "react";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import WebProfileMenu from "./Components/WebProfileMenu";
import { Menus } from "./Components/Menus";
import { PageNameComponent } from "./Components/PageNameComponent";

export const WebOverlayHeader = ({
  accountType,
  logo,
  navigate,
  setLoadFlag,
  countReview,
}) => {
  return (
    <WebOverlayHeaderBox transition={{ ease: "linear", duration: 0.215 }}>
      <FlexBox>
        {logo}
        <Menus
          navigate={navigate}
          accountType={accountType}
          setLoadFlag={setLoadFlag}
          countReview={countReview}
        />
      </FlexBox>
      <FlexBox
        style={{
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <PageNameComponent />
        <StyledButtonBox>
          <WebProfileMenu accountType={accountType} navigate={navigate} />
        </StyledButtonBox>
      </FlexBox>
    </WebOverlayHeaderBox>
  );
};
const WebOverlayHeaderBox = styled(motion.div)`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.05) 0px 4px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 10px 0px;
  z-index: 30;
  background-color: red;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.lightGrayColor};
  position: relative;
  height: 64px;
  flex-shrink: 0;
  box-sizing: border-box;
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 20px;
`;
const StyledButtonBox = styled.div`
  position: relative;
`;
