import { AvatarComponent } from "$components/AvatarComponent";
import { GLOBAL_COLOR } from "$utils/constants";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { deviceMonitoringMessages } from "routes/DeviceMonitoirng/messages";
const avatarDefault = "/images/avatar-male-default.jpg";

export const headerBoxes = [
  <FormattedMessage {...deviceMonitoringMessages.headerRecentEvent} />,
  <FormattedMessage {...deviceMonitoringMessages.headerPreviousEvent} />,
];
export const LIST_CULTURE = [
  {
    language: "en-US",
    cultureCode: 0,
    dateTimeFormat: "MM.dd.yyyy HH:mm:ss",
  },
  {
    language: "ja-JP",
    cultureCode: 1,
    dateTimeFormat: "yyyy.MM.dd HH:mm:ss",
  },
  {
    language: "ko-KR",
    cultureCode: 2,
    dateTimeFormat: "yyyy.MM.dd HH:mm:ss",
  },
  {
    language: "vi-VN",
    cultureCode: 3,
    dateTimeFormat: "dd/MM/yyyy HH:mm:ss",
  },
];
export const NORMAL_TYPES = [1, 50, 101, 102, 103, 104, 105];
export const CANTEEN_TYPES = [101, 102, 103, 104, 105];
export const LIMIT_STORED = 100;

export const EVENT_TYPES = [
  1, 2, 3, 4, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 30, 45, 47, 48, 50, 51,
  58, 59, 60, 101, 102, 103, 104, 105,
];
export const convertDataDisplayMonitoring = (data, eventTypes) => {
  const newData = [];
  data.forEach((element) => {
    var eventType = eventTypes.find(
      (m) => m.id == element.eventType || m.id == element.eventDetailCode
    );
    newData.push({
      ...element,
      accessTime:
        element.unixTime &&
        format(new Date(parseInt(element.unixTime, 10) * 1000), "HH:mm:ss"),
      eventDetail: eventType ? eventType.name : element.eventDetail,
    });
  });

  return newData;
};
const yellowStatus = [0, 1];
const greenStatus = [2];
const redStatus = [3];
const initStyleRowVisitorByStatus = (statusCode) => {
  const style = { height: 28, position: "relative" };
  if (yellowStatus.includes(statusCode)) {
    return { ...style, backgroundColor: GLOBAL_COLOR.lightYellowColor };
  } else if (greenStatus.includes(statusCode)) {
    return { ...style, backgroundColor: GLOBAL_COLOR.lightGreenColor };
  } else if (redStatus.includes(statusCode)) {
    return { ...style, backgroundColor: GLOBAL_COLOR.lightRedColor };
  }
  return undefined;
};

export const convertVisitorDisplayMonitoring = (data, visitorStatusTypes) => {
  const newData = [];

  data.forEach((element) => {
    const visitorStatus = visitorStatusTypes.find(
      (m) => m.id === element.statusCode
    );
    newData.push({
      ...element,
      processStatus: visitorStatus ? visitorStatus.name : element.processStatus,
      style: initStyleRowVisitorByStatus(element.statusCode),
      visitorNameHtml: (
        <div
          style={{ display: "flex", alignItems: "center" }}
          key={`${element.visitorName}_grid`}
        >
          <AvatarComponent src={element.avatar || avatarDefault} />
          <span key={`${element.visitorName}_span`} style={{ marginLeft: 10 }}>
            {element.visitorName}
          </span>
        </div>
      ),
    });
  });

  return newData;
};
export const resetListVisitors = (visitors, newVisitor, eventNormal) => {
  let newVisitors = [];
  if (newVisitor) {
    const filterVisitors = visitors.filter((m) => m.id !== newVisitor.id);
    if (
      greenStatus.includes(newVisitor.statusCode) ||
      redStatus.includes(newVisitor.statusCode)
    ) {
      newVisitors = [newVisitor, ...filterVisitors];
    } else {
      newVisitors = [...filterVisitors, newVisitor];
    }
  } else {
    newVisitors = visitors;
  }
  return newVisitors;
};
export const headers = [
  {
    id: "accessTime",
    label: <FormattedMessage {...deviceMonitoringMessages.headerAccessTime} />,
  },
  {
    id: "cardId",
    label: <FormattedMessage {...deviceMonitoringMessages.headerCardID} />,
  },
  {
    id: "userName",
    label: <FormattedMessage {...deviceMonitoringMessages.headerName} />,
  },
  {
    id: "department",
    label: <FormattedMessage {...deviceMonitoringMessages.headerDepartment} />,
  },
  {
    id: "bodyTemperature",
    label: (
      <FormattedMessage {...deviceMonitoringMessages.headerBodyTemperature} />
    ),
  },
  {
    id: "doorName",
    label: <FormattedMessage {...deviceMonitoringMessages.headerDoorName} />,
  },
  {
    id: "eventDetail",
    label: <FormattedMessage {...deviceMonitoringMessages.headerEventDetail} />,
  },
  {
    id: "inOut",
    label: <FormattedMessage {...deviceMonitoringMessages.headerInOut} />,
  },
];

export const headersVisitor = [
  {
    id: "visitorNameHtml",
    label: <FormattedMessage {...deviceMonitoringMessages.visitorName} />,
  },
  {
    id: "phone",
    label: <FormattedMessage {...deviceMonitoringMessages.headerPhone} />,
  },
  {
    id: "visiteeName",
    label: <FormattedMessage {...deviceMonitoringMessages.headerVisiteeName} />,
  },
  {
    id: "visiteeSite",
    label: <FormattedMessage {...deviceMonitoringMessages.headerVisiteeSite} />,
  },
  {
    id: "processStatus",
    label: <FormattedMessage {...deviceMonitoringMessages.headerStatus} />,
  },
];
