import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { EachOption } from "./EachOption";
import { ClickSelect } from "./ClickSelect";
import { EachOptionBox } from "$components/SelectComponent/style";
import CheckBoxComponent from "$components/CheckBoxComponent";
import { FormattedMessage } from "react-intl";
import { appMessages } from "messages";

export const AutoCompleteComponent = ({
  id,
  value,
  onChange,
  options = [],
  disabled,
  label,
  isMulti = false,
  darkTheme = false,
  width = "200px",
  height = "35px",
  fontSize = "12px",
  required = false,
  onDoubleClick = () => {},
}) => {
  const [text, setText] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [isDown, setIsDown] = useState(true);
  const [position, setPosition] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    if (!isFocus) {
      setText("");
    }
  }, [isFocus]);
  return (
    <AutoCompleteComponentBox onDoubleClick={onDoubleClick} width={width}>
      {label && (
        <StyledLabel
          required={required}
          disabled={disabled}
          darktheme={darkTheme ? darkTheme.toString() : null}
        >
          {label} {required && "*"}
        </StyledLabel>
      )}
      <SelectBox>
        <ClickSelect
          id={id}
          value={value}
          options={options}
          isMulti={isMulti}
          setIsFocus={setIsFocus}
          setPosition={setPosition}
          fontSize={fontSize}
          height={height}
          isFocus={isFocus}
          setFilteredOptions={setFilteredOptions}
          darkTheme={darkTheme}
          setIsDown={setIsDown}
          disabled={disabled}
          text={text}
          setText={setText}
        />
        {isFocus && position && !disabled && (
          <OptionListBox
            style={{
              top: isDown
                ? `calc(${position.y}px + ${height} + 5px)`
                : `calc(${position.y}px - ${
                    filteredOptions.length * 35 > 350
                      ? 350
                      : filteredOptions.length * 35
                  }px - 10px)`,
              left: position.x,
            }}
            width={width}
          >
            <OptionsList
              className="subScrollbar"
              initial={{
                y: isDown
                  ? -(filteredOptions.length * 40)
                  : filteredOptions.length * 40,
                opacity: 0,
              }}
              animate={{
                y: isFocus
                  ? 0
                  : isDown
                  ? -(filteredOptions.length * 40)
                  : filteredOptions.length * 40,
                opacity: isFocus ? 1 : 0,
              }}
              transition={{
                ease: "linear",
                duration: 0.1,
              }}
              fontSize={fontSize}
            >
              {isMulti && options?.length === filteredOptions?.length && (
                <EachOptionBox
                  whileHover={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (value.length === options.length) {
                      onChange([]);
                    } else {
                      onChange(options);
                    }
                  }}
                >
                  <CheckBoxComponent
                    id={`${id}_checkBox`}
                    value={value.length === options.length}
                    setValue={() => {}}
                  />
                  <span>
                    {value.length === options.length ? (
                      <FormattedMessage {...appMessages.unselectAll} />
                    ) : (
                      <FormattedMessage {...appMessages.selectAll} />
                    )}
                  </span>
                </EachOptionBox>
              )}
              {filteredOptions.map((item, index) => (
                <EachOption
                  key={index}
                  value={value}
                  item={item}
                  isMulti={isMulti}
                  onChange={onChange}
                  setIsFocus={setIsFocus}
                  setText={setText}
                />
              ))}
            </OptionsList>
          </OptionListBox>
        )}
      </SelectBox>
    </AutoCompleteComponentBox>
  );
};
const AutoCompleteComponentBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${(props) => props.width};
`;
const StyledLabel = styled.div`
  font-size: 11px;
  color: ${(props) =>
    props.disabled
      ? "rgba(0, 0, 0, 0.3)"
      : props.required
      ? props.theme.orangeColor
      : props.darktheme
      ? "rgba(255,255,255,0.5)"
      : props.theme.darkGrayColor};
  display: flex;
  align-items: center;
  position: absolute;
  background-color: ${(props) => props.theme.lightGrayColor};
  top: -4px;
  left: 8px;
  width: fit-content;
  z-index: 10;
  display: flex;
  gap: 3px;
`;
const SelectBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
`;
const OptionListBox = styled.div`
  position: fixed;
  width: ${(props) => props.width};
  overflow: hidden;
  z-index: 20;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
const OptionsList = styled(motion.div)`
  background-color: white;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.fontSize};
  max-height: 350px;
  overflow-y: scroll;
  cursor: pointer;
  border-radius: 3px;
`;
