import React from "react";
import { styled } from "styled-components";

export const ErrorMsg = ({ text = "" }) => {
  return <ErrorText>{text}</ErrorText>;
};
const ErrorText = styled.span`
  font-size: 12px;
  color: tomato;
`;
