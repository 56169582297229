import { useEffect, useRef } from "react";

export const useDidMountEffect = (func, deps) => {
  // 처음 렌더링되었을 때 실행되지 않도록 만든 커스텀 훅
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
