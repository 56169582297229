const { styled } = require("styled-components");

export const CommonAvatar = styled.div`
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.orangeColor};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;
