import React, { useEffect, useState } from "react";

export const Time = () => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const id = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(id);
  }, []);
  return <>{time.toLocaleString()}</>;
};
