export const reformatHeaders = (headers) => {
  const newHeaders = headers
    .filter((item) => item.headerVariable !== "Action")
    .filter((item) => item.headerVariable !== "Id")
    .map((item) => {
      let returnVal = { ...item };
      const str = returnVal.headerVariable.substr(0, 1);
      return {
        ...returnVal,
        id: returnVal.headerVariable.replace(str, str.toLowerCase()),
        label: item.headerName,
        property: returnVal.headerVariable.replace(str, str.toLowerCase()),
      };
    });
  return newHeaders;
};

export const MakeQuery = (data) => {
  const Querydata = Object.keys(data).map((key) => {
    if (data[key]) {
      if (typeof data[key] === "string" || typeof data[key] === "number") {
        return `${key}=${data[key]}&`;
      }
      if (Array.isArray(data[key]) && data[key].length > 0) {
        if (data[key].join("").trim() === "") {
          return "";
        }
        const query = data[key]
          .map((item) =>
            item.id || item.id === 0 ? `${key}=${item.id}&` : `${key}=${item}&`
          )
          .join("");
        return query;
      }
      if (Object.keys(data[key]).length > 0) {
        return `${key}=${data[key].id}&`;
      }
    }
    return "";
  });
  return Querydata.join("");
};
