import {
  tableFilterState,
  tablePageState,
  tableRowsPerPageState,
  tableSortState,
} from "$utils/atom";
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";

const ResetLoadingComponent = ({ children }) => {
  const [ready, setReady] = useState(false);
  const tableFilter = useRecoilValue(tableFilterState);
  const tablePage = useRecoilValue(tablePageState);
  const tableRowsPerPage = useRecoilValue(tableRowsPerPageState);
  const tableSort = useRecoilValue(tableSortState);
  const resettablePageState = useResetRecoilState(tablePageState);
  const resettableRowsPerPageState = useResetRecoilState(tableRowsPerPageState);
  const resettableSortState = useResetRecoilState(tableSortState);
  const resettableFilterState = useResetRecoilState(tableFilterState);
  useEffect(() => {
    if (!ready) {
      if (
        Object.keys(tableFilter).length === 0 &&
        Number(tablePage) === 1 &&
        Number(tableRowsPerPage) === 25 &&
        !tableSort
      ) {
        setReady(true);
      } else {
        resettablePageState();
        resettableRowsPerPageState();
        resettableSortState();
        resettableFilterState();
      }
    }
  }, [tableFilter, tablePage, tableRowsPerPage, tableSort]);
  return <>{ready && children}</>;
};

export const PrivateRoute = ({ element, ...rest }) => {
  return (
    <Route
      element={
        <ResetLoadingComponent key={rest.path}>{element}</ResetLoadingComponent>
      }
      exact={true}
      {...rest}
    />
  );
};
