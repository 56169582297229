import { GLOBAL_COLOR } from "$utils/constants";
import { faImage, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

export const InputImageComponent = ({
  id = "doorImg",
  defaultImg,
  setData,
  width = "250px",
  height = width,
  noCircle = null,
  showIcon = true,
}) => {
  const [attachedImg, setAttachedImg] = useState(defaultImg);
  const onFileChange = (event) => {
    const {
      target: { files },
    } = event;
    if (files?.length > 0) {
      const theFile = files[0];
      const reader = new FileReader();
      reader.onloadend = (finishedEvent) => {
        const {
          currentTarget: { result },
        } = finishedEvent;
        setAttachedImg(result);
      };
      reader.readAsDataURL(theFile);
    }
  };
  useEffect(() => {
    if (attachedImg) {
      setData(attachedImg);
    }
  }, [attachedImg]);
  return (
    <InputImageComponentBox width={width} height={height}>
      <input
        id={id}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={onFileChange}
      />
      <ImageLabel
        whileHover={{
          color: GLOBAL_COLOR.orangeColor,
          border: `4px dotted ${GLOBAL_COLOR.orangeColor}`,
        }}
        transition={{ duration: 0.2 }}
        htmlFor={id}
        style={{
          border: attachedImg ? "none" : `4px dotted ${GLOBAL_COLOR.grayColor}`,
        }}
      >
        {attachedImg ? (
          <Img nocircle={noCircle} src={attachedImg.replace("(.*?)", "jpg")}>
            {showIcon && (
              <ImgIcon>
                <FontAwesomeIcon icon={faPen} />
              </ImgIcon>
            )}
          </Img>
        ) : (
          <FontAwesomeIcon icon={faImage} />
        )}
      </ImageLabel>
    </InputImageComponentBox>
  );
};
const InputImageComponentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const ImageLabel = styled(motion.label)`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.grayColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  padding: 20px;
`;
const Img = styled.div`
  background-image: url(${(props) => props.src});
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: ${(props) => (props.nocircle ? "5px" : "50%")};
  background-position: center;
  position: relative;
`;
const ImgIcon = styled.div`
  position: absolute;
  z-index: 1;
  background-color: ${(props) => props.theme.grayColor};
  color: ${(props) => props.theme.lightGrayColor};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  bottom: 5px;
`;
