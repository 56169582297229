import { TYPE_ACCOUNT } from "$utils/constants";

export function getErrorMsg(error) {
  try {
    // 에러가 json 형태가 아닐 때 err를 일으켜 try-catch로 잡음
    const parsedError = JSON.parse(error.message);
    const msg = parsedError.value
      ? parsedError.value.message
      : parsedError.error
      ? parsedError.error.message
      : parsedError.message || "Network Error!";
    return msg;
  } catch (err) {
    return "Network Error!";
  }
}

export const isViewOnly = (role) => {
  if (role === TYPE_ACCOUNT.secondaryAdmin || role === TYPE_ACCOUNT.visitor) {
    return true;
  }
  return false;
};
export function validateEmail(email) {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
