import { useFetch } from "$utils/hooks/useFetch";
import { MakeQuery } from "$utils/libs/tableLibs";
import { EVENT_TYPES } from "./utils";

export function GetShortenLink(id) {
  const res = useFetch(`/shorten-link/${id}`, `/shorten-link/${id}`);
  return res;
}

// v1
export function GetSubDisplayInfoV1(token) {
  const res = useFetch(
    `/devices/sub-display-info?token=${token}`,
    `/devices/sub-display-info?token=${token}`
  );
  return res;
}

// v2
export function GetSubDisplayEventInfo(token) {
  const res = useFetch(
    `/event-logs/screen-monitoring?pageNumber=1&pageSize=20&token=${token}&${MakeQuery(
      { eventType: EVENT_TYPES }
    )}`,
    `/event-logs/screen-monitoring?pageNumber=1&pageSize=20&token=${token}`
  );
  return res;
}
