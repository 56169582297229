import { defineMessages } from "react-intl";

export const scope = "demasterpro.components.Datatables";
export const TableMessages = defineMessages({
  exitFullScreen: {
    id: `${scope}.exitFullScreen`,
    defaultMessage: "전체 화면 종료",
  },
  fullScreenInfo: {
    id: `${scope}.fullScreenInfo`,
    defaultMessage:
      "전체 화면을 종료하시려면 버튼을 다시 누르거나 ESC 키를 눌러주세요.",
  },
  fullScreen: {
    id: `${scope}.fullScreen`,
    defaultMessage: "전체 화면",
  },
  zoomOut: {
    id: `${scope}.zoomOut`,
    defaultMessage: "테이블 확대",
  },
  zoomIn: {
    id: `${scope}.zoomIn`,
    defaultMessage: "테이블 축소",
  },
  headerTransmit: {
    id: `demasterpro.containers.TransmitAllData.headerTransmit`,
    defaultMessage: "Transmit All Data",
  },
  btnTransmit: {
    id: `demasterpro.containers.TransmitAllData.btnTransmit`,
    defaultMessage: "Transmit",
  },
  btnCancel: {
    id: `demasterpro.containers.DevicePage.btnCancel`,
    defaultMessage: `Cancel`,
  },
  btnViewDetail: {
    id: `${scope}.btnViewDetail`,
    defaultMessage: `Detail`,
  },
  btnCompare: {
    id: `${scope}.btnCompare`,
    defaultMessage: `Compare`,
  },
  btnCopySettingDevice: {
    id: `${scope}.btnCopySettingDevice`,
    defaultMessage: `Copy setting of device`,
  },
  btnReinstall: {
    id: `${scope}.btnReinstall`,
    defaultMessage: `Reinstall`,
  },
  titleChangeStatus: {
    id: `${scope}.titleChangeStatus`,
    defaultMessage: `Change Status`,
  },
  btnEdit: {
    id: `${scope}.btnEdit`,
    defaultMessage: `Edit`,
  },
  btnDelete: {
    id: `${scope}.btnDelete`,
    defaultMessage: `Delete`,
  },
  btnOpenDoor: {
    id: `${scope}.btnOpenDoor`,
    defaultMessage: `Open door`,
  },
  btnResetDevice: {
    id: `${scope}.btnResetDevice`,
    defaultMessage: `Reset Device`,
  },
  btnSendCurrentTime: {
    id: `${scope}.btnSendCurrentTime`,
    defaultMessage: `Send Current Time`,
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: `Rows per page`,
  },
  btnActionStop: {
    id: `${scope}.btnActionStop`,
    defaultMessage: `Stop`,
  },
  btnActionUpdate: {
    id: `${scope}.btnActionUpdate`,
    defaultMessage: `Update`,
  },
  btnActionRecover: {
    id: `${scope}.btnActionRecover`,
    defaultMessage: `Recovery`,
  },
  btnActionClear: {
    id: `${scope}.btnActionClear`,
    defaultMessage: `Clear`,
  },
  btnActionRestore: {
    id: `${scope}.btnActionRestore`,
    defaultMessage: `Restore`,
  },
  btnTransmitAllData: {
    id: `${scope}.btnTransmitAllData`,
    defaultMessage: `Transmit All Data`,
  },
  commonConfirm: {
    id: `${scope}.commonConfirm`,
    defaultMessage: `You sure you want to such?`,
  },
  confirmDelete: {
    id: `${scope}.confirmDelete`,
    defaultMessage: `정말 삭제하시겠습니까?`,
  },
  statusUpdating: {
    id: `${scope}.statusUpdating`,
    defaultMessage: `Updating`,
  },
  statusCompleted: {
    id: `${scope}.statusCompleted`,
    defaultMessage: `Completed`,
  },
  statusDownloading: {
    id: `${scope}.statusDownloading`,
    defaultMessage: `Downloading`,
  },
  statusStop: {
    id: `${scope}.statusStop`,
    defaultMessage: `Stop`,
  },
  statusPreparing: {
    id: `${scope}.statusPreparing`,
    defaultMessage: `Preparing`,
  },
  statusFailed: {
    id: `${scope}.statusFailed`,
    defaultMessage: `Failed`,
  },
  statusUploading: {
    id: `${scope}.statusUploading`,
    defaultMessage: `Recovering`,
  },
  statusReinstalling: {
    id: `${scope}.statusReinstalling`,
    defaultMessage: `Reinstalling`,
  },
  statusTransmitting: {
    id: `${scope}.statusTransmitting`,
    defaultMessage: `Transmitting`,
  },
  confirmReInstall: {
    id: `${scope}.confirmReInstall`,
    defaultMessage: `When reinstalled, All events in the device are deleted and the entire user is newly transferred. Would you like to proceed anyway?`,
  },
  confirmReInstallTooltip: {
    id: `${scope}.confirmReInstallTooltip`,
    defaultMessage: `Reinstall : Delete users, events in device,  All transmit`,
  },
  confirmChangeValid: {
    id: `${scope}.confirmChangeStatus`,
    defaultMessage: `유효한 문으로 변경하시겠습니까?`,
  },
  confirmChangeInvalid: {
    id: `${scope}.confirmChangeInvalid`,
    defaultMessage: `유효하지 않은 문으로 변경하시겠습니까?`,
  },
  confirmChangeStatusTooltip: {
    id: `${scope}.confirmChangeStatusTooltip`,
    defaultMessage: `Invalid Door : Only Forced Open, Release in Emergency`,
  },
  index: {
    id: `${scope}.index`,
    defaultMessage: `Index`,
  },
  btnAssignCard: {
    id: `${scope}.btnAssignCard`,
    defaultMessage: `카드 교부`,
  },
  changeSort: {
    id: `${scope}.changeSort`,
    defaultMessage: `Sort Column`,
  },
  ascending: {
    id: `${scope}.ascending`,
    defaultMessage: `Ascending`,
  },
  descending: {
    id: `${scope}.descending`,
    defaultMessage: `Descending`,
  },
  rightClickToHide: {
    id: `${scope}.rightClickToHide`,
    defaultMessage: `Right Click To Hide`,
  },
  deviceHistory: {
    id: `${scope}.deviceHistory`,
    defaultMessage: `Device History`,
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage: `WARNING!`,
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: `No Data`,
  },
  alarm: {
    id: `${scope}.alarm`,
    defaultMessage: `Alarm`,
  },
  nomal: {
    id: `demasterpro.containers.UserPage.nomal`,
    defaultMessage: `일반 차량`,
  },
  twopart: {
    id: `demasterpro.containers.UserPage.twopart`,
    defaultMessage: `2부제 차량`,
  },
  exception: {
    id: `demasterpro.containers.UserPage.exception`,
    defaultMessage: `예외 차량`,
  },
  violation: {
    id: `demasterpro.containers.UserPage.violation`,
    defaultMessage: `위반 차량`,
  },
  fivepart: {
    id: `demasterpro.containers.UserPage.fivepart`,
    defaultMessage: `5부제 차량`,
  },
  notValidUser: {
    id: `${scope}.notValidUser`,
    defaultMessage: `유효하지 않은 사용자입니다.`,
  },
  alertNoDevices: {
    id: `${scope}.alertNoDevices`,
    defaultMessage: `선택된 단말기가 없습니다.`,
  },
});
