import React, { useEffect, useState } from "react";
import { localstoreUtilites } from "$utils/persistenceData";
import { LANGUAGE } from "$utils/constants";
import { SelectComponent } from "$components/SelectComponent";

export const SelectLanguage = () => {
  const pageLanguage = localstoreUtilites.getLanguageFromLocalStorage();
  const [lang, setLang] = useState(pageLanguage);
  const options = Object.keys(LANGUAGE).map((item) => ({
    id: LANGUAGE[item],
    name: item,
  }));
  useEffect(() => {
    if (lang && lang !== pageLanguage) {
      localstoreUtilites.saveLanguageToLocalStorage(lang);
      window.location.reload();
    }
  }, [lang]);
  return (
    <SelectComponent
      id="lang"
      value={lang}
      onChange={setLang}
      options={options}
      darkTheme
      width="100px"
      height="30px"
      fontSize="12px"
    />
  );
};
