import { useFetch } from "$utils/hooks/useFetch";
import { useMutation } from "$utils/hooks/useMutation";

export function GetLastLogin() {
  const res = useFetch(`/accounts/last-login`, `GetLastLogin`);
  return res;
}
export function GetLogo() {
  const res = useFetch(`/current-logo`, `GetLogo`);
  return res;
}
export function UpdateCountReview() {
  const res = useFetch(`/accounts/waiting-for-review`, `UpdateCountReview`);
  return res;
}

export function GetNotifications() {
  const res = useFetch(
    `/notifications?pageNumber=1&pageSize=10&sortDirection=desc&sortColumn=1`,
    `notifications`
  );
  return res;
}
export function PutAllNotificationsAsRead() {
  const res = useMutation(`/read-all-notifications`, "PUT");
  return res;
}
export function DeleteAllReadNotification() {
  const res = useMutation(`/delete-all-notifications`, "DELETE");
  return res;
}
export function ChangeNotificationStatus(id) {
  const res = useMutation(`/notifications/${id}`, "PUT");
  return res;
}
export function DeleteNotification(id) {
  const res = useMutation(`/notifications/${id}`, "DELETE");
  return res;
}
