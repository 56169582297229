import { cookieExpires } from "$utils/constants";
import { localstoreUtilites } from "$utils/persistenceData";

const lang = localstoreUtilites.getLanguageFromLocalStorage();

export const saveLoginDataToLocal = (data, username, rememberMe) => {
  if (rememberMe) {
    localstoreUtilites.saveToLocalStorage(
      data.authToken,
      data.accountType,
      14 * 24 * 60
    );
  } else {
    localstoreUtilites.saveToLocalStorage(
      data.authToken,
      data.accountType,
      cookieExpires
    );
  }
  localstoreUtilites.saveRefreshTokenToLocalStorage(data.refreshToken);
  localstoreUtilites.saveConfigWSToLocalStorage(data.queueService);
  localstoreUtilites.savePluginsToLocalStorage(data.plugIn);
  localstoreUtilites.saveUserIdToLocalStorage(data.accountId);
  localstoreUtilites.saveUserCompanyIdToLocalStorage(data.userId);
  if (username) {
    localstoreUtilites.saveUsernameToLocalStorage(username);
  }
  localstoreUtilites.saveCompanycodeToLocalStorage(data.companyCode);
  localstoreUtilites.saveAccountTzToLocalStorage(data.userTimeZone);
  localstoreUtilites.savePermissionsToLocalStorage(data.permissions || {});
  localstoreUtilites.savePreferredSystemToLocalStorage(
    data.userPreferredSystem
  );
  const langData =
    lang ||
    (data.userLanguage && data.userLanguage !== "null"
      ? data.userLanguage
      : "en-US");
  localstoreUtilites.saveLanguageToLocalStorage(langData);
  window.location.reload();
};

export const LogOut = () => {
  localstoreUtilites.removeAuthFromLocalStorage();
  const userName = localstoreUtilites.getUsernameFromLocalStorage();
  localstoreUtilites.clearAllFromLocalStorage();
  if (localstoreUtilites.getIsRememberIdToLocalStorage()) {
    localstoreUtilites.saveUsernameToLocalStorage(userName);
  }
  localstoreUtilites.setTableHeightToLocalStorage(44);
  // clear mqtt
  window.DeMaster_Mqtt_Client = null;
  window.Paho = null;
  window.location.assign("/");
};
