import { ToolTipComponent } from "$components/ToolTipComponent";
import React, { useEffect, useState } from "react";
import { PaginantionSpan } from "./style";
import { useResetRecoilState } from "recoil";
import { TooltipTextState } from "$utils/atom";
import { AutoCompleteComponent } from "$components/AutoCompleteComponent";

export const ChangePageComponent = ({
  tableId,
  total,
  rowLength,
  totalPage,
  page,
  setPage,
}) => {
  const [open, setOpen] = useState(false);
  const resetTooltipTextState = useResetRecoilState(TooltipTextState);
  const text = `${
    total < rowLength
      ? // ex) 1~5 of 100
        totalPage === 0
        ? `0`
        : `1~${total}`
      : page * rowLength > total
      ? `${
          total - (total - (page - 1) * rowLength) + 1 === total
            ? // ex) 16 of 16
              total
            : // ex) 16~18 of 18
              `${total - (total - (page - 1) * rowLength) + 1}~${total}`
        }`
      : // ex) 11~15 of 100
        `${(page - 1) * rowLength + 1}~${page * rowLength}`
  } of ${total}`;
  useEffect(() => {
    setOpen(false);
  }, [rowLength]);
  return (
    <>
      {open ? (
        <AutoCompleteComponent
          id={`${tableId}_pageNumber`}
          value={{ name: page, id: page }}
          onChange={(value) => setPage(value.id)}
          width="60px"
          onDoubleClick={() => setOpen(false)}
          options={Array(totalPage)
            .fill(0)
            .map((_, index) => ({
              id: index + 1,
              name: String(index + 1),
            }))}
        />
      ) : (
        <ToolTipComponent text={text}>
          <PaginantionSpan
            style={{ width: "50px", cursor: "pointer" }}
            onDoubleClick={() => {
              setOpen(true);
              resetTooltipTextState();
            }}
          >{`${totalPage === 0 ? 0 : page} / ${totalPage}`}</PaginantionSpan>
        </ToolTipComponent>
      )}
    </>
  );
};
