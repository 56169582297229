import { privateRouteMessages } from "$components/PrivateRoute/PrivateRouteWrapper/messages";
import { ToolTipComponent } from "$components/ToolTipComponent";
import { pageNameAtom } from "$utils/atom";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";
import { styled } from "styled-components";

export const PageNameComponent = () => {
  const pageName = useRecoilValue(pageNameAtom);
  return (
    <>
      {pageName && (
        <PageNameText>
          <ToolTipComponent
            text={<FormattedMessage {...privateRouteMessages.NowLocation} />}
          >
            <div
              style={{
                display: "flex",
                gap: "6px",
              }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {pageName}
            </div>
          </ToolTipComponent>
        </PageNameText>
      )}
    </>
  );
};

const PageNameText = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  height: fit-content;
`;
