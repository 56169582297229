/*
 * FeaturePage Messages
 *
 * This contains all the text for the FeaturePage component.
 */
import { defineMessages } from "react-intl";

export const scope = "demasterpro.containers.LoginPage";
export default defineMessages({
  invalidNewPassword: {
    id: `demasterpro.containers.ResetPasswordPage.invalidNewPassword`,
    defaultMessage: `새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.`,
  },
  requireMsgForChangePassword: {
    id: `demasterpro.containers.ResetPasswordPage.requireMsgForChangePassword`,
    defaultMessage: `모든 입력란은 빈 칸일 수 없습니다.`,
  },
  titleResetPassword: {
    id: `demasterpro.containers.ResetPasswordPage.titleResetPassword`,
    defaultMessage: `비밀번호 초기화`,
  },
  titleChangePassword: {
    id: `demasterpro.containers.ResetPasswordPage.titleChangePassword`,
    defaultMessage: `비밀번호 변경`,
  },
  currentPassword: {
    id: `demasterpro.containers.ResetPasswordPage.currentPassword`,
    defaultMessage: `현재 비밀번호`,
  },
  loginId: {
    id: `demasterpro.containers.ResetPasswordPage.loginId`,
    defaultMessage: `아이디`,
  },
  newPassword: {
    id: `demasterpro.containers.ResetPasswordPage.newPassword`,
    defaultMessage: `새 비밀번호`,
  },
  confirmNewPassword: {
    id: `demasterpro.containers.ResetPasswordPage.confirmNewPassword`,
    defaultMessage: `새 비밀번호 확인`,
  },
  notifyPage: {
    id: `demasterpro.containers.ResetPasswordPage.notifyPage`,
    defaultMessage: `비밀번호 변경을 위해 새로운 비밀번호를 입력해주세요.`,
  },
  changeLanguage: {
    id: `${scope}.changeLanguage`,
    defaultMessage: "언어를 변경하고 싶으신가요?",
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: "검색하기",
  },
  rememberMe: {
    id: `${scope}.rememberMe`,
    defaultMessage: "Remember Me",
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: "Rows Per Page",
  },
  btnSend: {
    id: `${scope}.btnSend`,
    defaultMessage: "Send",
  },
  emailAddress: {
    id: `${scope}.emailAddress`,
    defaultMessage: "Email address: ",
  },
  mesInvalidLogin: {
    id: `${scope}.mesInvalidLogin`,
    defaultMessage: "Username and password is required",
  },
  titleFormLogin: {
    id: `${scope}.titleFormLogin`,
    defaultMessage: "{title} - Sign in",
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: "Email",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: "Sign in",
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: "Forgot Password",
  },
  requestVisit: {
    id: `${scope}.requestVisit`,
    defaultMessage: "Register in Advance",
  },
  messageValidEmail: {
    id: `${scope}.messageValidEmail`,
    defaultMessage: "Email is required",
  },
  selectToLogin: {
    id: `${scope}.selectToLogin`,
    defaultMessage: "Select To Login",
  },
  titleFormAuthenticationKey: {
    id: `${scope}.titleFormAuthenticationKey`,
    defaultMessage: "DeMasterPro -  License Key Verification",
  },
  desFormAuthenticationKey: {
    id: `${scope}.desFormAuthenticationKey`,
    defaultMessage:
      "Please enter the License Key that provided together with your software package in order to activate the system!",
  },
  licenseKey: {
    id: `${scope}.licenseKey`,
    defaultMessage: "License Key(XXXXX-XXXXX-XXXXX-XXXXX-XXXXX-XXXXX)",
  },
  verifyKey: {
    id: `${scope}.verifyKey`,
    defaultMessage: "Verify Key",
  },
  returnPageLogin: {
    id: `${scope}.returnPageLogin`,
    defaultMessage: "Return Page Login",
  },
  resetPasswd: {
    id: `demasterpro.containers.ResetPasswordPage.titleChangePassword`,
    defaultMessage: "비밀번호 변경",
  },
  labelContinueNewSession: {
    id: `${scope}.labelContinueNewSession`,
    defaultMessage:
      "Account is being used on another device. Do you want continue?",
  },
  btnContinue: {
    id: `${scope}.btnContinue`,
    defaultMessage: "Continue",
  },
  btnCancel: {
    id: `${scope}.btnCancel`,
    defaultMessage: "Cancel",
  },
});
