import React, { useEffect } from "react";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import { loadingState } from "$utils/atom";
import { motion } from "framer-motion";

const LoadingComponent = () => {
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  useEffect(() => {
    if (isLoading < 0) {
      setIsLoading(0);
    }
  }, [isLoading]);
  return (
    <Box>
      {isLoading && isLoading > 0 ? (
        <LinearProgressBox>
          <LinearProgressBar
            animate={{
              left: 2600,
            }}
            transition={{
              repeat: Infinity,
              duration: 1.5,
            }}
          />
        </LinearProgressBox>
      ) : (
        <></>
      )}
    </Box>
  );
};

const Box = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;
const LinearProgressBox = styled.div`
  width: 100%;
  height: 5px;
  background-color: inherit;
  position: relative;
  overflow: hidden;
`;
const LinearProgressBar = styled(motion.div)`
  width: 900px;
  height: 100%;
  left: -900px;
  top: 0;
  position: absolute;
  background-color: ${(props) => props.theme.darkBlueColor};
`;

export default LoadingComponent;
