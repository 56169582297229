import { defineMessages } from "react-intl";

export const scope = "demasterpro.containers.DeviceMonitoringPage";
export const deviceMonitoringMessages = defineMessages({
  date: {
    id: `${scope}.date`,
    defaultMessage: "날짜",
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: "시간",
  },
  filteringBuildings: {
    id: `${scope}.filteringBuildings`,
    defaultMessage: "건물 검색",
  },
  searchBox: {
    id: `${scope}.searchBox`,
    defaultMessage: "Search...",
  },
  headerCompany: {
    id: `${scope}.headerCompany`,
    defaultMessage: "Company",
  },
  headerAction: {
    id: `${scope}.headerAction`,
    defaultMessage: "Action",
  },
  headerRegisIdNumber: {
    id: `${scope}.headerRegisIdNumber`,
    defaultMessage: "Registered ID Number",
  },
  headerDBIdNumber: {
    id: `${scope}.fromDbIdNumber`,
    defaultMessage: "Database ID Number",
  },
  headerNotTransmitEvt: {
    id: `${scope}.headerNotTransmitEvt`,
    defaultMessage: "Not transmitting Event",
  },
  headerCommuniTine: {
    id: `${scope}.headerCommuniTine`,
    defaultMessage: "Last Communication Time",
  },
  headerDeviceType: {
    id: `${scope}.headerDeviceType`,
    defaultMessage: "Device Type",
  },
  headerFirmwareVersion: {
    id: `${scope}.headerFirmwareVersion`,
    defaultMessage: "Firmware Version",
  },
  headerConnectionStatus: {
    id: `${scope}.headerConnectionStatus`,
    defaultMessage: "Connection Status",
  },
  headerDoorStatus: {
    id: `${scope}.headerDoorStatus`,
    defaultMessage: "Door Status",
  },
  headerDoorName: {
    id: `${scope}.headerDoorName`,
    defaultMessage: "Door Name",
  },
  headerDeviceAddress: {
    id: `${scope}.headerDeviceAddress`,
    defaultMessage: "Device Address",
  },
  titleTable: {
    id: `${scope}.titleTable`,
    defaultMessage: "Device Monitoring",
  },
  openUntilTime: {
    id: `${scope}.openUntilTime`,
    defaultMessage: "Door Open Until Time",
  },
  openPeriod: {
    id: `${scope}.openPeriod`,
    defaultMessage: "Door Open Time Second(1~254)",
  },
  btnResetDevice: {
    id: `${scope}.btnResetDevice`,
    defaultMessage: `Reset Device`,
  },
  btnSendCurrentTime: {
    id: `${scope}.btnSendCurrentTime`,
    defaultMessage: `Send Current Time`,
  },
  btnOpenDoor: {
    id: `demasterpro.components.Datatables.btnOpenDoor`,
    defaultMessage: `Open door`,
  },
  btnOk: {
    id: `${scope}.btnOk`,
    defaultMessage: `Ok`,
  },
  btnCancel: {
    id: `${scope}.btnCancel`,
    defaultMessage: `Cancel`,
  },
  validateUntilTimeError: {
    id: `${scope}.validateUntilTimeError`,
    defaultMessage: `The time must be after current time`,
  },
  openDoorConfirm: {
    id: `${scope}.openDoorConfirm`,
    defaultMessage: `You sure you want to such?`,
  },
  resetAllDevices: {
    id: `${scope}.resetAllDevices`,
    defaultMessage: `Do you want to reset selected device(s)?`,
  },
  upTimeOnlineDevice: {
    id: `${scope}.upTimeOnlineDevice`,
    defaultMessage: "Up Time",
  },
  filterCompany: {
    id: `${scope}.filterCompany`,
    defaultMessage: "Company Name",
  },
  filterConnectionStatus: {
    id: `${scope}.filterConnectionStatus`,
    defaultMessage: "Connection Status",
  },
  filterDeviceType: {
    id: `${scope}.filterDeviceType`,
    defaultMessage: "Device Type",
  },
  operationType: {
    id: `${scope}.operationType`,
    defaultMessage: `Operation Type`,
  },
  btnClear: {
    id: `${scope}.btnClear`,
    defaultMessage: "Clear",
  },
  operationTypeEntrance: {
    id: `demasterpro.containers.DevicePage.operationTypeEntrance`,
    defaultMessage: "Entrance",
  },
  operationTypeRestaurant: {
    id: `demasterpro.containers.DevicePage.operationTypeRestaurant`,
    defaultMessage: "Restaurant",
  },
  errorMultiSelect: {
    id: `${scope}.errorMultiSelect`,
    defaultMessage: "You should select option at least 1",
  },
  releaseDoor: {
    id: `${scope}.releaseDoor`,
    defaultMessage: "Release Door",
  },
  titleDeviceInformation: {
    id: `${scope}.titleDeviceInformation`,
    defaultMessage: "Device Information",
  },
  eventTypeDescriptionInfo: {
    id: `${scope}.eventTypeDescriptionInfo`,
    defaultMessage: "Access Status:",
  },
  positionInfo: {
    id: `${scope}.positionInfo`,
    defaultMessage: "Position:",
  },
  nameInfo: {
    id: `${scope}.nameInfo`,
    defaultMessage: "Name:",
  },
  gradeInfo: {
    id: `${scope}.gradeInfo`,
    defaultMessage: "Grade:",
  },
  deviceNameInfo: {
    id: `${scope}.deviceNameInfo`,
    defaultMessage: "Device Name:",
  },
  deviceAddressInfo: {
    id: `${scope}.deviceAddressInfo`,
    defaultMessage: "Device Address:",
  },
  departmentInfo: {
    id: `${scope}.departmentInfo`,
    defaultMessage: "Department:",
  },
  accessTimeInfo: {
    id: `${scope}.accessTimeInfo`,
    defaultMessage: "Access Time:",
  },
  deviceSubMonitoring: {
    id: `${scope}.deviceSubMonitoring`,
    defaultMessage: "Device",
  },
  btnGenerateLink: {
    id: `${scope}.btnGenerateLink`,
    defaultMessage: "Generate Link",
  },
  timeReset: {
    id: `${scope}.timeReset`,
    defaultMessage: "Time Reset",
  },
  timeStartCheckIn: {
    id: `${scope}.timeStartCheckIn`,
    defaultMessage: "Time Start CheckIn",
  },
  timeEndCheckIn: {
    id: `${scope}.timeEndCheckIn`,
    defaultMessage: "Time End CheckIn",
  },
  timeStartCheckOut: {
    id: `${scope}.timeStartCheckOut`,
    defaultMessage: "Time Start CheckOut",
  },
  timeEndCheckOut: {
    id: `${scope}.timeEndCheckOut`,
    defaultMessage: "Time End CheckOut",
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: "Copy",
  },
  linkSubMonitoring: {
    id: `${scope}.linkSubMonitoring`,
    defaultMessage: "Link Page Sub-Monitoring",
  },
  linkScreenMonitoring: {
    id: `${scope}.linkScreenMonitoring`,
    defaultMessage: "Link Page Monitoring",
  },
  headerVehicleIn: {
    id: `${scope}.headerVehicleIn`,
    defaultMessage: "Vehicle (entrance)",
  },
  headerVehicleOut: {
    id: `${scope}.headerVehicleOut`,
    defaultMessage: "Vehicle (exit)",
  },
  headerPersonIn: {
    id: `${scope}.headerPersonIn`,
    defaultMessage: "Person (entrance)",
  },
  headerPersonOut: {
    id: `${scope}.headerPersonOut`,
    defaultMessage: "Person (exit)",
  },
  labelCountOfDevicesShowing: {
    id: `${scope}.labelCountOfDevicesShowing`,
    defaultMessage: "Count devices show in page Sub Monitoring",
  },
  headerPreviousEvent: {
    id: `${scope}.headerPreviousEvent`,
    defaultMessage: "Previous Event",
  },
  headerRecentEvent: {
    id: `${scope}.headerRecentEvent`,
    defaultMessage: "Recent Event",
  },
  headerEvent: {
    id: `${scope}.headerEvent`,
    defaultMessage: "Event",
  },
  enableUsingDesignShowDevice: {
    id: `${scope}.enableUsingDesignShowDevice`,
    defaultMessage: "Fixed device per box",
  },
  tooltipEnableUsingDesignShowDevice: {
    id: `${scope}.tooltipEnableUsingDesignShowDevice`,
    defaultMessage:
      "Enable this setting to display event from selected device in designated box otherwise latest event from all devices will be showed respectively",
  },
  titleModalGenerateLinkSubMonitoring: {
    id: `${scope}.titleModalGenerateLinkSubMonitoring`,
    defaultMessage: "Generate Link for Sub-Display Monitoring",
  },
  descriptionModalGenerateLinkSubMonitoring: {
    id: `${scope}.descriptionModalGenerateLinkSubMonitoring`,
    defaultMessage:
      "Select the devices and layout that will be displayed in the Sub-Display screen",
  },
  tooltipOptionBoxes: {
    id: `${scope}.tooltipOptionBoxes`,
    defaultMessage:
      "Select number of boxes(devices) display Sub-Display screen",
  },
  visitorTypeInfo: {
    id: `${scope}.visitorTypeInfo`,
    defaultMessage: "Visitor Type",
  },
  visitorNameInfo: {
    id: `${scope}.visitorNameInfo`,
    defaultMessage: "Visitor Name",
  },
  visitorReasonInfo: {
    id: `${scope}.visitorReasonInfo`,
    defaultMessage: "Visitor Reason",
  },
  visitorRankInfo: {
    id: `${scope}.visitorRankInfo`,
    defaultMessage: "Visitor Rank",
  },
  eventUser: {
    id: `${scope}.eventUser`,
    defaultMessage: "Event User",
  },
  eventVisitor: {
    id: `${scope}.eventVisitor`,
    defaultMessage: "Event Visitor",
  },
  eventAbnormal: {
    id: `${scope}.eventAbnormal`,
    defaultMessage: "Event Abnormal",
  },
  eventCanteen: {
    id: `${scope}.eventCanteen`,
    defaultMessage: "Event Canteen",
  },
  titleScreenMonitoring: {
    id: `${scope}.titleScreenMonitoring`,
    defaultMessage: "Screen Monitoring Duall Master",
  },
  absent: {
    id: `${scope}.absent`,
    defaultMessage: `Absent`,
  },
  available: {
    id: `${scope}.available`,
    defaultMessage: `Available`,
  },

  headerAccessTime: {
    id: `demasterpro.containers.EventLogPage.accessTime`,
    defaultMessage: `Access Time`,
  },
  headerName: {
    id: `demasterpro.containers.MonitorPage.headerName`,
    defaultMessage: "Name",
  },
  headerDepartment: {
    id: `demasterpro.containers.MonitorPage.headerDepartment`,
    defaultMessage: "Department",
  },
  headerBodyTemperature: {
    id: `demasterpro.containers.ReportPage.bodyTemperature`,
    defaultMessage: "Body Temperature",
  },
  headerCardID: {
    id: `demasterpro.containers.UserPage.headerCardId`,
    defaultMessage: "Card ID",
  },
  headerEventDetail: {
    id: `demasterpro.containers.MonitorPage.headerEventDetail`,
    defaultMessage: "Event Detail",
  },
  headerInOut: {
    id: `demasterpro.containers.MonitorPage.headerInOut`,
    defaultMessage: `In/Out`,
  },
  templateSubMonitoring: {
    id: `${scope}.templateSubMonitoring`,
    defaultMessage: `Template Screen Sub Monitoring`,
  },
  filterEvent: {
    id: `demasterpro.containers.MonitorPage.filterEvent`,
    defaultMessage: `Template Screen Sub Monitoring`,
  },
  enableDisplayListVisitor: {
    id: `${scope}.enableDisplayListVisitor`,
    defaultMessage: `Enable Display Listing of Visitor`,
  },
  enableDisplayAbnormalEvent: {
    id: `${scope}.enableDisplayAbnormalEvent`,
    defaultMessage: `Enable Display Count of Abnormal Event`,
  },
  enableDisplayCanteenEvent: {
    id: `${scope}.enableDisplayCanteenEvent`,
    defaultMessage: `Enable Display Count of Canteen Event`,
  },
  visitorName: {
    id: `demasterpro.containers.VisitPage.visitorName`,
    defaultMessage: `Visitor Name`,
  },
  headerStatus: {
    id: `demasterpro.containers.VisitPage.headerStatus`,
    defaultMessage: `Step Status`,
  },
  headerVisiteeSite: {
    id: `demasterpro.containers.VisitPage.headerVisiteeSite`,
    defaultMessage: `Visitee Site`,
  },
  headerVisiteeName: {
    id: `demasterpro.containers.VisitPage.headerVisiteeName`,
    defaultMessage: `Visitee Name`,
  },
  headerPhone: {
    id: `demasterpro.containers.VisitPage.headerPhone`,
    defaultMessage: `Phone`,
  },
  forcedOpen: {
    id: `demasterpro.containers.EmergencyPage.forcedOpen`,
    defaultMessage: `Forced Open`,
  },
  release: {
    id: `demasterpro.containers.EmergencyPage.release`,
    defaultMessage: `Release`,
  },
  forcedClose: {
    id: `demasterpro.containers.EmergencyPage.forcedClose`,
    defaultMessage: `Forced Close`,
  },
  headerBuilding: {
    id: `demasterpro.containers.UserPage.headerBuilding`,
    defaultMessage: `Building`,
  },
  plateNumber: {
    id: `demasterpro.containers.UserPage.plateNumber`,
    defaultMessage: `Plate Number`,
  },
  totalStudent: {
    id: `${scope}.totalStudent`,
    defaultMessage: "Total Student",
  },
  present: {
    id: `${scope}.present`,
    defaultMessage: "Present",
  },
  leave: {
    id: `${scope}.leave`,
    defaultMessage: "Leave",
  },
  parentDepartment: {
    id: `${scope}.parentDepartment`,
    defaultMessage: "Parent Department",
  },
  isCheckTeacherOut: {
    id: `${scope}.isCheckTeacherOut`,
    defaultMessage: "Check Teacher Out",
  },
  titleScreenMonitoringV5: {
    id: `${scope}.titleScreenMonitoringV5`,
    defaultMessage: "HÂN HẠNH CHÀO MỪNG",
  },
  visitorNameV5: {
    id: `${scope}.visitorNameV5`,
    defaultMessage: "Mr/Ms",
  },
  positionV5: {
    id: `${scope}.positionV5`,
    defaultMessage: "Chức vụ",
  },
  companyV5: {
    id: `${scope}.companyV5`,
    defaultMessage: "Công ty",
  },
  settingDeviceMonitoring: {
    id: `${scope}.settingDeviceMonitoring`,
    defaultMessage: "Setting",
  },
  PreviewDeviceMonitoring: {
    id: `${scope}.PreviewDeviceMonitoring`,
    defaultMessage: "Preview",
  },
  reconnect: {
    id: `${scope}.reconnect`,
    defaultMessage: "Reconnect",
  },
  backgroundImage: {
    id: `${scope}.backgroundImage`,
    defaultMessage: "Background Image",
  },
  colorLabel: {
    id: `${scope}.colorLabel`,
    defaultMessage: "Color",
  },
});
