import { styled } from "styled-components";

export const ModalComponentBox = styled.div`
  padding: 30px;
  background-color: ${(props) => props.theme.lightGrayColor};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const CommonTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.darkGrayColor};
`;
export const NoContentBox = styled.div`
  width: 100%;
  padding: 120px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;
