import React from "react";
import {
  TempTooltipTextState,
  TooltipTextState,
  tooltipMouseLocaleState,
} from "$utils/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ToolTipBody } from "./Body";

export const ToolTip = () => {
  const mouseLocale = useRecoilValue(tooltipMouseLocaleState);
  const tooltipText = useRecoilValue(TooltipTextState);
  const [tempData, setTempData] = useRecoilState(TempTooltipTextState);
  const handleEnter = () => {
    setTempData({
      mouseLocale,
      tooltipText,
    });
  };
  const handleEndHover = () => {
    setTempData(null);
  };
  return (
    <>
      {((mouseLocale &&
        tooltipText &&
        tooltipText.text &&
        tooltipText.text !== "") ||
        (tempData && tempData?.tooltipText?.text)) && (
        <ToolTipBody
          tooltipText={tooltipText}
          mouseLocale={mouseLocale}
          tempData={tempData}
          handleEnter={handleEnter}
          handleEndHover={handleEndHover}
        />
      )}
    </>
  );
};
