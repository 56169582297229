import { defineMessages } from "react-intl";

export default defineMessages({
  cancel: {
    id: `demasterpro.containers.App.cancel`,
    defaultMessage: "취소",
  },
  confirm: {
    id: `demasterpro.containers.App.confirm`,
    defaultMessage: "확인",
  },
});
