import { styled } from "styled-components";

export const ContextMenuBox = styled.div`
  position: fixed;
  z-index: 10;
  left: ${(props) => `${props.mouselocale.x}px`};
  top: ${(props) => `${props.mouselocale.y}px`};
  padding: 10px;
  border-radius: 2.5px;
  background-color: ${(props) => props.theme.lightGrayColor};
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;
