import boxResizeEvent from "$components/Common/TreeWithInfomationComponent/utils";
import {
  HeaderRowDrager,
  StyledHeaderRow,
} from "$components/TableComponent/style";
import { localstoreUtilites } from "$utils/persistenceData";
import React, { memo, useEffect, useState } from "react";

export const HeaderRow = memo(
  ({ tableId, row, tableHeader, setTableHeader }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [headerRowData, setHeaderRowData] = useState(row.width);
    const [isResizeEnd, setIsResizeEnd] = useState(false);
    useEffect(() => {
      if (isResizeEnd) {
        const parsedHeaders = tableHeader.map((item) =>
          item.id === row.id ? { ...item, width: headerRowData } : item
        );
        setTableHeader(parsedHeaders);
        localstoreUtilites.saveTableHeaderSizesToLocalStorage(
          tableId,
          parsedHeaders
        );
        setIsResizeEnd(false);
      }
    }, [isResizeEnd]);
    return (
      <StyledHeaderRow
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        width={headerRowData}
        style={{ ...row.style }}
      >
        <span style={{ width: "100%" }}>{row.label}</span>
        {isHovered && tableId && (
          <HeaderRowDrager
            {...boxResizeEvent(
              (deltaX) => {
                if (
                  typeof row.width === "number" &&
                  row.width + deltaX >= 50 &&
                  row.width + deltaX <= 500
                ) {
                  setHeaderRowData(row.width + deltaX);
                }
              },
              () => {
                setIsResizeEnd(true);
              }
            )}
          />
        )}
      </StyledHeaderRow>
    );
  }
);
