import { FormattedMessage } from "react-intl";
import { monitroingMessages } from "./messages";
import { AvatarComponent } from "$components/AvatarComponent";
import { CARD_TYPE } from "$utils/constants";
import { USERTYPE, localstoreUtilites } from "$utils/persistenceData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCreditCard,
  faFingerprint,
  faGrin,
  faKeyboard,
  faMobileAlt,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { ToolTipComponent } from "$components/ToolTipComponent";
import { styled } from "styled-components";
import {
  changeUnixToTime,
  convertDateUtcToUserTime,
  convertShowDate,
  reformatDate,
} from "$utils/libs/timeLibs";

const permissions = localstoreUtilites.getPermissionsFromLocalStorage();
const userID = localstoreUtilites.getUserIdFromLocalStorage();

export const CARD_STATUS_OBJ = {
  en: {
    0: "Normal",
    1: "Temp",
    2: "Retire",
    3: "Lost",
    4: "Invalid",
  },
  ko: {
    0: "일반",
    1: "임시 카드",
    2: "퇴직",
    3: "분실 카드",
    4: "사용안함",
  },
  ja: {
    0: "普通",
    1: "温度",
    2: "引退",
    3: "なくした",
    4: "無効",
  },
  vi: {
    0: "Normal",
    1: "Temp",
    2: "Retire",
    3: "Lost",
    4: "Invalid",
  },
};

export const MONITORING_MODAL_STATE = {
  ASSIGN_DOOR: "ASSIGN_DOOR",
  ADD_CARD_IN_USER: "ADD_CARD_IN_USER",
  VISIT_ACCESS_HISTORY: "VISIT_ACCESS_HISTORY",
  ACCESS_HISTORY: "ACCESS_HISTORY",
  MEMO: "MEMO",
  AVATAR: "AVATAR",
};
export const MONITORING_FETCH_STATE = {
  PATCH_HEADERS: "PATCH_HEADERS",
};
export const greenCode = [
  1, 11, 17, 18, 19, 20, 21, 22, 23, 27, 28, 35, 37, 40, 41, 43, 44,
];
export const redCode = [
  2, 3, 4, 5, 6, 9, 10, 12, 13, 14, 15, 16, 24, 25, 26, 36, 38, 39, 42, 45, 47,
  51, 61, 62, 49,
];
export const yellowCode = [7, 8, 29, 30, 31, 32, 33, 34];
export const alarmCode = [
  2, 3, 4, 5, 6, 9, 10, 12, 13, 14, 15, 16, 24, 25, 26, 31, 32, 33, 34, 36, 38,
  39, 42, 45, 47, 51, 61, 62, 70, 49,
];
export const userCode = [1, 41, 42, 43, 45, 50, 51, 52, 53, 54, 55, 61, 62];

export const cardIcon = {
  [CARD_TYPE.CARD]: <FontAwesomeIcon icon={faCreditCard} />,
  [CARD_TYPE.QR]: <FontAwesomeIcon icon={faQrcode} />,
  [CARD_TYPE.PASSCODE]: <FontAwesomeIcon icon={faKeyboard} />,
  [CARD_TYPE.NFCPHONE]: <FontAwesomeIcon icon={faMobileAlt} />,
  [CARD_TYPE.FACEID]: <FontAwesomeIcon icon={faGrin} />,
  [CARD_TYPE.HFACEID]: <FontAwesomeIcon icon={faGrin} />,
  [CARD_TYPE.VEHICLE]: <FontAwesomeIcon icon={faCar} />,
  [CARD_TYPE.VEIN]: <FontAwesomeIcon icon={faGrin} />, // Need to change icon
  [CARD_TYPE.FINGERPRINT]: <FontAwesomeIcon icon={faFingerprint} />,
  [CARD_TYPE.LFACEID]: <FontAwesomeIcon icon={faGrin} />,
  [CARD_TYPE.BIOFACEID]: <FontAwesomeIcon icon={faGrin} />,
  [CARD_TYPE.EBKNFACEID]: <FontAwesomeIcon icon={faGrin} />,
  [CARD_TYPE.EBKNFINGERPRINT]: <FontAwesomeIcon icon={faFingerprint} />,
  [CARD_TYPE.ARAREKFINGERPRINT]: <FontAwesomeIcon icon={faFingerprint} />,
};

export const parseMonitorData = (data, navigate, isApi = false) => {
  if (!data || data?.length <= 0) {
    return undefined;
  }
  return data.map((item) => {
    const cloneMonitorItem = Object.assign({}, item);
    cloneMonitorItem.name = item.userName;
    if (item.userName !== null && (item.avatar || item.resultCheckIn)) {
      cloneMonitorItem.userName = (
        <div
          style={{ display: "flex", alignItems: "center" }}
          key={`${item.userName}_grid`}
        >
          <AvatarComponent src={item.avatar} />
          <span key={`${item.userName}_span`} style={{ marginLeft: 10 }}>
            {item.userName}
          </span>
        </div>
      );
    }
    if (
      item.userId === null &&
      item.cardId &&
      (item.userType === null || item.userType === USERTYPE.NORMAL)
    ) {
      if (cardIcon[item.cardTypeId]) {
        if (permissions.user && permissions.user.addUser) {
          cloneMonitorItem.cardId = (
            <FlexBox>
              <ToolTipComponent text={item.cardType}>
                {cardIcon[item.cardTypeId]}
              </ToolTipComponent>
              <EditDevice
                onClick={() =>
                  navigate(
                    `/user/add?cardId=${item.cardId}&issueCount=${item.issueCount}`
                  )
                }
              >
                {item.cardId}
              </EditDevice>
            </FlexBox>
          );
        } else {
          cloneMonitorItem.cardId = (
            <FlexBox>
              <ToolTipComponent text={item.cardType}>
                {cardIcon[item.cardTypeId]}
              </ToolTipComponent>
              {item.cardId}
            </FlexBox>
          );
        }
      } else if (permissions.user && permissions.user.addUser) {
        cloneMonitorItem.cardId = (
          <FlexBox>
            <EditDevice
              onClick={() =>
                navigate(
                  `/user/add?cardId=${item.cardId}&issueCount=${item.issueCount}`
                )
              }
            >
              {item.cardId}
            </EditDevice>
          </FlexBox>
        );
      }
    } else if (
      item.userId !== null &&
      item.cardId !== null &&
      item.userType !== null &&
      item.userType === USERTYPE.NORMAL
    ) {
      if (permissions.user && permissions.user.editUser) {
        cloneMonitorItem.cardId = (
          <FlexBox>
            <ToolTipComponent text={item.cardType}>
              {cardIcon[item.cardTypeId]}
            </ToolTipComponent>
            <EditDevice onClick={() => navigate(`/user/edit/${item.userId}`)}>
              {item.cardId}
            </EditDevice>
          </FlexBox>
        );
      } else {
        cloneMonitorItem.cardId = (
          <FlexBox>
            <ToolTipComponent text={item.cardType}>
              {cardIcon[item.cardTypeId]}
            </ToolTipComponent>
            {item.cardId}
          </FlexBox>
        );
      }
    } else if (
      item.cardId !== null &&
      item.userType !== null &&
      item.userType === USERTYPE.VISITOR
    ) {
      cloneMonitorItem.cardId = (
        <FlexBox>
          <ToolTipComponent text={item.cardType}>
            {cardIcon[item.cardTypeId]}
          </ToolTipComponent>
          <EditDevice onClick={() => navigate(`/`)}>{item.cardId}</EditDevice>
        </FlexBox>
      );
    }
    cloneMonitorItem.strCardId = item.cardId;
    cloneMonitorItem.device = permissions?.deviceSetting?.editDeviceSetting ? (
      <EditDevice
        onClick={() => navigate(`/device-setting/edit/${item.icuId}`)}
      >
        {item.device}
      </EditDevice>
    ) : (
      item.device
    );
    cloneMonitorItem.eventTime = changeUnixToTime(item.unixTime);
    if (
      item.deviceManagerIds.includes(parseInt(userID, 10)) &&
      alarmCode.includes(item.eventType)
    ) {
      cloneMonitorItem.enableAlarm = true;
    }
    if (item?.expireDate) {
      if (isApi) {
        cloneMonitorItem.expireDate = reformatDate(
          convertDateUtcToUserTime(new Date(convertShowDate(item.expireDate)))
        );
      } else {
        cloneMonitorItem.expireDate = item.expireDate;
      }
    }
    return cloneMonitorItem;
  });
};
const FlexBox = styled.div`
  display: flex;
  gap: 5px;
`;
const EditDevice = styled.div`
  color: ${(props) => props.theme.grayColor};
  text-decoration: underline;
  cursor: pointer;
`;

export const accessHistoryHeaders = [
  {
    id: "accessTime",
    label: <FormattedMessage {...monitroingMessages.accessTime} />,
  },
  {
    id: "avatar",
    label: <FormattedMessage {...monitroingMessages.avatar} />,
  },
  {
    id: "cardId",
    label: <FormattedMessage {...monitroingMessages.cardId} />,
  },
  {
    id: "eventDetail",
    label: <FormattedMessage {...monitroingMessages.eventDetail} />,
  },
  {
    id: "inOut",
    label: <FormattedMessage {...monitroingMessages.inOut} />,
  },
  {
    id: "doorName",
    label: <FormattedMessage {...monitroingMessages.doorName} />,
  },
];
