import React from "react";
import { ThemeProvider, createGlobalStyle, styled } from "styled-components";
import { GLOBAL_COLOR, PAGE_TITLE } from "$utils/constants";
import { Helmet } from "react-helmet";
import { localstoreUtilites } from "$utils/persistenceData";
import PrivateRouter from "routes/PrivateRouter";
import PublicRouter from "routes/PublicRouter";
import { QueryClient, QueryClientProvider } from "react-query";
import SnackbarComponent from "$components/SnackbarComponent";
import { useSetRecoilState } from "recoil";
import { menuOverlayState, mouseLocaleState } from "$utils/atom";
import LoadingComponent from "$components/LoadingComponent";
import { ToolTip } from "$components/ToolTipComponent/ToolTip";
import "./App.css";
import { SnackbarProvider } from "notistack";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // window focus 설정
      retry: 1,
    },
  },
});

function App() {
  const auth = localstoreUtilites.getAuthFromLocalStorage();
  const permission = localstoreUtilites.getPermissionsFromLocalStorage();
  const setIsOverlay = useSetRecoilState(menuOverlayState);
  const setMouseLocale = useSetRecoilState(mouseLocaleState);
  return (
    <ThemeProvider theme={GLOBAL_COLOR}>
      <ToolTip />
      <AppContainer
        onClick={() => {
          setIsOverlay(false);
          setMouseLocale(null);
        }}
      >
        <LoadingComponent />
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <SnackbarComponent />
        </SnackbarProvider>
        <GlobalStyles />
        <Helmet titleTemplate={`%s - ${PAGE_TITLE}`} defaultTitle="DeMasterPro">
          <meta name="description" content="DeMasterPro" />
        </Helmet>
        <QueryClientProvider client={queryClient}>
          {auth.isAuthed && permission ? (
            <PrivateRouter
              isAuthed={auth.isAuthed}
              accountType={auth.accountType}
              permission={permission}
            />
          ) : (
            <PublicRouter
              isAuthed={auth.isAuthed}
              auth={auth}
              permission={permission}
            />
          )}
        </QueryClientProvider>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;

const AppContainer = styled.div`
  margin: 0 auto;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  right: 0;
  left: 0;
  font-family: "NotoSans";
  color: ${(props) => props.theme.darkGrayColor};
`;

const GlobalStyles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
  font-family: "NotoSans";
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
div {
  background-repeat : no-repeat;
}
#app {
  background-color: #D6DEE8;
  min-height: 100%;
  min-width: 100%;
}
p,
label {
  line-height: 1.5em;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px inherit inset;
    transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #555;
  visibility: visible;
}
.scrollbar {
  display: block;
}
.noScrollbar{
  display: block;
}
.noScrollbar::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.noScrollbar::-webkit-scrollbar-thumb {
}
.subScrollbar {
}
.subScrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: transparent;
}
.subScrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.subScrollbar::-webkit-scrollbar-thumb {
  background: #F4911D;
  visibility: visible;
  border-radius : 10px;
}
/* for Calendar */
.react-calendar {
  width: 350px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 10px;
  color : #3A4856
}
.react-calendar__tile--active {
  background : #e0f2f1;
  font-family : "NotoSans";
  color : #3A4856;
}
.react-calendar__tile--hasActive{
  background : #e0f2f1;
  color : #3A4856;
}
.react-calendar__tile--active:enabled:hover {
    background: #e6e6e6;
}
.react-calendar__tile--active:enabled:focus {
    background: #e0f2f1;
    color : #3A4856;
}
.react-calendar__tile--now {
    background: #ffcccc;
}
.react-calendar__tile--now:enabled:hover {
    background: #e6e6e6;
}
`;
