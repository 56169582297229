/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from "react";
import { IntlProvider } from "react-intl";
import { localstoreUtilites } from "$utils/persistenceData";
import enMessages from "../../translations/en.json";
import koMessages from "../../translations/ko.json";
import jaMessages from "../../translations/ja.json";
import viMessages from "../../translations/vi.json";

export const LanguageProvider = ({ children }) => {
  const locale = localstoreUtilites.getLanguageFromLocalStorage()
    ? localstoreUtilites.getLanguageFromLocalStorage().substr(0, 2)
    : "en";
  const messages = {
    en: enMessages,
    ko: koMessages,
    ja: jaMessages,
    vi: viMessages,
  };
  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};
