import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { EachCompany } from "./EachCompany";
import { IconComponent } from "$components/IconComponent";
import { SearchCompany } from "./SearchCompany";
import {
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SelectCompanies = ({
  companies,
  newLogin,
  setTemporaryToken,
  setCompanies,
}) => {
  const [companiesState, setCompaniesState] = useState(companies || []);
  useEffect(() => {
    if (companies) {
      setCompaniesState(companies);
    }
  }, [companies]);
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const companiesData = companiesState.slice(startIndex, endIndex);
  const totalPages = Math.ceil(companiesState.length / rowsPerPage);
  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const goBack = () => {
    setTemporaryToken(null);
    setCompanies(null);
  };
  return (
    <SelectCompaniesBox>
      <GetBeforeComponent>
        <IconComponent
          onClick={goBack}
          id="goBack"
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        />
      </GetBeforeComponent>
      <SearchCompany
        companiesState={companiesState}
        setCompaniesState={setCompaniesState}
        setCurrentPage={setCurrentPage}
        companies={companies}
      />
      <CompanyList>
        {companiesData.map((company) => (
          <EachCompany onClick={newLogin} company={company} key={company.id} />
        ))}
      </CompanyList>
      <PageBtnBox>
        <IconComponent
          disabled={currentPage === 1}
          onClick={handlePrevClick}
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
        />
        <IconComponent
          disabled={currentPage === totalPages}
          onClick={handleNextClick}
          icon={<FontAwesomeIcon icon={faChevronRight} />}
        />
      </PageBtnBox>
    </SelectCompaniesBox>
  );
};
const SelectCompaniesBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 30px 0;
`;
const GetBeforeComponent = styled.div`
  position: absolute;
  width: 300px;
  top: -15px;
  left: -10px;
`;
const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 0 20px;
  height: 345px;
`;
const PageBtnBox = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;
