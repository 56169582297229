import React, { memo, useEffect } from "react";
import { menuOverlayState } from "$utils/atom";
import { useRecoilState } from "recoil";
import { styled } from "styled-components";
import { useDidMountEffect } from "$utils/hooks/useDidMountEffect";

export const ClickSelect = memo(
  ({
    id,
    value,
    options,
    isMulti,
    setIsFocus,
    setPosition,
    disabled,
    fontSize,
    height,
    setFilteredOptions,
    isFocus,
    darkTheme,
    setIsDown,
    text,
    setText,
  }) => {
    const [isOverlay, setIsOverlay] = useRecoilState(menuOverlayState);
    const handleSearchCompany = () => {
      const filtered = options.filter((item) =>
        item.name.toLowerCase().includes(String(text).toLowerCase())
      );
      setFilteredOptions(filtered);
    };
    useEffect(() => {
      handleSearchCompany();
    }, [text]);
    useDidMountEffect(() => {
      if (!isOverlay) {
        setIsFocus(false);
        setPosition(null);
        if (text !== "" && !isMulti) {
          setText(value.name);
        }
      }
      if (isOverlay && isOverlay.toString() === id) {
        setIsFocus(true);
      } else {
        setIsFocus(false);
        setPosition(null);
        if (text !== "" && !isMulti) {
          setText(value.name);
        }
      }
    }, [isOverlay]);
    return (
      <Box
        disabled={disabled}
        fontSize={fontSize}
        height={height}
        // html attribute로 boolean값을 보내면 Warning이 발생함.
        darktheme={darkTheme ? darkTheme.toString() : null}
        onClick={(event) => {
          if (!disabled) {
            event.stopPropagation();
          }
        }}
      >
        <Inputbox
          value={text}
          onChange={(event) => setText(String(event.target.value))}
          placeholder={
            isMulti ? value.map((item) => item.name).join(", ") : value.name
          }
          disabled={disabled}
          isfocus={isFocus ? isFocus.toString() : null}
          onFocus={(event) => {
            if (!disabled) {
              event.stopPropagation();
              if (!isFocus) {
                setIsOverlay(id);
              }
              setPosition({
                x: event.target.getBoundingClientRect().left,
                y: event.target.getBoundingClientRect().top,
              });
              setIsDown(
                event.target.getBoundingClientRect().top < 400 ? true : false
              );
              setIsFocus(true);
            }
          }}
        />
      </Box>
    );
  }
);
const Box = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.disabled ? "lightgray" : "black")};
  display: flex;
  align-items: center;
  font-size: 12px;
`;
const Inputbox = styled.input`
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.darktheme ? "white" : "transparent")};
  outline: ${(props) =>
    props.isfocus ? `2px solid ${props.theme.orangeColor}` : "none"};
  border-radius: 3px;
  border: none;
  padding-left: 5px;
  padding-right: 20px;
`;
