import React from "react";
import { StyledTData } from "$components/TableComponent/style";
import { useRecoilValue } from "recoil";
import { ButtonRowWidthState } from "$utils/atom";

export const BtnHeader = ({ tableId }) => {
  const ButtonRowWidth = useRecoilValue(ButtonRowWidthState);
  return (
    <StyledTData
      style={{ flexShrink: 0, marginLeft: "auto" }}
      width={
        ButtonRowWidth && ButtonRowWidth[tableId]
          ? `${ButtonRowWidth[tableId] + 8}px`
          : "160px"
      }
    />
  );
};
