import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { TYPE_ACCOUNT } from "$utils/constants";
import { pageNameAtom, errorState } from "$utils/atom";
import { useSetRecoilState } from "recoil";
import { WebOverlay } from "./Weboverlay";
import { getPathPermission, getPrivateTitle } from "./utils";
import { ResetDataComponent } from "./resetDataComponent";
import { LogOut } from "$utils/libs/loginLibs";
import { privateRouteMessages } from "./messages";

const PrivateRouteWrapper = ({
  accountType,
  isAuthed,
  ignoreLogin,
  refreshToken,
  permission,
  children,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!permission || (!ignoreLogin && !isAuthed) || !refreshToken) {
      LogOut();
    }
  }, [permission, isAuthed, refreshToken, ignoreLogin]);

  const setErrorState = useSetRecoilState(errorState);
  const splitedPath = pathname.split("/");
  const path =
    splitedPath.length > 3
      ? [...splitedPath.splice(0, splitedPath.length - 1), ":id"].join("/")
      : pathname;
  const checkPermission = () => {
    const pathPermission = getPathPermission(permission, accountType);
    const isExistedPermission = pathPermission[path];
    if (!isExistedPermission) {
      navigate("/profile");
      setErrorState(
        <FormattedMessage {...privateRouteMessages.unableToAccess} />
      );
    }
  };
  useEffect(() => {
    if (
      // exept system/super admin
      accountType !== TYPE_ACCOUNT.systemAdmin &&
      accountType !== TYPE_ACCOUNT.superAdmin &&
      permission
    ) {
      checkPermission();
    }
  }, [pathname, accountType, permission]);
  const title = getPrivateTitle(path);
  const isCommonPath = ["/v1", "/v2", "/v3", "/v4", "/v5"].includes(
    pathname.slice(0, 3)
  );
  const setPageName = useSetRecoilState(pageNameAtom);
  useEffect(() => {
    if (title) {
      setPageName(<FormattedMessage {...title} />);
    }
  }, [title]);
  return (
    <>
      {title && (
        <FormattedMessage {...title}>
          {(titleStr) => (
            <Helmet>
              <title>{titleStr}</title>
              <meta name="description" content="DeMasterPro homepage" />
            </Helmet>
          )}
        </FormattedMessage>
      )}
      {isCommonPath ? (
        children
      ) : (
        <WebOverlay accountType={accountType} navigate={navigate}>
          <ResetDataComponent pathname={pathname}>
            {children}
          </ResetDataComponent>
        </WebOverlay>
      )}
    </>
  );
};
export default PrivateRouteWrapper;
