import React, { memo, useState } from "react";
import Checkbox from "./Checkbox";
import Label from "./Label";
import {
  BoxOfCheckBox,
  CheckBoxContainer,
  StyledCheckBoxBackground,
} from "./style";

const CheckBoxComponent = ({
  id,
  value,
  setValue,
  label = "",
  bgcolor = "rgba(0,0,0,0.1)",
  disabled,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <CheckBoxContainer>
      <BoxOfCheckBox
        onHoverStart={() => {
          setIsHover(true);
        }}
        onHoverEnd={() => setIsHover(false)}
      >
        <Checkbox
          disabled={disabled}
          setValue={setValue}
          id={id}
          value={value}
        />
        {!disabled && (
          <StyledCheckBoxBackground
            htmlFor={id}
            initial={{ scale: 0 }}
            animate={{
              scale: isHover ? 1 : 0,
            }}
            transition={{ duration: 0.12 }}
            bgcolor={bgcolor}
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        )}
      </BoxOfCheckBox>
      {label && <Label id={id} label={label} />}
    </CheckBoxContainer>
  );
};
export default memo(CheckBoxComponent);
