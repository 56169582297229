import React, { useEffect, useState } from "react";
import messages from "routes/Login/messages";
import { FormattedMessage } from "react-intl";
import { InputComponent } from "$components/InputComponent";
import { styled } from "styled-components";
import { ButtonComponent } from "$components/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const SearchCompany = ({
  companiesState,
  setCompaniesState,
  setCurrentPage,
  companies,
}) => {
  const [text, setText] = useState("");
  const handleSearchCompany = (event) => {
    event.preventDefault();
    const filteredCompany = companiesState.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );
    setCompaniesState(filteredCompany);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (!text) {
      setCompaniesState(companies);
    }
  }, [text]);
  return (
    <SearchCompanyBox>
      <InputComponent
        value={text}
        setValue={setText}
        id="search-text"
        placeholder={<FormattedMessage {...messages.search} />}
        width="300px"
        height="45px"
        border=""
      />
      <ButtonComponent
        id="search-btn"
        icon={<FontAwesomeIcon icon={faSearch} />}
        onClick={handleSearchCompany}
        disabled={!text}
        width="70px"
      />
    </SearchCompanyBox>
  );
};

const SearchCompanyBox = styled.form`
  display: flex;
  gap: 10px;
  justify-content: center;
`;
