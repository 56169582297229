import React, { forwardRef, memo, useRef } from "react";
import { ContextComponent } from "$components/ContextComponent.js";
import { ContextMenuComponent } from "$components/ContextComponent.js/ContextMenuComponent";
import {
  CheckBoxContainer,
  MenuIconBox,
  StyledHeaderTrow,
  StyledTHead,
  StyledTable,
} from "../../style";
import { menuOverlayState, mouseLocaleState } from "$utils/atom";
import { useSetRecoilState } from "recoil";
import { HeaderCheckBox } from "./HeaderCheckBox";
import { BtnHeader } from "./BtnHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import { localstoreUtilites } from "$utils/persistenceData";
import { HeaderRow } from "./HeaderRow";

const TableHeader = (
  {
    data,
    tableHeader,
    setTableHeader,
    onPatchHeaderWhenClosed,
    tableId,
    checkBox,
    checkOnlyOne,
    viewOnly,
    isSecondTable,
    isThirdTable,
    isAssignTable,
    keepSaveSelectedEvenChangeMeta,
    viewAction,
    rowUniqueKey,
  },
  ref
) => {
  const changeHeaderVisible = (id) => {
    const newHeaders = tableHeader.map((header) => ({
      ...header,
      isVisible: header.id === id ? !header.isVisible : header.isVisible,
    }));
    setTableHeader(newHeaders);
    if (tableId && !onPatchHeaderWhenClosed) {
      localstoreUtilites.saveTableHeaderToLocalStorage(
        tableId,
        newHeaders.filter((saved) => saved.isVisible).map((saved) => saved.id)
      );
    }
  };
  // index
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (position) => {
    dragItem.current = position;
  };
  const dragEnter = (position) => {
    dragOverItem.current = position;
  };
  const drop = () => {
    if (!onPatchHeaderWhenClosed) {
      const newHeaders = [...tableHeader].filter((saved) => saved.isVisible);
      const notVisibleHeader = [...tableHeader].filter(
        (saved) => !saved.isVisible
      );
      const dragItemValue = newHeaders[dragItem.current];
      newHeaders.splice(dragItem.current, 1);
      newHeaders.splice(dragOverItem.current, 0, dragItemValue);
      dragItem.current = null;
      dragOverItem.current = null;
      setTableHeader([...newHeaders, ...notVisibleHeader]);
      if (tableId) {
        localstoreUtilites.saveTableHeaderToLocalStorage(tableId, [
          ...newHeaders.map((head) => head.id),
        ]);
      }
    } else {
      const newHeaders = [...tableHeader];
      const dragItemValue = newHeaders[dragItem.current];
      newHeaders.splice(dragItem.current, 1);
      newHeaders.splice(dragOverItem.current, 0, dragItemValue);
      dragItem.current = null;
      dragOverItem.current = null;
      setTableHeader(
        newHeaders.map((item, index) => ({
          ...item,
          headerOrder: index,
        }))
      );
    }
  };
  const headerContextMenu = (
    <HeaderContextMenuBox>
      {tableHeader
        .filter((item) => item.id)
        .map(
          (header, index) =>
            header.id.toLowerCase() !== "action" &&
            header.id.toLowerCase() !== "id" &&
            !header.id.includes("#") && (
              <HeaderMenu
                key={header.id + tableId}
                whileHover={{ backgroundColor: "rgba(0,0,0,0.1)" }}
              >
                <ContextMenuComponent
                  id={header.id + index}
                  text={header.label}
                  clickEvent={() => changeHeaderVisible(header.id)}
                  checkBox
                  draggable={onPatchHeaderWhenClosed ? true : header.isVisible}
                  isChecked={header.isVisible}
                  dragStart={dragStart}
                  dragEnter={dragEnter}
                  drop={drop}
                  index={index}
                />
              </HeaderMenu>
            )
        )}
    </HeaderContextMenuBox>
  );
  const setMenuOverlay = useSetRecoilState(menuOverlayState);
  const setMouseLocale = useSetRecoilState(mouseLocaleState);
  const onClickMenuIcon = (event) => {
    event.stopPropagation();
    setMenuOverlay(tableId);
    setMouseLocale({ x: event.clientX, y: event.clientY });
  };
  const onCloseContext = () => {
    onPatchHeaderWhenClosed(tableHeader);
  };
  return (
    <ContextComponent
      contextMenu={headerContextMenu}
      closeEvent={tableId && onPatchHeaderWhenClosed ? onCloseContext : null}
      noFullHeight
      tableId={tableId}
      styles={{
        flexShrink: 0,
        marginTop: "5px",
      }}
    >
      <StyledTable
        style={{ overflowY: "hidden" }}
        ref={ref}
        className={"noScrollbar"}
      >
        <StyledTHead>
          <StyledHeaderTrow action={viewAction ? "true" : null}>
            {tableId && tableHeader && tableHeader.length > 0 && (
              <MenuIconBox onClick={onClickMenuIcon}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </MenuIconBox>
            )}

            {checkBox && !viewOnly && (
              <CheckBoxContainer>
                <HeaderCheckBox
                  data={data}
                  checkOnlyOne={checkOnlyOne}
                  isSecondTable={isSecondTable}
                  isThirdTable={isThirdTable}
                  isAssignTable={isAssignTable}
                  keepSaveSelectedEvenChangeMeta={
                    keepSaveSelectedEvenChangeMeta
                  }
                  rowUniqueKey={rowUniqueKey}
                />
              </CheckBoxContainer>
            )}
            {tableHeader.map(
              (row) =>
                (row.id !== "id" || row.property === "index") &&
                row.isVisible &&
                typeof row.width === "number" && (
                  <HeaderRow
                    key={row.id}
                    tableId={tableId}
                    row={row}
                    tableHeader={tableHeader}
                    setTableHeader={setTableHeader}
                  />
                )
            )}
            {viewAction ? <BtnHeader tableId={tableId} /> : null}
          </StyledHeaderTrow>
        </StyledTHead>
      </StyledTable>
    </ContextComponent>
  );
};
export default memo(forwardRef(TableHeader));

const HeaderContextMenuBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 300px;
  padding-right: 15px;
`;
const HeaderMenu = styled(motion.div)``;
