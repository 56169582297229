export const STATUS_PREPARING = "preparing";
export const STATUS_DOWNLOADING = "downloading";
export const STATUS_UPDATING = "Updating";
export const STATUS_COMPLETED = "completed";
export const STATUS_STOP = "stop";
export const STATUS_FAILED = "failed";
export const STATUS_UPLOADING = "recovering";
export const STATUS_REINSTALLING = "reinstalling";
export const STATUS_TRANSMITTING = "transmitting";
export const STOP = "stop";
export const CLEAR = "clear";
export const ON_RESTORE = "onRestoreProcesses";
export const ON_REINSTALL = "onReinstallDoor";
export const ON_TRANSMIT = "onTransmitData";
export const ON_UPDATE = "onUpdate";
export const ON_RECOVER = "ON_RECOVER";
export const SEE_REGISTERED_IDS = "SEE_REGISTERED_IDS";
