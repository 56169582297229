import { TYPE_ACCOUNT } from "$utils/constants";
import { isAdmin } from "$utils/persistenceData";
import { isPluginEnabled } from "$utils/plugins";
import { privateRouteMessages } from "./messages";

export const getPrivateTitle = (url) => {
  if (url === "/") {
    return privateRouteMessages.dashboard;
  }
  if (url === "/download-mobile-app") {
    return privateRouteMessages.titleDownloadMobileApp;
  }
  if (url === "/user") {
    return privateRouteMessages.titleUserRoute;
  }
  if (url === "/user/add") {
    return privateRouteMessages.titleAddUserRoute;
  }
  if (url === "/user/edit/:id") {
    return privateRouteMessages.titleEditUserRoute;
  }
  if (url === "/person") {
    return privateRouteMessages.titleEditPersonRoute;
  }
  if (url === "/department") {
    return privateRouteMessages.titleDepartmentRoute;
  }
  if (url === "/unregistered-devices") {
    return privateRouteMessages.unregisteredDevices;
  }
  if (url === "/device-setting") {
    return privateRouteMessages.titleDeviceSettingRoute;
  }
  if (url === "/restaurant-device-setting") {
    return privateRouteMessages.restaurantDeviceSetting;
  }
  if (url === "/device-setting/add") {
    return privateRouteMessages.titleAddSettingRoute;
  }
  if (url === "/device-setting/edit/:id") {
    return privateRouteMessages.titleEditSettingRoute;
  }
  if (url === "/restaurant-device-setting/edit/:id") {
    return privateRouteMessages.titleEditSettingRoute;
  }
  if (url === "/message-setting") {
    return privateRouteMessages.titleMessageSettingRoute;
  }
  if (url === "/building") {
    return privateRouteMessages.titleBuildingRoute;
  }
  if (url === "/restaurant") {
    return privateRouteMessages.titleBuildingRoute;
  }
  if (url === "/monitoring") {
    if (url === isAdmin()) {
      return privateRouteMessages.titleDeviceMonitorRoute;
    }
    return privateRouteMessages.titleMonitorRoute;
  }
  if (url === "/canteen-monitoring") {
    return privateRouteMessages.titleMonitorRoute;
  }
  if (url === "/access-times") {
    return privateRouteMessages.titleTimeZoneRoute;
  }
  if (url === "/access-group") {
    return privateRouteMessages.titleAccessGroupRoute;
  }
  if (url === "/access-times/add") {
    return privateRouteMessages.titleAddTimezoneRoute;
  }
  if (url === "/access-times/edit/:id") {
    return privateRouteMessages.titleEditTimezoneRoute;
  }
  if (url === "/holiday") {
    return privateRouteMessages.titleHolidayRoute;
  }
  if (url === "/account-management") {
    return privateRouteMessages.titleAccountManagementRoute;
  }
  if (url === "/event") {
    return privateRouteMessages.titleEventLogRoute;
  }
  if (url === "/device-monitoring") {
    return privateRouteMessages.titleDeviceMonitorRoute;
  }
  if (url === "/canteen-device-monitoring") {
    return privateRouteMessages.titleCanteenDeviceMonitoring;
  }
  if (url === "/system-log") {
    return privateRouteMessages.titleSystemLogRoute;
  }
  if (url === "/accessible-door") {
    return privateRouteMessages.titleAccessibleDoor;
  }
  if (url === "/registered-user") {
    return privateRouteMessages.titleRegisteredUser;
  }
  if (url === "/analysis") {
    return privateRouteMessages.titleAnalysis;
  }
  if (url === "/setting") {
    return privateRouteMessages.titleSettingRoute;
  }
  if (url === "/check-device") {
    return privateRouteMessages.titlecheckDeviceRoute;
  }
  if (url === "/check-user") {
    return privateRouteMessages.titleCheckUserRoute;
  }
  if (url === "/emergency") {
    return privateRouteMessages.titleEmergencyRoute;
  }
  if (url === "/device-update") {
    return privateRouteMessages.titleDeviceUpdasteRoute;
  }
  if (url === "/event-recovery") {
    return privateRouteMessages.titleEventRecoveryRoute;
  }
  if (url === "/transmit-data") {
    return privateRouteMessages.titleTransmitDataRoute;
  }
  if (url === "/message-log") {
    return privateRouteMessages.titleMessageLogRoute;
  }
  if (url === "/visit") {
    return privateRouteMessages.titleEntranceVisitRoute;
  }
  if (url === "/visit/camera") {
    return privateRouteMessages.visitByCamera;
  }
  if (url === "/visit/apply") {
    return privateRouteMessages.titleEntranceVisitApplicationRoute;
  }
  if (url === "/visit/apply/:id") {
    return privateRouteMessages.titleEntranceVisitApplicationRoute;
  }
  if (url === "/visit/in-advance") {
    return privateRouteMessages.titleVisitApplicationInAdvanceRoute;
  }
  if (url === "/visit/report") {
    return privateRouteMessages.titleVisitReportRoute;
  }
  if (url === "/visit/edit/:id") {
    return privateRouteMessages.titleAddVisitRoute;
  }
  if (url === "/visit/settings") {
    return privateRouteMessages.titleVisitSettings;
  }
  if (url === "/company") {
    return privateRouteMessages.titleCompanyRoute;
  }
  if (url === "/about") {
    return privateRouteMessages.titleAboutVersionRoute;
  }
  if (url === "/workingtime-type") {
    return privateRouteMessages.titleWorkingTimeTypeRoute;
  }
  if (url === "/workingtime-type/add") {
    return privateRouteMessages.titleWorkingTimeTypeRoute;
  }
  if (url === "/workingtime-type/edit/:id") {
    return privateRouteMessages.titleWorkingTimeTypeRoute;
  }
  if (url === "/time-attendance-report") {
    return privateRouteMessages.titleTimeAttendanceReport;
  }
  if (url === "/leave-request") {
    return privateRouteMessages.leaveRequest;
  }
  if (url === "/leave-management") {
    return privateRouteMessages.leaveRequest;
  }
  if (url === "/time-attendance-setting") {
    return privateRouteMessages.titleTimeAttendanceSetting;
  }
  if (url === "/canteen-discounts") {
    return privateRouteMessages.titleCanteenDiscount;
  }
  if (url === "/canteen-meal-service-time") {
    return privateRouteMessages.titleCanteenMealServiceTime;
  }
  if (url === "/canteen-meal-service-time/add") {
    return privateRouteMessages.titleCanteenMealServiceTime;
  }
  if (url === "/admin-dashboard") {
    return privateRouteMessages.dashboard;
  }
  if (url === "/") {
    return privateRouteMessages.dashboard;
  }
  if (url === "/canteen-meal-service-time/edit/:id") {
    return privateRouteMessages.titleCanteenMealServiceTime;
  }
  if (url === "/canteen-corners") {
    return privateRouteMessages.titleCanteenCorner;
  }
  if (url === "/canteen-mealtypes") {
    return privateRouteMessages.titleCanteenMealType;
  }
  if (url === "/canteen-exceptional-meals") {
    return privateRouteMessages.titleCanteenExceptionalMeals;
  }
  if (url === "/canteen-meal-event-logs") {
    return privateRouteMessages.titleCanteenMealEventLogs;
  }
  if (url === "/canteen-report-settlement") {
    return privateRouteMessages.titleCanteenReportSettlement;
  }
  if (url === "/canteen-report-by-building") {
    return privateRouteMessages.titleCanteenReportByBuilding;
  }
  if (url === "/canteen-report-by-corner") {
    return privateRouteMessages.titleCanteenReportByCorner;
  }
  if (url === "/role-setting") {
    return privateRouteMessages.titleRoleSetting;
  }
  if (url === "/notifications") {
    return privateRouteMessages.titleNotifications;
  }
  if (url === "/profile") {
    return privateRouteMessages.titleProfile;
  }
  if (url === "/system-account") {
    return privateRouteMessages.titleSystemAccountRoute;
  }
  if (url === "/email-templates") {
    return privateRouteMessages.titleEmailTemplate;
  }
  if (url === "/email-templates/edit/:id") {
    return privateRouteMessages.titleEmailTemplate;
  }
  if (url === "/cameras") {
    return privateRouteMessages.cameras;
  }
  if (url === "/meetings") {
    return privateRouteMessages.titleMeeting;
  }
  if (url === "/not-found") {
    return privateRouteMessages.titleNotFoundRoute;
  }
  /*   if (url === "/health-monitoring") {
    return privateRouteMessages.healthMonitoring;
  } */
  if (url === "/car-monitoring") {
    return privateRouteMessages.carMonitoring;
  }
  if (url === "/car-report") {
    return privateRouteMessages.carReport;
  }
  if (url === "/user-vehicle-management") {
    return privateRouteMessages.userVehicleManagement;
  }
  if (url === "/unit-vehicle-management") {
    return privateRouteMessages.unitVehicleManagement;
  }
  if (url === "/vehicle-allocation-management") {
    return privateRouteMessages.vehicleAllocationManagement;
  }
  if (url === "/elevators") {
    return privateRouteMessages.elevators;
  }
  if (url === "/elevators/add") {
    return privateRouteMessages.elevators;
  }
  if (url === "/elevators/edit/:id") {
    return privateRouteMessages.elevators;
  }
  if (url === "/issuing/access-card") {
    return privateRouteMessages.issuingAccessCard;
  }
  if (url === "/issuing/temp-card") {
    return privateRouteMessages.issuingTempCard;
  }
  if (url === "/issuing/visit-card") {
    return privateRouteMessages.issuingVisitCard;
  }
  if (url === "/issuing/setting") {
    return privateRouteMessages.issuingSetting;
  }
  if (url === "/card-layouts") {
    return privateRouteMessages.cardLayout;
  }
  if (url === "/card-layouts/add") {
    return privateRouteMessages.cardLayout;
  }
  if (url === "/card-layouts/edit/:id") {
    return privateRouteMessages.cardLayout;
  }
  if (url === "/issuing/report") {
    return privateRouteMessages.issuingReport;
  }
  if (url === "/user/settings") {
    return privateRouteMessages.userSettings;
  }
  if (url === "/book") {
    return privateRouteMessages.bookManagement;
  }
  if (url === "/book-borrow") {
    return privateRouteMessages.borrowingManagement;
  }
  if (url === "/book-area") {
    return privateRouteMessages.bookArea;
  }
  if (url === "/book-report") {
    return privateRouteMessages.bookReport;
  }
  if (url === "/user/soldier-management") {
    return privateRouteMessages.soldierManagement;
  }
  if (url === "/entry-report") {
    return privateRouteMessages.entryReport;
  }
  return null;
};
export const getPathPermission = (permissions, accountType) => ({
  "/": permissions.dashBoard && permissions.dashBoard.viewDashBoard,
  // monitoring,
  "/monitoring":
    permissions.monitoring && permissions.monitoring.viewMonitoring,
  "/temp-device-setting": true,
  "/entry-statistics": true,
  // "/health-monitoring": true,
  "/device-monitoring":
    permissions.deviceMonitoring &&
    permissions.deviceMonitoring.viewDeviceMonitoring,

  "/car-monitoring":
    permissions.monitoring &&
    permissions.monitoring.viewMonitoring &&
    isPluginEnabled("vehiclePlugIn"),
  "/canteen-device-monitoring": accountType === TYPE_ACCOUNT.primaryManager,
  "/canteen-monitoring": true,
  "/visit":
    permissions.visitManagement && permissions.visitManagement.viewVisit,
  "/visit/apply":
    permissions.visitManagement && permissions.visitManagement.addVisit,
  "/visit/apply/:id":
    permissions.visitManagement && permissions.visitManagement.addVisit,
  "/visit/in-advance":
    permissions.visitManagement && permissions.visitManagement.addVisit,
  "/visit/edit/:id":
    permissions.visitReport && permissions.visitManagement.editVisit,
  "/visit/report":
    permissions.visitReport && permissions.visitReport.viewVisitReport,
  "/visit/settings":
    permissions.visitSetting && permissions.visitSetting.editVisitSetting,
  "/visit/camera": true,
  // User
  "/department":
    permissions.department && permissions.department.viewDepartment,
  "/user": permissions.user && permissions.user.viewUser,
  "/user/add": permissions.user && permissions.user.addUser,
  "/user/edit/:id": permissions.user && permissions.user.editUser,
  "/user/settings": permissions.user && permissions.user.updateUserSettingUser,
  // unknown person
  "/person": true,
  // Canteen Management
  "/canteen-mealtypes": true,
  "/canteen-corners": true,
  "/canteen-meal-service-time": true,
  "/canteen-meal-service-time/add": true,
  "/canteen-meal-service-time/edit/:id": true,
  "/canteen-exceptional-meals": true,
  "/canteen-discounts": true,
  "/canteen-meal-settings": true,
  // Event Management
  "/meetings": true,
  // Access Control
  "/access-group": true,
  "/access-times": permissions.timezone && permissions.timezone.viewTimezone,
  "/access-times/add": permissions.timezone && permissions.timezone.addTimezone,
  "/access-times/edit/:id":
    permissions.timezone && permissions.timezone.editTimezone,
  "/holiday": permissions.holiday && permissions.holiday.viewHoliday,
  "/registered-user":
    permissions.accessGroup && permissions.accessGroup.viewAccessGroup,
  // Car Management
  "/user-vehicle-management": true,
  "/unit-vehicle-management":
    permissions.vehicleManagement && permissions.vehicleManagement.viewVehicle,
  "/vehicle-allocation-management":
    permissions.vehicleAllocation &&
    permissions.vehicleAllocation.viewVehicleAllocation,
  // Attendance
  "/time-attendance-report":
    permissions.timeAttendanceReport &&
    permissions.timeAttendanceReport.viewAttendance,
  "/workingtime-type":
    permissions.workingTime && permissions.workingTime.viewWorkingTime,
  "/workingtime-type/add":
    permissions.workingTime && permissions.workingTime.viewWorkingTime,
  "/workingtime-type/edit/:id":
    permissions.workingTime && permissions.workingTime.viewWorkingTime,
  "/leave-request":
    permissions.leaveRequest && permissions.leaveRequest.viewLeaveRequest,
  "/leave-management":
    permissions.leaveRequest &&
    permissions.leaveRequest.viewLeaveManagementLeaveRequest,
  "/time-attendance-setting":
    permissions.timeAttendanceReport &&
    permissions.timeAttendanceReport.updateAttendanceSettingAttendance,
  // Card Issuing
  "/issuing/visit-card": true,
  "/issuing/access-card": true,
  "/issuing/temp-card": true,
  "/issuing/report":
    permissions.cardIssuing && permissions.cardIssuing.viewCardIssuing,
  "/issuing/setting":
    permissions.cardIssuingSetting &&
    permissions.cardIssuingSetting.viewCardIssuingSetting,
  "/card-layouts":
    permissions.cardIssuingLayout &&
    permissions.cardIssuingLayout.viewCardIssuingLayout,
  "/card-layouts/add":
    permissions.cardIssuingLayout &&
    permissions.cardIssuingLayout.addCardIssuingLayout,
  "/card-layouts/edit/:id":
    permissions.cardIssuingLayout &&
    permissions.cardIssuingLayout.editCardIssuingLayout,
  // Device Management
  "/message-setting":
    permissions.deviceMessage && permissions.deviceMessage.editDeviceMessage,
  "/message-log": true,
  "/building": permissions.building && permissions.building.viewBuilding,
  "/restaurant": true,
  "/device-setting":
    permissions.deviceSetting && permissions.deviceSetting.viewDeviceSetting,
  "/restaurant-device-setting": true,
  "/restaurant-device-setting/edit/:id": true,
  "/device-setting/add":
    permissions.deviceSetting && permissions.deviceSetting.editDeviceSetting,
  "/device-setting/edit/:id":
    permissions.deviceSetting && permissions.deviceSetting.editDeviceSetting,
  "/cameras": true,
  "/elevators": true,
  "/elevators/add": true,
  "/elevators/edit/:id": true,
  "/unregistered-devices": accountType === TYPE_ACCOUNT.systemAdmin,
  // Report
  "/canteen-meal-event-logs": true,
  "/event":
    (permissions.report && permissions.report.viewReport) ||
    (permissions.report && permissions.report.viewAllReport),
  "/car-report": isPluginEnabled("vehiclePlugIn"),
  "/system-log": permissions.systemLog && permissions.systemLog.viewSystemLog,
  "/accessible-door":
    permissions.accessibleDoor && permissions.accessibleDoor.viewAccessibleDoor,
  // Canteen Settlement
  "/canteen-report-settlement": true,
  "/canteen-report-by-building": true,
  "/canteen-report-by-corner": true,
  // Library Management
  "/book": permissions.book,
  "/book-borrow": permissions.book && permissions.book.viewBook,
  "/book-area": permissions.book,
  "/book-report": permissions.book && permissions.book.addBook,
  // System Management
  "/company": accountType === TYPE_ACCOUNT.systemAdmin,
  "/system-account": accountType === TYPE_ACCOUNT.systemAdmin,
  "/account-management": true,
  "/role-setting": true,
  "/event-recovery": true,
  "/setting": true,
  // "/emergency": accountType === TYPE_ACCOUNT.primaryManager,
  "/email-templates": true,
  "/email-templates/edit/:id": true,
  // Notification
  "/notifications": accountType === TYPE_ACCOUNT.primaryManager,
  // Advanced Management
  "/check-device": accountType === TYPE_ACCOUNT.systemAdmin,
  "/check-user": accountType === TYPE_ACCOUNT.systemAdmin,
  "/device-update": accountType === TYPE_ACCOUNT.systemAdmin,
  "/transmit-data": true,
  "/about": true,
  "/analysis": true,
  // else
  "/not-found": true,
  "/download-mobile-app": true,
  "/profile": true,
  "/entry-report": true,
  "/v1/device-screen-monitoring/:id": true,
  "/v2/device-screen-monitoring/:id": true,
  "/v3/device-screen-monitoring/:id": true,
  "/v4/device-screen-monitoring/:id": true,
  "/v5/device-screen-monitoring/:id": true,
  // '/temp-entry-report': true,
});
