import { useSetRecoilState } from "recoil";
import { errorState } from "$utils/atom";
import { saveLoginDataToLocal } from "$utils/libs/loginLibs";
import { useMutation } from "$utils/hooks/useMutation";

export function LoginStepOne() {
  const res = useMutation(`/login`);
  return res;
}
export function LoginStepTwo(rememberMe, username) {
  const res = useMutation(`/login-step2`);
  const [_, { data }] = res;
  const setErrorState = useSetRecoilState(errorState);
  if (data) {
    if (!data.authToken) {
      setErrorState(data.message);
      return res;
    }
    saveLoginDataToLocal(data, username, rememberMe);
  }
  return res;
}
export function SendMailForgotPass() {
  const res = useMutation(`/accounts/forgot-password`);
  return res;
}
export function PostChangePassword() {
  const res = useMutation(`/accounts/change-password-no-login`);
  return res;
}
