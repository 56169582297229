import { motion } from "framer-motion";
import { styled } from "styled-components";

export const SelectComponentContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${(props) => props.width};
`;
export const StyledLabel = styled.div`
  font-size: 11px;
  color: ${(props) =>
    props.disabled
      ? "rgba(0, 0, 0, 0.4)"
      : props.required
      ? props.theme.orangeColor
      : props.darktheme
      ? "rgba(255,255,255,0.5)"
      : props.theme.darkGrayColor};
  display: flex;
  align-items: center;
  position: absolute;
  background-color: ${(props) => props.theme.lightGrayColor};
  top: -4px;
  left: 8px;
  width: fit-content;
  z-index: 10;
  display: flex;
  gap: 3px;
`;
export const SelectBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: ${(props) => props.height};
`;
export const OptionListBox = styled.div`
  position: fixed;
  width: ${(props) => props.width};
  overflow: hidden;
  z-index: 20;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
`;
export const OptionsList = styled(motion.div)`
  background-color: white;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.fontSize};
  max-height: 300px;
  overflow-y: scroll;
  cursor: pointer;
  border-radius: 3px;
`;

export const EachOptionBox = styled(motion.div)`
  cursor: pointer;
  padding: 10px;
  height: 35px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.selected ? props.theme.orangeColor : props.theme.whiteColor};
  color: ${(props) => (props.selected ? "white" : "")};
  display: flex;
  gap: 5px;
  align-items: center;
`;
export const Box = styled.div`
  position: relative;
  background-color: ${(props) => (props.darktheme ? "white" : "transparent")};
  outline: ${(props) =>
    props.isfocus ? `2px solid ${props.theme.orangeColor}` : ""};
  border-radius: 3px;
  border: none;
  z-index: 1;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.disabled ? "rgba(0,0,0,0.4)" : "black")};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
`;
export const IconBox = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.lightGrayColor};
`;
export const Icon = styled(motion.div)`
  font-size: 15px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
