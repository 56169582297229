import { useEffect } from "react";
import { styled } from "styled-components";

export const ToolTipBody = ({
  tooltipText,
  mouseLocale,
  tempData,
  handleEnter,
  handleEndHover,
}) => {
  const tooltipData = tooltipText || tempData?.tooltipText;
  const locale = mouseLocale || tempData?.mouseLocale;
  useEffect(() => {
    if (
      tooltipText &&
      tempData?.tooltipText &&
      tooltipText.id !== tooltipData.id
    ) {
      handleEndHover();
    }
  }, [tooltipText]);
  return (
    <ToolTipBox
      style={tooltipData?.style}
      mouselocale={locale}
      onMouseEnter={(event) => {
        event.stopPropagation();
        handleEnter();
      }}
      onMouseLeave={() => {
        handleEndHover();
      }}
      onWheel={() => {
        handleEndHover();
      }}
    >
      {Array.isArray(tooltipData.text) ? (
        <ColBox>
          {tooltipData.text.map((item, index) => (
            <RowBox key={index}>
              <span>{index + 1}.</span>
              <ColBox style={{ gap: "0px" }}>
                {Array.isArray(item)
                  ? item.map((text, index) => (
                      <span key={`text_${index}`}>{text}</span>
                    ))
                  : item}
              </ColBox>
            </RowBox>
          ))}
        </ColBox>
      ) : (
        tooltipData.text
      )}
    </ToolTipBox>
  );
};
const ToolTipBox = styled.div`
  position: fixed;
  background-color: ${(props) => props.theme.darkGrayColor};
  z-index: 2000;
  min-height: ${(props) => `${props.mouselocale.height}px`};
  display: flex;
  align-items: center;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
  left: ${(props) => `${props.mouselocale.x}px`};
  top: ${(props) => `${props.mouselocale.y}px`};
  border-radius: 5px;
  color: ${(props) => props.theme.whiteColor};
  font-size: 11px;
  line-height: 18px;
  word-break: keep-all;
  word-wrap: break-word;
`;
const ColBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const RowBox = styled.div`
  display: flex;
  gap: 5px;
`;
