import { motion } from "framer-motion";
import { styled } from "styled-components";

export const TableBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.lightGrayColor};
  border-radius: ${(props) => (props.secondtable ? "5px" : "")};
  padding: ${(props) =>
    props.isSecondTable || props.isThirdTable ? "10px" : 0};
  padding-top: 10px;
  overflow: hidden;
`;
// talbe styles
export const TableBodyContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-top: 2px solid ${(props) => props.theme.grayColor};
`;
export const StyledTable = styled.table`
  font-size: 12px;

  width: 100%;
  height: 100%;
  overflow: scroll;
`;
export const StyledTBody = styled.tbody`
  width: 100%;
  display: table;
`;
export const StyledTrow = styled(motion.tr)`
  width: 100%;
  height: ${(props) => `${props.height}px`};
  font-size: ${(props) => `${props.height / 10 + 8}px`};
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 29px;
  cursor: ${(props) => (props.clickevent ? "pointer" : "default")};
`;
export const StyledTrowAnimationBox = styled(motion.td)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;
export const BlankTData = styled.td`
  width: 24px;
  height: 100%;
  flex-shrink: 0;
`;
export const StyledTData = styled(motion.td)`
  width: ${(props) =>
    typeof props.width === "string"
      ? props.width
      : `${props.width}px` || "160px"};
  height: 100%;
  padding: 0px 20px 0px 5px;
  display: flex;
  gap: 5px;
  align-items: center;
`;
export const CellData = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
export const StyledHeaderRow = styled(StyledTData)`
  position: relative;
`;
export const HeaderRowDrager = styled.div`
  position: absolute;
  right: 5px;
  width: 10px;
  height: 45px;
  border-top: 1px solid lightgray;
  border-right: 2px solid ${(props) => props.theme.grayColor};
  border-bottom: 1px solid lightgray;
  cursor: ew-resize;
`;
export const StyledText = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const OverflowText = styled(StyledText)`
  height: fit-content;
  padding: 1px 0;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const StyledTHead = styled.thead`
  width: 100%;
  display: table;
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
`;
export const StyledHeaderTrow = styled.tr`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 29px;
  font-weight: bold;
  position: relative;
  padding-right: ${(props) => (props.action ? "0px" : "8px")};
`;
export const MenuIconBox = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  position: absolute;
  left: 5px;
  font-size: 15px;
`;
export const CheckBoxContainer = styled.td`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
// sticky menu styles
export const StyledStickyTable = styled.table`
  height: calc(100% - 8px);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  overflow-y: scroll;
`;
export const StyledBtnBody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
export const StyledBtnTRow = styled(StyledTrow)`
  width: fit-content;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
  border: none;
`;
export const StyledBtnTData = styled(motion.td)`
  height: 80%;
  padding: 0 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: ${(props) => props.theme.lightGrayColor};
`;
export const NavigateText = styled.div`
  color: ${(props) => props.theme.grayColor};
  text-decoration: underline;
  cursor: pointer;
`;
