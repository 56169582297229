import { errorState } from "$utils/atom";
import { GLOBAL_COLOR, MQTT_TYPE } from "$utils/constants";
import WebSocketMqtt from "$utils/mqtt";
import { localstoreUtilites } from "$utils/persistenceData";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import {
  CANTEEN_TYPES,
  LIMIT_STORED,
  NORMAL_TYPES,
  convertDataDisplayMonitoring,
  convertVisitorDisplayMonitoring,
  resetListVisitors,
} from "./utils";

export const HandleEventDataMqtt = ({
  eventData,
  setEventData,
  jsonData,
  typeDetails,
  typeStatusDetails,
  setEventAbnormal,
  setEventCanteen,
  setEventVisitors,
  setEventUsers,
  setVisitData,
}) => {
  const setError = useSetRecoilState(errorState);
  const listDeviceIds = JSON.parse(jsonData.DeviceIds);
  const TYPES = JSON.parse(jsonData.EventType);
  useEffect(() => {
    window.DeMaster_Mqtt_Client = new WebSocketMqtt()
      .initClient()
      .addReciveMessageHandler(handleReceiveMessageMqtt)
      .onReciveMessage()
      .setTopics([
        MQTT_TYPE.EVENT_LOG_WEBAPP.topic.concat(`/${jsonData.CompanyCode}`),
        MQTT_TYPE.NOTIFICATION_VISITOR_INFO.topic.concat(
          `/${jsonData.CompanyCode}`
        ),
      ])
      .connectClient(
        (context, topicsIsSubscribed) => {
          context.subscribeTopics(topicsIsSubscribed);
        },
        (message) => {
          setError(`connect failed: ${message}`);
        }
      );
    return () => {
      window?.DeMaster_Mqtt_Client?.unSubscribe([
        MQTT_TYPE.EVENT_LOG_WEBAPP.topic.concat(
          `/${localstoreUtilites.getCompanyCodeClientFromLocalStorage()}`
        ),
        MQTT_TYPE.NOTIFICATION_VISITOR_INFO.topic.concat(
          `/${localstoreUtilites.getCompanyCodeClientFromLocalStorage()}`
        ),
      ])
        .removeReciveMessageHandler()
        .removeConnectLostHandler();
    };
  }, [eventData]);
  const handleReceiveMessageMqtt = (msg) => {
    const messageQueue = JSON.parse(msg.payloadString);
    if (messageQueue.type === MQTT_TYPE.EVENT_LOG_WEBAPP.type) {
      const events = convertDataDisplayMonitoring(
        messageQueue.data.events,
        typeDetails
      );
      const newEvents = [];
      const oldEvents = eventData;
      events.forEach((e) => {
        if (
          listDeviceIds.includes(e.icuId) &&
          TYPES.includes(e.eventDetailCode)
        ) {
          const tempEvent = eventData.find(
            (m) => m.eventLogId === e.eventLogId
          );
          if (!tempEvent) {
            if (CANTEEN_TYPES.includes(e.eventDetailCode)) {
              setEventCanteen((prev) => prev + 1);
            } else if (NORMAL_TYPES.includes(e.eventDetailCode)) {
              if (e.isVisit) {
                setEventVisitors((prev) => prev + 1);
                // reset visitor
                setVisitData((prev) => resetListVisitors(prev, undefined, e));
              } else {
                setEventUsers((prev) => prev + 1);
              }
            } else {
              setEventAbnormal((prev) => prev + 1);
            }
            newEvents.push({
              ...e,
              newMessageColor: NORMAL_TYPES.includes(e.eventDetailCode)
                ? GLOBAL_COLOR.lightGreenColor
                : GLOBAL_COLOR.lightRedColor,
              newMessage: true,
            });
          } else {
            oldEvents = oldEvents.filter(
              (m) => m.eventLogId !== tempEvent.eventLogId
            );
            newEvents.push({
              ...tempEvent,
              ...e,
              newMessageColor: NORMAL_TYPES.includes(e.eventDetailCode)
                ? GLOBAL_COLOR.lightGreenColor
                : GLOBAL_COLOR.lightRedColor,
              newMessage: true,
            });
          }
        }
      });
      let data = [...newEvents, ...oldEvents];
      if (data.length > LIMIT_STORED) {
        data = data.splice(-1);
      }
      setEventData(data);
    } else if (messageQueue.type === MQTT_TYPE.NOTIFICATION_VISITOR_INFO.type) {
      let newVisitors = convertVisitorDisplayMonitoring(
        [JSON.parse(messageQueue.data.message)],
        typeStatusDetails
      );
      setVisitData((prev) =>
        resetListVisitors(prev, newVisitors[0], undefined)
      );
    }
  };
  return <></>;
};
