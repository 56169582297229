import React from "react";
import { Route } from "react-router-dom";
import { RegisterUser } from "./User/RegisterUser";
import { SubMonitoringOne } from "./SubMonitoring/SubMonitoringOne";
import { SubMonitoringTwo } from "./SubMonitoring/SubMonitoringTwo";
import { SubMonitoringThree } from "./SubMonitoring/SubMonitoringThree";

export const CommonRouter = () => {
  return (
    <>
      <Route
        exact
        path="/v1/device-screen-monitoring/:id"
        element={<SubMonitoringOne />}
      />
      <Route
        exact
        path="/v2/device-screen-monitoring/:id"
        element={<SubMonitoringTwo />}
      />
      <Route
        exact
        path="/v3/device-screen-monitoring/:id"
        element={<SubMonitoringThree />}
      />
      <Route
        exact
        path="/v1/register-user/:companyCode/:accountId"
        element={<RegisterUser />}
      />
    </>
  );
};
