import { useId } from "react";
import { TooltipTextState, tooltipMouseLocaleState } from "$utils/atom";
import { motion } from "framer-motion";
import { useSetRecoilState } from "recoil";
import { styled } from "styled-components";

export const ToolTipComponent = ({ children, text, style }) => {
  const id = useId();
  const setMouseLocale = useSetRecoilState(tooltipMouseLocaleState);
  const setTooltipText = useSetRecoilState(TooltipTextState);
  const handleStartHover = (event) => {
    if (text) {
      setMouseLocale({
        x:
          event.view.innerWidth - event.clientX > 300
            ? event.clientX + 30
            : event.view.innerWidth - event.clientX < 100
            ? event.target.getBoundingClientRect().x - 30
            : event.target.getBoundingClientRect().x,
        y:
          event.clientY + event.target.clientHeight + 400 >
          event.view.innerHeight
            ? Array.isArray(text)
              ? // [[], [], []]
                Array.isArray(text[0])
                ? event.target.getBoundingClientRect().bottom -
                  (text.reduce((acc, cur) => acc + cur.length, 0) * 19 +
                    20 +
                    event.target.clientHeight)
                : // ["test","test","test"]
                  event.target.getBoundingClientRect().bottom -
                  (text.length * 24 + 15 + event.target.clientHeight)
              : event.target.clientHeight <= 30
              ? event.target.getBoundingClientRect().bottom - 60
              : event.target.getBoundingClientRect().bottom -
                event.target.clientHeight * 2
            : // "test"
            event.target.clientHeight <= 30
            ? event.target.getBoundingClientRect().bottom + 15
            : event.target.getBoundingClientRect().bottom,
        width: event.target.clientWidth,
        height: event.target.clientHeight,
      });
      setTooltipText({ text, style, id });
    }
  };
  const handleEndHover = () => {
    setMouseLocale(null);
    setTooltipText(null);
  };
  return (
    <ToolTipComponentBox
      onHoverStart={(e) => handleStartHover(e)}
      onHoverEnd={handleEndHover}
    >
      {children}
    </ToolTipComponentBox>
  );
};

const ToolTipComponentBox = styled(motion.div)`
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
