import React, { memo } from "react";
import { CheckBoxIcon, StyledCheckBox } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Checkbox = ({ disabled, setValue, id, value }) => {
  return (
    <>
      <input
        type="checkbox"
        onChange={(event) => {
          setValue(event.target.checked);
        }}
        id={id}
        disabled={disabled}
        checked={value}
        style={{ display: "none" }}
      />
      <StyledCheckBox
        htmlFor={id}
        onClick={(event) => {
          event.stopPropagation();
        }}
        disabled={disabled}
      >
        <CheckBoxIcon
          disabled={disabled}
          initial={{ scale: value ? 1 : 0 }}
          animate={{ scale: value ? 1 : 0 }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </CheckBoxIcon>
      </StyledCheckBox>
    </>
  );
};
export default memo(Checkbox);
