import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { GetInitRegisterUser, PostRegisterUser } from "../api";
import { userMessages } from "../messages";
import { FormattedMessage } from "react-intl";
import { InputImageComponent } from "$components/InputComponent/InputImageComponent";
import { InputComponent } from "$components/InputComponent";
import { InputDateComponent } from "$components/InputComponent/InputDateComponent";
import { ButtonComponent } from "$components/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useSetRecoilState } from "recoil";
import { alertMsgState } from "$utils/atom";
import CheckBoxComponent from "$components/CheckBoxComponent";

export const RegisterUser = () => {
  const { companyCode, accountId } = useParams();
  const setAlertMsg = useSetRecoilState(alertMsgState);
  const { data } = GetInitRegisterUser(companyCode);
  const [onPostRegisterUser] = PostRegisterUser(companyCode);
  const [gender, setGender] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [imageCardIdBack, setImageCardIdBack] = useState(null);
  const [imageCardIdFront, setImageCardIdFront] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [birthDay, setBirthDay] = useState();
  const [nationalIdNumber, setNationalIdNumber] = useState("");
  const [address, setAddress] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [email, setEmail] = useState("");
  return (
    <>
      <RegisterUserOverlay>
        {data && (
          <RegisterUserBox>
            <Header>
              <HeaderInfo>
                <Logo src={data.logo} alt="logo" />
                <CompanyName>{data.companyName}</CompanyName>
              </HeaderInfo>
              <ButtonComponent
                onClick={() => {
                  setAlertMsg({
                    text: (
                      <FormattedMessage {...userMessages.confirmRegisterUser} />
                    ),
                    action: () => {
                      onPostRegisterUser({
                        approverId: accountId,
                        gender,
                        avatar,
                        imageCardIdBack,
                        imageCardIdFront,
                        firstName,
                        birthDay,
                        nationalIdNumber,
                        address,
                        homePhone,
                        email,
                        effectiveDate: new Date(),
                        expiredDate: new Date(9999, 1, 1),
                      });
                    },
                  });
                }}
                icon={<FontAwesomeIcon icon={faSave} />}
                text={<FormattedMessage {...userMessages.btnSave} />}
                width="100px"
                height="35px"
                fontSize="13px"
              />
            </Header>
            <InfomationBox>
              <FormattedMessage {...userMessages.titleRegisterUser} />
              <SubInfo>
                <FormattedMessage {...userMessages.descriptionRegisterUser} />
              </SubInfo>
            </InfomationBox>
            <NewUserInfo>
              <NewUserImageBox>
                <ImageBox>
                  <InputImageComponent
                    id="avatar"
                    setData={setAvatar}
                    height="200px"
                    noCircle
                    showIcon={false}
                  />
                  <SubInfo>
                    <FormattedMessage {...userMessages.chooseAvatar} />
                  </SubInfo>
                </ImageBox>
                <ImageBox>
                  <InputImageComponent
                    id="imageCardIdBack"
                    setData={setImageCardIdBack}
                    height="200px"
                    noCircle
                    showIcon={false}
                  />
                  <SubInfo>
                    <FormattedMessage {...userMessages.imageCardIdFont} />
                  </SubInfo>
                </ImageBox>
                <ImageBox>
                  <InputImageComponent
                    id="imageCardIdFront"
                    setData={setImageCardIdFront}
                    height="200px"
                    noCircle
                    showIcon={false}
                  />
                  <SubInfo>
                    <FormattedMessage {...userMessages.imageCardIdBack} />
                  </SubInfo>
                </ImageBox>
              </NewUserImageBox>
              <NewUserImageBox>
                <CheckBoxComponent
                  id="male"
                  value={gender}
                  setValue={() => setGender(true)}
                  label={<FormattedMessage {...userMessages.genderMale} />}
                />
                <CheckBoxComponent
                  id="female"
                  value={!gender}
                  setValue={() => setGender(false)}
                  label={<FormattedMessage {...userMessages.genderFemale} />}
                />
              </NewUserImageBox>
              <NewUserMainInfo>
                <InputComponent
                  value={firstName}
                  setValue={setFirstName}
                  id="firstName"
                  placeholder={<FormattedMessage {...userMessages.firstName} />}
                  label
                  required
                />
                <InputDateComponent
                  value={birthDay}
                  setValue={setBirthDay}
                  id="birthDay"
                  placeholder={<FormattedMessage {...userMessages.birthDay} />}
                  label
                />
                <InputComponent
                  value={nationalIdNumber}
                  setValue={setNationalIdNumber}
                  id="nationalIdNumber"
                  placeholder={
                    <FormattedMessage {...userMessages.nationalIdNumber} />
                  }
                  label
                />
                <InputComponent
                  value={address}
                  setValue={setAddress}
                  id="address"
                  placeholder={<FormattedMessage {...userMessages.address} />}
                  label
                />
                <InputComponent
                  value={homePhone}
                  setValue={setHomePhone}
                  id="homePhone"
                  placeholder={<FormattedMessage {...userMessages.homePhone} />}
                  label
                  required
                />
                <InputComponent
                  value={email}
                  setValue={setEmail}
                  id="email"
                  placeholder={<FormattedMessage {...userMessages.email} />}
                  label
                />
              </NewUserMainInfo>
            </NewUserInfo>
          </RegisterUserBox>
        )}
      </RegisterUserOverlay>
    </>
  );
};
const RegisterUserOverlay = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  color: ${(props) => props.theme.darkGrayColor};
  padding-top: 50px;
  padding-bottom: 10px;
`;
const RegisterUserBox = styled.div`
  padding: 30px;
  background-color: ${(props) => props.theme.lightGrayColor};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 50px;
  justify-content: space-between;
`;
const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Logo = styled.img`
  height: 50px;
`;
const CompanyName = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.grayColor};
  color: ${(props) => props.theme.lightGrayColor};
  font-family: "NotoSans-Bold";
  border-radius: 10px;
  font-size: 20px;
`;
const InfomationBox = styled.div`
  font-family: "NotoSans-Bold";
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SubInfo = styled.div`
  color: gray;
  font-size: 12px;
  font-weight: normal;
`;
const NewUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const NewUserImageBox = styled.div`
  display: flex;
  gap: 40px;
  font-family: "NotoSans-Bold";
`;
const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const NewUserMainInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  gap: 20px;
`;
