import React, { useState } from "react";
import CheckBoxComponent from "$components/CheckBoxComponent";
import { styled } from "styled-components";

export const ContextMenuComponent = ({
  id,
  text,
  clickEvent = () => {},
  draggable = true,
  checkBox,
  isChecked,
  dragStart = () => {},
  dragEnter = () => {},
  drop = () => {},
  index,
}) => {
  const [checked, setChecked] = useState(isChecked);
  const onClickCheckBox = (value) => {
    setChecked(value);
    clickEvent();
  };
  return (
    <MenuItem
      draggable={draggable}
      onDragStart={() => dragStart(index)}
      onDragEnter={() => dragEnter(index)}
      onDragEnd={drop}
      onDragOver={(e) => e.preventDefault()}
    >
      {checkBox && (
        <CheckBoxComponent id={id} value={checked} setValue={onClickCheckBox} />
      )}
      <MenuText>{text}</MenuText>
    </MenuItem>
  );
};
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px 10px;
  padding-left: 0;
`;
const MenuText = styled.div``;
