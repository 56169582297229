import React from "react";
import { styled } from "styled-components";
import { SelectLanguage } from "./SelectLanguage";
import messages from "../../messages";
import { FormattedMessage } from "react-intl";

export const LoginHeader = () => {
  return (
    <LoginHeaderBox>
      <IconBox>
        <Icon src="/images/dmpIcon.png" alt="duall master pro" />
        <CompanyName>Duall Master Pro</CompanyName>
      </IconBox>
      <SelectComponent>
        <FormattedMessage {...messages.changeLanguage} />
        <SelectLanguage />
      </SelectComponent>
    </LoginHeaderBox>
  );
};
const LoginHeaderBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  min-height: 70px;
  padding: 30px 150px;
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
`;
const IconBox = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const SelectComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Icon = styled.img`
  width: 30px;
`;
const CompanyName = styled.span`
  font-size: 13px;
  font-weight: bold;
`;
