export default function boxResizeEvent(onDragChange, mouseUpEvent = () => {}) {
  return {
    onMouseDown: (clickEvent) => {
      clickEvent.stopPropagation();
      const mouseMoveHandler = (moveEvent) => {
        const deltaX = moveEvent.screenX - clickEvent.screenX;
        onDragChange(deltaX);
      };
      const mouseUpHandler = (event) => {
        event.stopPropagation();
        mouseUpEvent(clickEvent.target.offsetLeft);
        document.removeEventListener("mousemove", mouseMoveHandler);
      };
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler, { once: true });
    },
  };
}
