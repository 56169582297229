/*
 * MonitorPage Messages
 *
 * This contains all the text for the MonitorPage component.
 */
import { defineMessages } from "react-intl";

export const scope = "demasterpro.containers.MonitorPage";

export const monitroingMessages = defineMessages({
  noData: {
    id: `demasterpro.components.Datatables.noData`,
    defaultMessage: "데이터 없음",
  },
  headerUserName: {
    id: `demasterpro.containers.AddCardInUserDlg.headerUserName`,
    defaultMessage: "이름",
  },
  headerUserCode: {
    id: `demasterpro.containers.AddCardInUserDlg.headerUserCode`,
    defaultMessage: "사원번호",
  },
  headerDepartment: {
    id: `demasterpro.containers.AddCardInUserDlg.headerDepartment`,
    defaultMessage: "소속",
  },
  headerCardId: {
    id: `demasterpro.containers.AddCardInUserDlg.headerCardId`,
    defaultMessage: "카드 ID",
  },
  headerIssueCount: {
    id: `demasterpro.containers.AddCardInUserDlg.headerIssueCount`,
    defaultMessage: "발급차수",
  },
  titleUserTable: {
    id: `demasterpro.containers.AddCardInUserDlg.titleUserTable`,
    defaultMessage: `사용자 리스트`,
  },
  headerEmployeeNo: {
    id: `demasterpro.containers.AddCardInUserDlg.headerEmployeeNo`,
    defaultMessage: `사원 번호`,
  },
  btnAdd: {
    id: `demasterpro.containers.AddCardInUserDlg.btnAdd`,
    defaultMessage: `추가`,
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: "Size",
  },
  searchDoorBox: {
    id: `${scope}.searchDoorBox`,
    defaultMessage: "Search",
  },
  headerDepartment: {
    id: `${scope}.headerDepartment`,
    defaultMessage: "Department",
  },
  headerExpriedDate: {
    id: `${scope}.headerExpriedDate`,
    defaultMessage: "Expried Date",
  },
  headerStatus: {
    id: `${scope}.headerStatus`,
    defaultMessage: "Status",
  },
  headerDoorName: {
    id: `${scope}.headerDoorName`,
    defaultMessage: "Door Name",
  },
  headerName: {
    id: `${scope}.headerName`,
    defaultMessage: "Name",
  },
  headerIssueCount: {
    id: `${scope}.headerIssueCount`,
    defaultMessage: "Issue Count",
  },
  headerCardId: {
    id: `${scope}.headerCardId`,
    defaultMessage: "Card Id",
  },
  headerEventDetail: {
    id: `${scope}.headerEventDetail`,
    defaultMessage: "Event Detail",
  },
  headerDeviceAddress: {
    id: `${scope}.headerDeviceAddress`,
    defaultMessage: "Device Address",
  },
  headerDateTime: {
    id: `${scope}.headerDateTime`,
    defaultMessage: "Date Time",
  },
  headerWorkType: {
    id: `${scope}.headerWorkType`,
    defaultMessage: "Work Type",
  },
  headerMemo: {
    id: `${scope}.headerMemo`,
    defaultMessage: "Memo",
  },
  titlePage: {
    id: `${scope}.titlePage`,
    defaultMessage: "Monitoring",
  },
  filterEvent: {
    id: `${scope}.filterEvent`,
    defaultMessage: "Event Type",
  },
  doorList: {
    id: `${scope}.doorList`,
    defaultMessage: "DoorList",
  },
  selectedDoorTitle: {
    id: `${scope}.selectedDoorTitle`,
    defaultMessage: `Selected doors`,
  },
  unassignedDoorListTitle: {
    id: `${scope}.unassignedDoorListTitle`,
    defaultMessage: `Unassigned door list`,
  },
  btnAssignDoors: {
    id: `${scope}.btnAssignDoors`,
    defaultMessage: `Assign doors`,
  },
  building: {
    id: `${scope}.building`,
    defaultMessage: `Building`,
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: `select`,
  },
  clearList: {
    id: `${scope}.clearList`,
    defaultMessage: `clear list`,
  },
  allDoors: {
    id: `${scope}.allDoors`,
    defaultMessage: `All Doors`,
  },
  selectedDoors: {
    id: `${scope}.selectedDoors`,
    defaultMessage: `Selected Doors`,
  },
  btnCancel: {
    id: "demasterpro.components.Common.cancel",
    defaultMessage: `Cancel`,
  },
  btnAssign: {
    id: `${scope}.btnAssign`,
    defaultMessage: `Assign`,
  },
  searchBox: {
    id: `${scope}.searchBox`,
    defaultMessage: `Search...`,
  },
  headerInOut: {
    id: `${scope}.headerInOut`,
    defaultMessage: `In/Out`,
  },
  btnOpenDoor: {
    id: `${scope}.btnOpenDoor`,
    defaultMessage: `Select Door`,
  },
  accessHistory: {
    id: `${scope}.accessHistory`,
    defaultMessage: `Access History`,
  },
  accessTime: {
    id: `${scope}.accessTime`,
    defaultMessage: `Access Time`,
  },
  cardId: {
    id: `${scope}.cardId`,
    defaultMessage: `Card Id`,
  },
  inOut: {
    id: `${scope}.inOut`,
    defaultMessage: `In/Out`,
  },
  doorName: {
    id: `${scope}.doorName`,
    defaultMessage: `Door Name`,
  },
  eventDetail: {
    id: `${scope}.eventDetail`,
    defaultMessage: `Event Detail`,
  },
  imageCamera: {
    id: `${scope}.imageCamera`,
    defaultMessage: `Image Camera`,
  },
  avatar: {
    id: `${scope}.avatar`,
    defaultMessage: `Avatar`,
  },
  save: {
    id: "demasterpro.components.Common.save",
    defaultMessage: `save`,
  },
  addAvatar: {
    id: `${scope}.addAvatar`,
    defaultMessage: `Add Avatar`,
  },
  alarm: {
    id: `${scope}.alarm`,
    defaultMessage: `Alarm`,
  },
  job: {
    id: `${scope}.job`,
    defaultMessage: `Job`,
  },
  allowedBelonging: {
    id: `demasterpro.containers.VisitPage.allowedBelonging`,
    defaultMessage: `Allowed Belonging`,
  },
  memo: {
    id: `${scope}.memo`,
    defaultMessage: `Memo`,
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: `Clear List`,
  },
});
