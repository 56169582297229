import React, { useEffect, useState } from "react";
import { ButtonComponent } from "$components/ButtonComponent";
import { FormattedMessage } from "react-intl";
import messages from "routes/Login/messages";
import { styled } from "styled-components";
import { CommonAvatar } from "routes/Login/commonStyle";
import { SendMailForgotPass } from "routes/Login/api";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay } from "$components/Overlay";
import { ErrorMsg } from "$components/Common/ErrorMsg";
import { useForm } from "react-hook-form";
import { InputComponent } from "$components/InputComponent";

export const FindPassword = ({ setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();
  const { email } = watch();
  const [onSendMailForgotPass, { data: resData }] = SendMailForgotPass();
  const handleSendEmail = () => {
    onSendMailForgotPass({ email });
  };
  useEffect(() => {
    if (resData?.statusCode === 201) {
      setOpen(false);
    }
  }, [resData]);
  return (
    <Overlay
      open={true}
      onClick={() => {
        setOpen(false);
      }}
    >
      <FindPasswordBox>
        <CommonAvatar style={{ marginBottom: "20px" }}>
          <FontAwesomeIcon size="lg" icon={faEnvelope} />
        </CommonAvatar>
        <ErrorMsg
          text={
            errors?.email ? (
              <FormattedMessage {...messages.messageValidEmail} />
            ) : (
              ""
            )
          }
        />
        <InputComponent
          required
          register={register("email", {
            required: true,
          })}
          value={email}
          height="45px"
          label={false}
          reset={() => setValue("email", "")}
          placeholder={<FormattedMessage {...messages.emailAddress} />}
        />
        <ButtonComponent
          id="email-send"
          text={<FormattedMessage {...messages.btnSend} />}
          onClick={handleSubmit(handleSendEmail)}
          textUnderline={true}
        />
      </FindPasswordBox>
    </Overlay>
  );
};
const FindPasswordBox = styled.div`
  width: 350px;
  height: 500px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;
