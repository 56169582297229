import React from "react";
import { StyledText } from "../style";

export const Placehoder = ({
  isFocus,
  value,
  disabled,
  icon,
  placeholder,
  required,
}) => {
  return (
    <StyledText
      animate={{
        opacity: isFocus || value ? 0 : 1,
        y: isFocus || value ? -50 : 0,
      }}
      disabled={disabled ? disabled.toString() : false}
    >
      {icon}
      {placeholder}
      {required && "*"}
    </StyledText>
  );
};
