import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TYPE_ACCOUNT } from "$utils/constants";
import { Helmet } from "react-helmet";
import { LoginHeader } from "./Components/LoginHeader";
import LoginBox from "./Components/LoginBox";
import { styled } from "styled-components";
import { LogOut } from "$utils/libs/loginLibs";

export const Login = ({ isAuthed, auth, permission }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthed && !permission) {
      LogOut();
    }
    if (isAuthed && auth) {
      if (auth.accountType === TYPE_ACCOUNT.employee) {
        navigate("/download-mobile-app");
      } else if (
        auth.accountType === TYPE_ACCOUNT.systemAdmin ||
        auth.accountType === TYPE_ACCOUNT.primaryManager
      ) {
        navigate("/");
      } else {
        navigate("/monitoring");
      }
    }
  }, []);
  return (
    <LoginContainer>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="DeMasterPro" />
      </Helmet>
      <LoginHeader />
      <LoginBox />
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.2)),
    url("/images/platiumcard.png"), url("/images/reversedPlatiumcard.png"),
    url("/images/platiumcard.png"),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top, left, center, right;
`;
