import { styled } from "styled-components";

export const InputDateComponentBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const DateContentBox = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  gap: 10px;
  padding-left: 5px;
  font-size: 14px;
`;
export const DateContent = styled.span``;
export const StyledDateLabel = styled.span`
  position: absolute;
  left: 10px;
  align-items: center;
  font-size: 12px;
  top: -6px;
  z-index: 3;
  color: ${(props) => props.theme.darkGrayColor};
  background-color: ${(props) => props.theme.lightGrayColor};
  display: flex;
  gap: 3px;
`;
export const IconBox = styled.div`
  position: absolute;
  cursor: pointer;
  color: ${(props) => props.theme.grayColor};
  right: 7px;
  font-size: 12px;
  z-index: 1;
`;
