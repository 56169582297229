import {
  STATUS_COMPLETED,
  STATUS_DOWNLOADING,
  STATUS_FAILED,
  STATUS_PREPARING,
  STATUS_REINSTALLING,
  STATUS_STOP,
  STATUS_TRANSMITTING,
  STATUS_UPDATING,
  STATUS_UPLOADING,
} from "$components/TableComponent/constants";
import { TableMessages } from "$components/TableComponent/messages";
import { FormattedMessage } from "react-intl";
import { GLOBAL_COLOR } from "$utils/constants";

export const LIST_OF_COULD_ZOOM_TABLE = [
  "UserListTableAtAccessGroup",
  "UserInDepartmentTable",
  "DoorListTableAtAccessGroup",
  "UserInDeviceTableInBuilding",
  "MasterListTableInBuilding",
  "DoorListTableInBuilding",
];

export const getStringStatus = (status) => {
  if (status === STATUS_DOWNLOADING)
    return <FormattedMessage {...TableMessages.statusDownloading} />;
  else if (status === STATUS_UPDATING)
    return <FormattedMessage {...TableMessages.statusUpdating} />;
  else if (status === STATUS_COMPLETED)
    return <FormattedMessage {...TableMessages.statusCompleted} />;
  else if (status === STATUS_STOP)
    return <FormattedMessage {...TableMessages.statusStop} />;
  else if (status === STATUS_PREPARING)
    return <FormattedMessage {...TableMessages.statusPreparing} />;
  else if (status === STATUS_FAILED)
    return <FormattedMessage {...TableMessages.statusFailed} />;
  else if (status === STATUS_UPLOADING)
    return <FormattedMessage {...TableMessages.statusUploading} />;
  else if (status === STATUS_REINSTALLING)
    return <FormattedMessage {...TableMessages.statusReinstalling} />;
  else if (status === STATUS_TRANSMITTING)
    return <FormattedMessage {...TableMessages.statusTransmitting} />;
  return status;
};
const BarColor = {
  Completed: GLOBAL_COLOR.lightGreenColor,
  CompletedBar: GLOBAL_COLOR.greenColor,
  Progressing: GLOBAL_COLOR.lightBlueColor,
  ProgressingBar: GLOBAL_COLOR.blueColor,
  Stop: GLOBAL_COLOR.lightRedColor,
  StopBar: GLOBAL_COLOR.redColor,
  Finishing: GLOBAL_COLOR.lightYellowColor,
  FinishingBar: GLOBAL_COLOR.orangeColor,
};
export const getProgressColor = (status) => {
  if (status === STATUS_STOP || status === STATUS_FAILED)
    return {
      colorPrimary: BarColor.Stop,
      barColor: BarColor.StopBar,
    };
  else if (status === STATUS_COMPLETED)
    return {
      colorPrimary: BarColor.Completed,
      barColor: BarColor.CompletedBar,
    };
  else if (status === STATUS_UPDATING)
    return {
      colorPrimary: BarColor.Finishing,
      barColor: BarColor.FinishingBar,
    };
  return {
    colorPrimary: BarColor.Progressing,
    barColor: BarColor.ProgressingBar,
  };
};
