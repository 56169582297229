import React from "react";
import CheckBoxComponent from "$components/CheckBoxComponent";
import { EachOptionBox } from "./style";

export const EachOption = ({
  value,
  id,
  name,
  isMulti,
  onChange,
  setIsFocus,
}) => {
  const selected = isMulti ? value.includes(id) : value === id;
  const handleChange = (event) => {
    event.stopPropagation();
    const newData = value.includes(id)
      ? value.filter((item) => item !== id)
      : [...value, id];
    onChange(newData);
  };
  return (
    <EachOptionBox
      onClick={(event) => {
        if (isMulti) {
          handleChange(event);
        } else {
          event.stopPropagation();
          onChange(id);
          setIsFocus(false);
        }
      }}
      whileHover={
        !isMulti && selected ? {} : { backgroundColor: "rgba(0,0,0,0.2)" }
      }
      selected={isMulti ? null : selected}
    >
      {isMulti && (
        <CheckBoxComponent
          id={`${id}${name}`}
          value={selected}
          setValue={() => {}}
        />
      )}
      {name}
    </EachOptionBox>
  );
};
