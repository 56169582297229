import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
// eslint-disable-next-line
import "file-loader?name=[name].[ext]!./env";
import { RecoilRoot } from "recoil";
import { LanguageProvider } from "$components/LanguageProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
const render = () => {
  root.render(
    <LanguageProvider>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </LanguageProvider>
  );
};
render();
