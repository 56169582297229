import React from "react";
import { motion } from "framer-motion";
import { styled } from "styled-components";
import CheckBoxComponent from "$components/CheckBoxComponent";

export const EachOption = ({
  value,
  isMulti,
  item,
  onChange,
  setIsFocus,
  setText,
}) => {
  const selected = isMulti
    ? value.map((val) => val.id).includes(item.id)
    : value.id === item.id;
  const handleChange = (event) => {
    event.stopPropagation();
    const newData = selected
      ? value.filter((val) => val.id !== item.id)
      : [...value, item];
    onChange(newData);
  };
  return (
    <EachOptionBox
      onClick={(event) => {
        if (isMulti) {
          handleChange(event);
        } else {
          event.stopPropagation();
          onChange(item);
          setIsFocus(false);
          setText(item.name);
        }
      }}
      whileHover={
        !isMulti && selected ? {} : { backgroundColor: "rgba(0,0,0,0.2)" }
      }
      selected={isMulti ? null : selected}
    >
      {isMulti && (
        <CheckBoxComponent
          id={`${item.id}${item.name}`}
          value={selected}
          setValue={() => {}}
        />
      )}
      {item.name}
    </EachOptionBox>
  );
};
const EachOptionBox = styled(motion.div)`
  cursor: pointer;
  padding: 10px;
  height: 35px;
  background-color: ${(props) =>
    props.selected ? props.theme.orangeColor : props.theme.whiteColor};
  color: ${(props) => (props.selected ? "white" : "")};
  display: flex;
  gap: 5px;
  align-items: center;
`;
