import React, { useState } from "react";
import { ClickSelect } from "./ClickSelect";
import { EachOption } from "./EachOption";
import { useDidMountEffect } from "$utils/hooks/useDidMountEffect";
import {
  EachOptionBox,
  OptionListBox,
  OptionsList,
  SelectBox,
  SelectComponentContainer,
  StyledLabel,
} from "./style";
import { appMessages } from "messages";
import { FormattedMessage } from "react-intl";
import CheckBoxComponent from "$components/CheckBoxComponent";
export const SelectComponent = ({
  id,
  value,
  onChange,
  options = [],
  disabled,
  label,
  isMulti = false,
  darkTheme = false,
  width = "200px",
  height = "35px",
  fontSize = "12px",
  required = false,
  onClose = () => {},
  onDoubleClick = () => {},
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [isDown, setIsDown] = useState(true);
  const [position, setPosition] = useState(null);
  useDidMountEffect(() => {
    if (!isFocus) {
      onClose();
    }
  }, [isFocus]);
  return (
    <SelectComponentContainer
      onClick={(event) => {
        event.stopPropagation();
      }}
      onDoubleClick={onDoubleClick}
      width={width}
    >
      {label && (
        <StyledLabel
          required={required}
          disabled={disabled}
          darktheme={darkTheme ? darkTheme.toString() : null}
        >
          {label} {required && "*"}
        </StyledLabel>
      )}
      <SelectBox height={height}>
        <ClickSelect
          id={id}
          value={value}
          options={options}
          setIsFocus={setIsFocus}
          setPosition={setPosition}
          fontSize={fontSize}
          height={height}
          isFocus={isFocus}
          darkTheme={darkTheme}
          setIsDown={setIsDown}
          disabled={disabled}
          isMulti={isMulti}
        />
        {isFocus && position && !disabled && (
          <OptionListBox
            style={{
              top: isDown
                ? `calc(${position.y}px + ${height} + 5px)`
                : `calc(${position.y}px - ${
                    options.length > 9 ? 300 : options.length * 35
                  }px - 10px)`,
              left: position.x,
            }}
            width={width}
          >
            <OptionsList
              className="subScrollbar"
              initial={{
                y: isDown ? -(options.length * 40) : options.length * 40,
                opacity: 0,
              }}
              animate={{
                y: isFocus
                  ? 0
                  : isDown
                  ? -(options.length * 40)
                  : options.length * 40,
                opacity: isFocus ? 1 : 0,
              }}
              transition={{
                ease: "linear",
                duration: 0.1,
              }}
              fontSize={fontSize}
            >
              {isMulti && (
                <EachOptionBox
                  whileHover={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                  onClick={() => {
                    if (value.length === options.length) {
                      onChange([]);
                    } else {
                      onChange(options.map((item) => item.id));
                    }
                  }}
                >
                  <CheckBoxComponent
                    id={`${id}_checkBox`}
                    value={value.length === options.length}
                    setValue={() => {}}
                  />
                  <span>
                    {value.length === options.length ? (
                      <FormattedMessage {...appMessages.unselectAll} />
                    ) : (
                      <FormattedMessage {...appMessages.selectAll} />
                    )}
                  </span>
                </EachOptionBox>
              )}
              {options.map((item, index) => (
                <EachOption
                  key={index}
                  value={value}
                  id={item.id}
                  name={item.name}
                  isMulti={isMulti}
                  onChange={onChange}
                  setIsFocus={setIsFocus}
                />
              ))}
            </OptionsList>
          </OptionListBox>
        )}
      </SelectBox>
    </SelectComponentContainer>
  );
};
