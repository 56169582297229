import * as CryptoJS from "crypto-js";
import { TYPE_ACCOUNT, LANGUAGE } from "./constants";

/* eslint-disable no-bitwise */
/**
 * Utilites for using localStorage API
 * e.g: save. remove, get...token returned from remote api login.
 */
const DEMASTERPRO_AUTHINFO = "DEMASTERPRO_V2_TOKEN_LOGIN";
const DEMASTERPRO_LANGUAGE = "DEMASTERPRO_V2_LANGUAGE";
const DEMASTERPRO_USERNAME = "DEMASTERPRO_V2_USERNAME";
const DEMASTERPRO_COMPANYCODE = "DEMASTERPRO_V2_COMPANYCODE";
const DEMASTERPRO_ACCOUNTTZ = "DEMASTERPRO_V2_ACCOUNTTZ";
const DEMASTERPRO_CONFIGWS = "DEMASTERPRO_V2_CONFIGWS";
const DEMASTERPRO_PLUGINS = "DEMASTERPRO_V2_PLUGINS";
const DEMASTERPRO_USERID = "DEMASTERPRO_V2_USERID";
const DEMASTERPRO_USERCOMPAMYID = "DEMASTERPRO_V2_USERCOMPAMYID";
const DEMASTERPRO_REFRESHTOKEN = "DEMASTERPRO_V2_REFRESHTOKEN";
const DEMASTERPRO_PERMISSIONS = "DEMASTERPRO_V2_PERMISSIONS";
const DEMASTERPRO_PREFERREDSYSTEM = "DEMASTERPRO_V2_PREFERREDSYSTEM";
const DEMASTERPRO_USERNAME_CLIENT = "DEMASTERPRO_V2_USERNAME_CLIENT";
const DEMASTERPRO_COMPANYCODE_CLIENT = "DEMASTERPRO_V2_COMPANYCODE_CLIENT";
const DEMASTERPRO_CONFIGWS_CLIENT = "DEMASTERPRO_V2_CONFIGWS_CLIENT";
const DEMASTERPRO_SEARCH_BUILDING_GROUP =
  "DEMASTERPRO_V2_SEARCH_BUILDING_GROUP";
const DEMASTERPRO_V2_IS_REMEMBER_USERNAME =
  "DEMASTERPRO_V2_IS_REMEMBER_USERNAME";
const DEMASTERPRO_TABLE_HEADER = "DEMASTERPRO_V2_TABLE_HEADER";
const DEMASTERPRO_TABLE_HEADER_SIZES = "DEMASTERPRO_V2_TABLE_HEADER_SIZES";
const ENCRYPTION_KEY = "DEMASTERPRO_V2_DUALi_ENCRYPTION_KEY";
const DEMASTERPRO_TREE_WIDTH = "DEMASTERPRO_V2_TREE_WIDTH";

const getCookie = (cname) => {
  const name = `${cname}=`;
  let ca = document.cookie.split(";");
  ca = ca.map((item) => {
    let c = item;
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
    return "";
  });
  return ca.find((item) => item !== "") || null;
};

const setCookie = (cname, cvalue, exdays, setMaxAge = false) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 60 * 1000);
  const expires = `expires=${d.toGMTString()};`;
  const maxAge = "max-age=0";
  document.cookie = `${cname}=${cvalue};${exdays ? expires : ""}${
    setMaxAge ? `${maxAge}` : ""
  }`;
};

const removeCookie = (cname) => setCookie(cname, "", -1, true);

export const localstoreUtilites = {
  saveToLocalStorage: (authToken, accountType, exdays) => {
    setCookie(
      DEMASTERPRO_AUTHINFO,
      JSON.stringify({
        token: authToken,
        isAuthed: true,
        accountType,
      }),
      exdays
    );
  },
  getAuthFromLocalStorage: () =>
    JSON.parse(getCookie(DEMASTERPRO_AUTHINFO)) || {
      token: "token_fake",
      isAuthed: false,
      accountType: TYPE_ACCOUNT.visitor, // TYPE_ACCOUNT.Visitor
    },
  removeAuthFromLocalStorage: () => {
    removeCookie(DEMASTERPRO_AUTHINFO);
  },
  saveLanguageToLocalStorage: (lang) => {
    localStorage.setItem(DEMASTERPRO_LANGUAGE, lang);
  },
  getLanguageFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_LANGUAGE) || LANGUAGE.KOREA,
  saveUsernameToLocalStorage: (username) => {
    localStorage.setItem(DEMASTERPRO_USERNAME, username);
  },
  getUsernameFromLocalStorage: () => localStorage.getItem(DEMASTERPRO_USERNAME),
  clearAllFromLocalStorage: () => {
    localStorage.removeItem(DEMASTERPRO_AUTHINFO);
    localStorage.removeItem(DEMASTERPRO_CONFIGWS);
    localStorage.removeItem(DEMASTERPRO_USERNAME);
    localStorage.removeItem(DEMASTERPRO_COMPANYCODE);
    localStorage.removeItem(DEMASTERPRO_ACCOUNTTZ);
    localStorage.removeItem(DEMASTERPRO_PLUGINS);
    localStorage.removeItem(DEMASTERPRO_USERID);
    localStorage.removeItem(DEMASTERPRO_USERCOMPAMYID);
    localStorage.removeItem(DEMASTERPRO_REFRESHTOKEN);
    localStorage.removeItem(DEMASTERPRO_PERMISSIONS);
    localStorage.removeItem(DEMASTERPRO_PREFERREDSYSTEM);
    localStorage.removeItem(DEMASTERPRO_USERNAME_CLIENT);
    localStorage.removeItem(DEMASTERPRO_COMPANYCODE_CLIENT);
    localStorage.removeItem(DEMASTERPRO_CONFIGWS_CLIENT);
    localStorage.removeItem(DEMASTERPRO_SEARCH_BUILDING_GROUP);
  },
  saveCompanycodeToLocalStorage: (companyCode) => {
    localStorage.setItem(DEMASTERPRO_COMPANYCODE, companyCode);
  },
  getCompanycodeFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_COMPANYCODE),
  saveAccountTzToLocalStorage: (accountTz) => {
    localStorage.setItem(DEMASTERPRO_ACCOUNTTZ, accountTz);
  },
  getAccountTzFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_ACCOUNTTZ),
  saveConfigWSToLocalStorage: (configWS) => {
    localStorage.setItem(
      DEMASTERPRO_CONFIGWS,
      encryption(JSON.stringify(configWS))
    );
  },
  getConfigWSFromLocalStorage: () =>
    JSON.parse(decryption(localStorage.getItem(DEMASTERPRO_CONFIGWS))),
  removeConfigWSFromLocalStorage: () =>
    localStorage.removeItem(DEMASTERPRO_CONFIGWS),
  savePluginsToLocalStorage: (plugins) => {
    localStorage.setItem(
      DEMASTERPRO_PLUGINS,
      encryption(JSON.stringify(plugins))
    );
  },
  getPluginsFromLocalStorage: () =>
    JSON.parse(decryption(localStorage.getItem(DEMASTERPRO_PLUGINS))),
  removePluginsFromLocalStorage: () =>
    localStorage.removeItem(DEMASTERPRO_PLUGINS),
  saveUserIdToLocalStorage: (userId) => {
    localStorage.setItem(DEMASTERPRO_USERID, userId);
  },
  getUserIdFromLocalStorage: () => localStorage.getItem(DEMASTERPRO_USERID),
  saveUserCompanyIdToLocalStorage: (userId) => {
    localStorage.setItem(DEMASTERPRO_USERCOMPAMYID, userId);
  },
  getUserCompanyIdFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_USERCOMPAMYID),
  saveRefreshTokenToLocalStorage: (refreshToken) => {
    localStorage.setItem(
      DEMASTERPRO_REFRESHTOKEN,
      encryption(JSON.stringify(refreshToken))
    );
  },
  getRefreshTokenToLocalStorage: () =>
    JSON.parse(decryption(localStorage.getItem(DEMASTERPRO_REFRESHTOKEN))),
  savePermissionsToLocalStorage: (permissions) => {
    localStorage.setItem(
      DEMASTERPRO_PERMISSIONS,
      encryption(JSON.stringify(permissions))
    );
  },
  getPermissionsFromLocalStorage: () =>
    JSON.parse(decryption(localStorage.getItem(DEMASTERPRO_PERMISSIONS))),
  savePreferredSystemToLocalStorage: (ui) => {
    localStorage.setItem(DEMASTERPRO_PREFERREDSYSTEM, ui);
  },
  getPreferredSystemFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_PREFERREDSYSTEM),

  getUsernameClientFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_USERNAME_CLIENT),
  saveUsernameClientToLocalStorage: (username) => {
    localStorage.setItem(DEMASTERPRO_USERNAME_CLIENT, username);
  },

  getCompanyCodeClientFromLocalStorage: () =>
    localStorage.getItem(DEMASTERPRO_COMPANYCODE_CLIENT),
  saveCompanyCodeClientToLocalStorage: (companyCode) => {
    localStorage.setItem(DEMASTERPRO_COMPANYCODE_CLIENT, companyCode);
  },

  getConfigWSClientFromLocalStorage: () =>
    JSON.parse(decryption(localStorage.getItem(DEMASTERPRO_CONFIGWS_CLIENT))),
  saveConfigWSClientToLocalStorage: (configWS) => {
    localStorage.setItem(
      DEMASTERPRO_CONFIGWS_CLIENT,
      encryption(JSON.stringify(configWS))
    );
  },

  getTableHeaderFromLocalStorage: (tableId) => {
    const header = JSON.parse(localStorage.getItem(DEMASTERPRO_TABLE_HEADER));
    return header ? header[tableId] : null;
  },
  saveTableHeaderToLocalStorage: (tableId, header) => {
    const prevHeaders = JSON.parse(
      localStorage.getItem(DEMASTERPRO_TABLE_HEADER)
    );
    localStorage.setItem(
      DEMASTERPRO_TABLE_HEADER,
      JSON.stringify({ ...prevHeaders, [tableId]: header })
    );
  },
  removeTableHeaderFromLocalStorage: () =>
    localStorage.removeItem(DEMASTERPRO_TABLE_HEADER),

  getTableHeaderSizesFromLocalStorage: (tableId) => {
    const header = JSON.parse(
      localStorage.getItem(DEMASTERPRO_TABLE_HEADER_SIZES)
    );
    return header ? header[tableId] : null;
  },
  saveTableHeaderSizesToLocalStorage: (tableId, headerSizes) => {
    const prev = JSON.parse(
      localStorage.getItem(DEMASTERPRO_TABLE_HEADER_SIZES)
    );
    const newData = headerSizes.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur.width }),
      {}
    );
    localStorage.setItem(
      DEMASTERPRO_TABLE_HEADER_SIZES,
      JSON.stringify({
        ...prev,
        [tableId]: newData,
      })
    );
  },
  removeTableHeaderSizesFromLocalStorage: () =>
    localStorage.removeItem(DEMASTERPRO_TABLE_HEADER_SIZES),

  getTableHeightFromLocalStorage: () =>
    JSON.parse(localStorage.getItem("tableHeight")),

  setTableHeightToLocalStorage: (data) => {
    localStorage.setItem("tableHeight", JSON.stringify(data));
  },

  saveBuildingGroupToLocalStorage: (data) => {
    localStorage.setItem(
      DEMASTERPRO_SEARCH_BUILDING_GROUP,
      JSON.stringify(data)
    );
  },
  getBuildingGroupToLocalStorage: () =>
    JSON.parse(localStorage.getItem(DEMASTERPRO_SEARCH_BUILDING_GROUP)),
  saveIsRememberIdToLocalStorage: (bool) => {
    localStorage.setItem(
      DEMASTERPRO_V2_IS_REMEMBER_USERNAME,
      JSON.stringify(bool)
    );
  },
  getIsRememberIdToLocalStorage: () =>
    JSON.parse(localStorage.getItem(DEMASTERPRO_V2_IS_REMEMBER_USERNAME)),

  getTreeWidthFromLocalStorage: (id) => {
    const width = JSON.parse(localStorage.getItem(DEMASTERPRO_TREE_WIDTH));
    return width ? width[id] : null;
  },
  saveTreeWidthToLocalStorage: (id, width) => {
    const prevWidth = JSON.parse(localStorage.getItem(DEMASTERPRO_TREE_WIDTH));
    localStorage.setItem(
      DEMASTERPRO_TREE_WIDTH,
      JSON.stringify({ ...prevWidth, [id]: width })
    );
  },
  removeTreeWidthFromLocalStorage: () =>
    localStorage.removeItem(DEMASTERPRO_TREE_WIDTH),
};

const makeHashCode = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    // hash |= 0; // Convert to 32bit integer
  }
  if (hash < 0) {
    return hash * -1;
  }
  return hash;
};

const loginToken = localstoreUtilites?.getAuthFromLocalStorage()?.token;

export const sessionId = makeHashCode(loginToken);

// export const isAdmin = currentAccountType === TYPE_ACCOUNT.systemAdmin;
export const isAdmin = () =>
  localstoreUtilites.getAuthFromLocalStorage().accountType ===
    TYPE_ACCOUNT.systemAdmin ||
  localstoreUtilites.getAuthFromLocalStorage().accountType ===
    TYPE_ACCOUNT.superAdmin;

/**
 * Variable that user types
 * 0: user, 1: visitor
 * @author WooCheol Kim
 */
export const USERTYPE = {
  NORMAL: 0,
  VISITOR: 1,
};

/**
 * Function that encrypt data
 * @author WooCheol Kim
 */
const encryption = (data) => {
  return CryptoJS.AES.encrypt(data, ENCRYPTION_KEY).toString();
};

/**
 * Function that decrypt data
 * @author WooCheol Kim
 */
const decryption = (data) => {
  if (data) {
    const decrypted = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
    return null;
  }
  return null;
};

export const API_HEADER = {
  headers: {
    Authorization: `Bearer ${loginToken}`,
    Accept: "application/json",
  },
};
