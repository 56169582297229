import { ModalInputBtnComponent } from "$components/ButtonComponent/ModalInputBtnComponent";
import { ErrorMsg } from "$components/Common/ErrorMsg";
import { ModalComponentBox } from "$components/Common/style";
import { InputComponent } from "$components/InputComponent";
import { Overlay } from "$components/Overlay";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { PostChangePassword } from "routes/Login/api";
import { styled } from "styled-components";

export const ChangePassword = ({ messages, intl, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    setError,
  } = useForm();
  const { username, password, newPassword, confirmNewPassword } = watch();
  const [onPostChangePassword, { data: resData }] = PostChangePassword();
  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmNewPassword) {
      return setError("invalidForm", {
        type: "manual",
        message: intl.formatMessage({
          id: messages.invalidNewPassword.id,
        }),
      });
    }
    onPostChangePassword({
      username,
      password,
      newPassword,
      confirmNewPassword,
    });
  };
  useEffect(() => {
    if (resData?.statusCode === 201) {
      setOpen(false);
    }
  }, [resData]);
  return (
    <Overlay
      open={true}
      onClick={() => {
        setOpen(false);
      }}
    >
      <ModalComponentBox
        style={{
          width: "700px",
          fontSize: "12px",
        }}
      >
        <ChangePasswordTitle>
          <FormattedMessage {...messages.titleChangePassword} />
        </ChangePasswordTitle>
        <FormattedMessage {...messages.notifyPage} />
        <InputComponent
          required
          register={register("username", {
            required: true,
          })}
          value={username}
          height="45px"
          label={false}
          reset={() => setValue("username", "")}
          placeholder={<FormattedMessage {...messages.loginId} />}
        />
        <InputComponent
          required
          register={register("password", {
            required: true,
          })}
          type="password"
          value={password}
          height="45px"
          label={false}
          reset={() => setValue("password", "")}
          placeholder={<FormattedMessage {...messages.currentPassword} />}
        />
        <InputComponent
          required
          register={register("newPassword", {
            required: true,
          })}
          value={newPassword}
          height="45px"
          type="password"
          label={false}
          reset={() => setValue("newPassword", "")}
          placeholder={<FormattedMessage {...messages.newPassword} />}
        />
        <InputComponent
          required
          register={register("confirmNewPassword", {
            required: true,
          })}
          value={confirmNewPassword}
          height="45px"
          type="password"
          label={false}
          reset={() => setValue("confirmNewPassword", "")}
          placeholder={<FormattedMessage {...messages.confirmNewPassword} />}
        />
        <ErrorMsg
          text={
            errors?.invalidForm ? (
              errors?.invalidForm.message
            ) : errors?.username ||
              errors?.password ||
              errors?.newPassword ||
              errors?.confirmNewPassword ? (
              <FormattedMessage {...messages.requireMsgForChangePassword} />
            ) : (
              ""
            )
          }
        />
        <ModalInputBtnComponent
          cancelEvent={() => setOpen(false)}
          okEvent={handleSubmit(onSubmit)}
        />
      </ModalComponentBox>
    </Overlay>
  );
};

const ChangePasswordTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.darkGrayColor};
`;
