import React from "react";
import CheckBoxComponent from "$components/CheckBoxComponent";
import { useRecoilState } from "recoil";
import {
  assignSelectedRowState,
  selectedRowState,
  subSelectedRowState,
  thirdSelectedRowState,
} from "$utils/atom";
import { useId } from "react";

export const HeaderCheckBox = ({
  data,
  checkOnlyOne,
  isSecondTable,
  isThirdTable,
  isAssignTable,
  keepSaveSelectedEvenChangeMeta,
  rowUniqueKey,
}) => {
  const [selectedRow, setSelectedRow] = useRecoilState(
    isAssignTable
      ? assignSelectedRowState
      : isSecondTable
      ? subSelectedRowState
      : isThirdTable
      ? thirdSelectedRowState
      : selectedRowState
  );
  const ids = data.map((n) => n[rowUniqueKey]);
  const handleSelectAllClick = (isChecked) => {
    if (!keepSaveSelectedEvenChangeMeta) {
      if (isChecked) {
        setSelectedRow(ids);
        return;
      }
      setSelectedRow([]);
    } else {
      if (isChecked) {
        // id배열 중복 값 제거
        const newSelectedRow = new Set([...selectedRow, ...ids]);
        setSelectedRow([...newSelectedRow]);
        return;
      }
      setSelectedRow((prev) => prev.filter((item) => !ids.includes(item)));
    }
  };
  const id = useId();
  return (
    <CheckBoxComponent
      id={`headerCheckBox${id}`}
      disabled={data.length === 0 || checkOnlyOne}
      value={
        data.length !== 0 &&
        (keepSaveSelectedEvenChangeMeta
          ? selectedRow.filter((item) => ids.includes(item)).length ===
            data.length
          : selectedRow.length === data.length)
      }
      setValue={handleSelectAllClick}
    />
  );
};
