import { ButtonComponent } from "$components/ButtonComponent";
import {
  assignSelectedRowState,
  assignTablePageState,
  assignTableRowsPerPageState,
  loadingState,
  selectedRowState,
  subSelectedRowState,
  subTablePageState,
  subTableRowsPerPageState,
  tableHeightState,
  tablePageState,
  tableRowsPerPageState,
  thirdSelectedRowState,
  thirdTablePageState,
  thirdTableRowsPerPageState,
} from "$utils/atom";
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FlexBox, PaginationBox } from "./style";
import SelectRowsPerPage from "./SelectRowsPerPage";
import SortComponent from "./SortComponent";
import { FormattedMessage } from "react-intl";
import { TableMessages } from "$components/TableComponent/messages";
import { ToolTipComponent } from "$components/ToolTipComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { localstoreUtilites } from "$utils/persistenceData";
import { LIST_OF_COULD_ZOOM_TABLE } from "$components/TableComponent/util";
import { HandleResetWhenUnmount } from "./handleResetWhenUnmount";
import { HandleResetWhenSearch } from "./handleResetWhenSearch";
import { ChangePageComponent } from "./ChangePageComponent";

export const TablePagination = ({
  tableId,
  noPagination,
  sortingItem,
  total,
  isSecondTable,
  isThirdTable,
  isAssignTable,
  checkBox,
  keepSaveSelectedEvenChangeMeta,
  noSort,
  noPageSize,
  noFullFunc = false,
}) => {
  const setSelected = useSetRecoilState(
    isAssignTable
      ? assignSelectedRowState
      : isSecondTable
      ? subSelectedRowState
      : isThirdTable
      ? thirdSelectedRowState
      : selectedRowState
  );
  const [page, setPage] = useRecoilState(
    isAssignTable
      ? assignTablePageState
      : isSecondTable
      ? subTablePageState
      : isThirdTable
      ? thirdTablePageState
      : tablePageState
  );
  const [rowLength, setRowLength] = useRecoilState(
    isAssignTable
      ? assignTableRowsPerPageState
      : isSecondTable
      ? subTableRowsPerPageState
      : isThirdTable
      ? thirdTableRowsPerPageState
      : tableRowsPerPageState
  );
  const isLoading = useRecoilValue(loadingState);
  const totalPage = Math.ceil(total / rowLength);
  const onPrev = () => {
    setPage((prev) => prev - 1);
  };
  const onNext = () => {
    setPage((prev) => prev + 1);
  };
  const handleChangeRowsPerPage = (value) => {
    setRowLength(value);
    setPage(1);
  };
  // reset selected
  useEffect(() => {
    if (checkBox && !keepSaveSelectedEvenChangeMeta) {
      setSelected([]);
    }
  }, [page, rowLength]);
  // set table size
  const setTableHeight = useSetRecoilState(tableHeightState);
  return (
    <PaginationBox>
      {/* pagination만 사용하는 컴포넌트가 존재하므로, data handle컴포넌트를 pagination에 위치시킴. */}
      <HandleResetWhenUnmount
        isSecondTable={isSecondTable}
        isThirdTable={isThirdTable}
        isAssignTable={isAssignTable}
        noPagination={noPagination}
      />
      <HandleResetWhenSearch
        isSecondTable={isSecondTable}
        isThirdTable={isThirdTable}
        isAssignTable={isAssignTable}
        keepSaveSelectedEvenChangeMeta={keepSaveSelectedEvenChangeMeta}
      />
      {!noPagination && (
        <>
          <FlexBox>
            {!noSort && (
              <SortComponent
                sortingItem={sortingItem}
                messages={TableMessages}
                isLoading={isLoading}
                setSelected={(item) =>
                  checkBox && !keepSaveSelectedEvenChangeMeta
                    ? setSelected(item)
                    : {}
                }
                isSecondTable={isSecondTable}
                isThirdTable={isThirdTable}
                isAssignTable={isAssignTable}
              />
            )}
            {((!noSort &&
              !isAssignTable &&
              !isSecondTable &&
              !isThirdTable &&
              !noFullFunc) ||
              // secondtable이면서 예외인 table
              LIST_OF_COULD_ZOOM_TABLE.includes(tableId)) && (
              <>
                <ToolTipComponent
                  text={<FormattedMessage {...TableMessages.zoomIn} />}
                >
                  <ButtonComponent
                    icon={<FontAwesomeIcon icon={faMinus} />}
                    onClick={() => {
                      setTableHeight((prev) => {
                        localstoreUtilites.setTableHeightToLocalStorage(
                          prev - 1
                        );
                        return prev - 1;
                      });
                    }}
                    width="25px"
                    height="25px"
                    contained={false}
                    circle
                  />
                </ToolTipComponent>
                <ToolTipComponent
                  text={<FormattedMessage {...TableMessages.zoomOut} />}
                >
                  <ButtonComponent
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={() => {
                      setTableHeight((prev) => {
                        localstoreUtilites.setTableHeightToLocalStorage(
                          prev + 1
                        );
                        return prev + 1;
                      });
                    }}
                    width="25px"
                    height="25px"
                    contained={false}
                    circle
                  />
                </ToolTipComponent>
              </>
            )}
          </FlexBox>
          <FlexBox>
            {!noPageSize && (
              <SelectRowsPerPage
                rowLength={rowLength}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                messages={TableMessages}
                isLoading={isLoading}
                isSecondTable={isSecondTable}
                isThirdTable={isThirdTable}
                isAssignTable={isAssignTable}
              />
            )}
            <FlexBox>
              <ButtonComponent
                icon={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                onClick={() => {
                  setPage(1);
                }}
                disabled={isLoading || page === 1}
                contained={false}
                circle
                style={{
                  fontSize: "17px",
                }}
              />
              <ButtonComponent
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
                onClick={onPrev}
                disabled={isLoading || page === 1}
                contained={false}
                circle
              />
            </FlexBox>
            <ChangePageComponent
              tableId={tableId}
              total={total}
              rowLength={rowLength}
              totalPage={totalPage}
              page={page}
              setPage={setPage}
            />
            <FlexBox>
              <ButtonComponent
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={onNext}
                disabled={isLoading || page >= totalPage}
                contained={false}
                circle
              />
              <ButtonComponent
                icon={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                onClick={() => {
                  setPage(totalPage);
                }}
                disabled={isLoading || page >= totalPage}
                contained={false}
                circle
                style={{
                  fontSize: "17px",
                }}
              />
            </FlexBox>
          </FlexBox>
        </>
      )}
    </PaginationBox>
  );
};
